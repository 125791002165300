import {Text} from 'features/components/text/Text';
import {Signature} from 'types/document';
import {useAppSelector} from 'store';
import clsx from 'clsx';
import {SignaturesTable} from 'features/components/signatures-table/SignaturesTable';
import styles from './SignaturesSection.module.scss';

type Props = {
  signatures: Signature[];
  isCreator: boolean;
};

export function SignaturesSection({signatures, isCreator}: Props) {
  const {isSigned} = useAppSelector(state => state.signature);

  return (
    <div className={clsx(styles.container, isSigned && styles.roundedBorder)}>
      <Text variant="smallBold" className={styles.title}>
        SIGNATURES
      </Text>
      <SignaturesTable
        className={styles.signaturesTable}
        signatures={signatures}
        isCreator={isCreator}
      />
    </div>
  );
}
