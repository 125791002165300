import {createSlice} from '@reduxjs/toolkit';
import {Event} from 'types/event';

export interface EventStoreState {
  event: Event | null;
}

const initialState: EventStoreState = {
  event: null,
};

const eventReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addEvent(state, action) {
      state.event = action.payload;
    },
    clearEvent(state, action) {
      if (action.payload === state.event) {
        state.event = null;
      }
    },
  },
});

export default eventReducer.reducer;

export const {addEvent, clearEvent} = eventReducer.actions;
