import {Text} from 'features/components/text/Text';
import {Button} from 'features/components/button/Button';
import {Form, Input, Switch} from 'antd';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {useState} from 'react';
import {useGetUserPreferencesQuery} from 'store/api/userPreferences';
import {PreferenceName, PreferenceRequest} from 'types/userPreferences';
import usePreferences from 'hooks/userPreferencesHooks';
import {useAppSelector} from 'store';
import {useTranslation} from 'hooks/translationHooks';
import styles from './Preferences.module.scss';
import {Layout} from '../../Layout';

type Props = {
  preference: PreferenceRequest | undefined;
  title: string;
};

const {
  EMAIL_ADDRESS,
  NOTIFY_ON_SIGN_REQUEST,
  NOTIFY_ON_DOCUMENT_SIGNED,
  NOTIFY_ALL_PARTIES_SIGNED,
} = PreferenceName;

const defaultSettings = [
  {name: EMAIL_ADDRESS, title: 'settings.page.preferences.email'},
  {name: NOTIFY_ON_SIGN_REQUEST, title: 'settings.page.preferences.notify.on.sign.request'},
  {name: NOTIFY_ON_DOCUMENT_SIGNED, title: 'settings.page.preferences.notify.on.document.signed'},
  {name: NOTIFY_ALL_PARTIES_SIGNED, title: 'settings.page.preferences.notify.all.parties.signed'},
];

function Preference({preference, title}: Props) {
  const {appendPreference} = usePreferences();
  const {t} = useTranslation();
  const {name, value} = preference || {};
  const isEmail = name === EMAIL_ADDRESS;

  const [isOpen, setIsOpen] = useState(value as boolean);

  const onChange = (isChecked: boolean) => {
    if (preference) {
      appendPreference({...preference, value: isChecked});
      setIsOpen(!isOpen);
    }
  };

  if (isEmail) return null;

  return (
    <div className={styles.notificationSelection}>
      <Text variant="mediumBold">{t(title)}</Text>
      <Switch checked={isOpen} onChange={onChange} className={styles.switch} />
    </div>
  );
}

function Email({preference, title}: Props) {
  const {appendPreference} = usePreferences();
  const [form] = Form.useForm();
  const {Item} = Form || {};
  const fieldName = 'email';

  const handleSaveEmail = () => {
    const email = form.getFieldValue(fieldName);
    if (preference) {
      appendPreference({...preference, value: email});
    }
  };

  return (
    <>
      <Text variant="small">{title}</Text>
      <Form form={form}>
        <Item name={fieldName} className={styles.item}>
          <div className={styles.saveEmail}>
            <Input className={styles.saveInput} defaultValue={preference?.value as string} />
            <Button
              onClick={handleSaveEmail}
              rightIcon={<ArrowRightIcon />}
              className={styles.saveButton}>
              Save
            </Button>
          </div>
        </Item>
      </Form>
    </>
  );
}

export function Preferences() {
  const {userInfo} = useAppSelector(state => state.user);
  const {data} = useGetUserPreferencesQuery();
  const {t} = useTranslation();
  const {givenName, surName} = userInfo || {};

  const getPreference = (preferenceName: PreferenceName): PreferenceRequest => {
    const element = data?.filter(({name}) => preferenceName === name);
    if (element?.length) {
      return element[0];
    }
    return {
      name: preferenceName,
      value: preferenceName === EMAIL_ADDRESS ? '' : false,
    };
  };

  if (!data) return null;

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.upperContainer}>
          <Text variant="xLarge">{t('settings.page.preferences.title')}</Text>

          <Text className={styles.infoText} variant="smallBold">
            {t('settings.page.preferences.my.info')}
          </Text>

          <div className={styles.infoContainer}>
            <Text variant="small">{t('settings.page.preferences.full.name')}</Text>
            <Text variant="mediumBold">
              {givenName} {surName}
            </Text>
          </div>

          <div className={styles.emailContainer}>
            <Email
              preference={getPreference(EMAIL_ADDRESS)}
              title={t('settings.page.preferences.email')}
            />
          </div>
        </div>

        <div className={styles.bottomContainer}>
          <Text className={styles.notificationInfoText} variant="smallBold">
            {t('settings.page.preferences.notification')}
          </Text>
          <Text variant="small">{t('settings.page.preferences.send.me.email')}</Text>
          <div className={styles.preferencesSelectionContainer}>
            {defaultSettings.map(({name, title}) => (
              <Preference key={name} preference={getPreference(name)} title={title} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
