import {TranslationResponse} from 'types/translation';
import {createSlice} from '@reduxjs/toolkit';

const initialState: TranslationResponse = {
  translations: {},
  activeLanguage: null,
};

const translationReducer = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setTranslations(state, action) {
      state.translations = action.payload.translations;
    },
    setActiveLanguage(state, action) {
      state.activeLanguage = action.payload.activeLanguage;
    },
  },
});

const {reducer, actions} = translationReducer;

export default reducer;
export const {setTranslations, setActiveLanguage} = actions;
