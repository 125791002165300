import {useDocument} from 'hooks/documentsHooks';
import {useTranslation} from 'hooks/translationHooks';
import {Button} from 'features/components/button/Button';
import {Text} from 'features/components/text/Text';
import {ReactComponent as FileDownloadIcon} from 'assets/icons/FileDownloadIcon.svg';
import {ReactComponent as TrashIcon} from 'assets/icons/TrashIcon.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import {Dispatch, SetStateAction} from 'react';
import {Document} from 'types/document';
import {useAppDispatch} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import styles from './DocumentSelectControls.module.scss';

type SelectControlsProps = {
  selectedDocuments: string[];
  setSelectedDocuments: Dispatch<SetStateAction<string[]>>;
  content?: Document[];
  setFilesSelectionActive(): void;
  handleRefreshDocuments(): void;
  deleteButtonDisabled?: boolean;
};

const downloadZip = (blob: Blob) => {
  const fileName = 'documents';
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName}.zip`;
  a.click();
  URL.revokeObjectURL(a.href);
};

export function SelectControls({
  selectedDocuments,
  setSelectedDocuments,
  content,
  setFilesSelectionActive,
  handleRefreshDocuments,
  deleteButtonDisabled = false,
}: SelectControlsProps) {
  const {deleteDocuments, downloadDocuments} = useDocument();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const hasSelectedDocuments = !selectedDocuments?.length;

  const handleSelectAllClick = () => {
    const allDocuments = content?.map(doc => doc.id) || [];
    setSelectedDocuments(selectedDocuments.length === allDocuments.length ? [] : allDocuments);
  };

  const handleDownloadDocuments = () => {
    downloadDocuments(selectedDocuments).then(blob => {
      if (blob) {
        downloadZip(blob);
      }
    });
  };

  const handleDeleteDocuments = () => {
    deleteDocuments({ids: selectedDocuments}).then(() => {
      setSelectedDocuments([]);
      handleRefreshDocuments();
    });
  };

  return (
    <div className={styles.filesSelectionControls}>
      <div className={styles.controls}>
        <Button variant="secondary" onClick={handleSelectAllClick}>
          {t('settings.page.documents.select.all')}
        </Button>
        <div className={styles.rightButtons}>
          <Button
            variant="secondary"
            leftIcon={<FileDownloadIcon />}
            onClick={handleDownloadDocuments}
            disabled={hasSelectedDocuments}>
            {t('settings.page.documents.download')}
          </Button>
          <Button
            variant="secondary"
            leftIcon={<TrashIcon />}
            disabled={hasSelectedDocuments || deleteButtonDisabled}
            onClick={() => {
              dispatch(
                setIsModalOpen({
                  isOpen: true,
                  type: 'Confirmation',
                  confirmationText: t('settings.page.documents.are.you.sure'),
                  onSubmit: handleDeleteDocuments,
                })
              );
            }}>
            {t('settings.page.documents.delete')}
          </Button>
        </div>
      </div>
      <button
        type="button"
        className={styles.filesSelectionButton}
        onClick={setFilesSelectionActive}>
        <CloseIcon />
        <Text variant="xSmallBold">{t('settings.page.documents.close.select')}</Text>
      </button>
    </div>
  );
}
