import {Layout} from 'antd';
import {Outlet} from 'react-router-dom';
import React from 'react';
import {MainHeader} from 'features/header/MainHeader';
import {MainFooter} from 'features/footer/MainFooter';
import styles from './MainLayout.module.scss';

export function MainLayout(): React.ReactElement {
  return (
    <Layout className="layout">
      <MainHeader />
      <div className={styles.content}>
        <Outlet />
      </div>
      <MainFooter />
    </Layout>
  );
}
