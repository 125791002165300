import {useAppDispatch, useAppSelector} from 'store';
import {
  useCancelSigningMutation,
  usePollSigningInfoMutation,
  useSignDocumentMutation,
} from 'store/api/signature';
import {useCallback, useEffect, useRef} from 'react';
import {ResponseStatus} from 'types/shared';
import {
  decreaseSigningTimeRemaining,
  resetSigning,
  setSigningError,
  setSigningResponse,
  signingSuccessful,
  startSigning,
} from 'store/reducers/signature';
import {setIsModalOpen} from 'store/reducers/modal';
import {useReloadDocument} from 'hooks/documentsHooks';
import {useLocation} from 'react-router-dom';
import {useEvent} from 'hooks/eventHooks';
import {Event} from 'types/event';
import {useNavigation} from 'hooks/navigateHooks';
import {message} from 'antd';

export const useSignDocument = () => {
  const {isLoading} = useAppSelector(state => state.signature);
  const [sign] = useSignDocumentMutation();
  const [cancelSigning] = useCancelSigningMutation();
  const [pollSigningInfo] = usePollSigningInfoMutation();
  const {reloadDocument} = useReloadDocument();
  const {createEvent} = useEvent();
  const dispatch = useAppDispatch();
  const pollTimeout = useRef<NodeJS.Timeout | number | null>(null);
  const isSigningRef = useRef(isLoading);
  const {navigate} = useNavigation();
  const location = useLocation();

  useEffect(() => {
    isSigningRef.current = isLoading;
  }, [isLoading]);

  const poll = useCallback(
    (documentId: string) => {
      pollSigningInfo()
        .unwrap()
        .then(response => {
          if (response.status === ResponseStatus.SIGNATURE_CREATED) {
            dispatch(signingSuccessful({signingResponse: response}));
            dispatch(setIsModalOpen({isOpen: false}));
            reloadDocument();
            createEvent(Event.DOCUMENT_SIGNED);
            navigate(`/document/${documentId}`, {state: {fromPath: location.pathname}});
          } else if (response.status === ResponseStatus.OUTSTANDING_TRANSACTION) {
            if (isSigningRef.current) {
              pollTimeout.current = setTimeout(() => {
                poll(documentId);
                dispatch(decreaseSigningTimeRemaining());
              }, 1000);
            }
          } else {
            message.error(response.status ? response.status : 'Failed to sign document');
            dispatch(setIsModalOpen({isOpen: false}));
          }
        })
        .catch(e => {
          if (isSigningRef.current) {
            message.error(e[0].message);
            dispatch(setIsModalOpen({isOpen: false}));
          }
        });
    },
    [pollSigningInfo, dispatch, reloadDocument, createEvent, navigate, location.pathname]
  );

  const signDocument = useCallback(
    (documentId: string) => {
      dispatch(startSigning());
      return sign({documentId})
        .unwrap()
        .then(response => {
          if (response.status === ResponseStatus.OUTSTANDING_TRANSACTION) {
            dispatch(setSigningResponse({signingResponse: response}));
            poll(documentId);
          } else {
            message.error('Failed to sign document');
            dispatch(setSigningError([{code: 500, message: 'Failed to sign document'}]));
            throw new Error('SIGNING_DOCUMENT_FAILED');
          }
        })
        .catch(e => {
          message.error('Failed to sign document');
          dispatch(setSigningError(e.data.errors));
          throw new Error('SIGNING_DOCUMENT_FAILED');
        });
    },
    [dispatch, poll, sign]
  );

  const cancel = useCallback(() => {
    if (pollTimeout.current !== null) {
      clearTimeout(pollTimeout.current);
    }
    cancelSigning();
    dispatch(resetSigning());
    dispatch(setIsModalOpen({isOpen: false}));
  }, [cancelSigning, dispatch, pollTimeout]);

  return {
    signDocument,
    cancel,
  };
};
