import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ErrorDto, StoreState} from 'types/shared';
import {Contact} from 'types/contact';

export interface UserStoreState extends StoreState {
  contacts: Contact[] | null;
}

const initialState: UserStoreState = {
  contacts: null,
  isLoading: false,
  errors: [],
};

const containerReducer = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.errors = [];
    },
    setContacts(state, action) {
      state.contacts = action.payload.contacts;
      state.errors = [];
      state.isLoading = false;
    },
    addUpContact(state, action) {
      state.contacts = state.contacts
        ? [...state.contacts, action.payload.contact]
        : [action.payload.contact];
      state.errors = [];
      state.isLoading = false;
    },
    updateContact(state, action) {
      if (state.contacts && action.payload.contact) {
        state.contacts = state.contacts.map(contact =>
          contact.id === action.payload.contact.id ? action.payload.contact : contact
        );
        state.errors = [];
        state.isLoading = false;
      }
    },
    setContactsError(state, action: PayloadAction<ErrorDto[]>) {
      state.isLoading = false;
      state.errors = action.payload;
    },
  },
});

export default containerReducer.reducer;

export const {setLoading, setContacts, setContactsError, addUpContact, updateContact} =
  containerReducer.actions;
