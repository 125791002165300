export type Contact = {
  id: string;
  name: string;
  type: LegalType;
  country: string;
  code: string;
  email?: string;
  representativeName?: string;
  representativeCountry?: string;
  representativeCode?: string;
};

export enum LegalType {
  Private = 'PRIVATE',
  Business = 'LEGAL',
  Government = 'GOVERNMENT',
}

export type ContactRequest = Omit<Contact, 'id'>;

export type DeleteContactRequest = {
  id: string;
};
