import {DocumentType} from 'types/document';
import {ReactComponent as BlankDocumentIcon} from 'assets/icons/BlankDocumentIcon.svg';
import {Text} from 'features/components/text/Text';
import styles from '../DocumentsList.module.scss';

type TypeProps = {
  type: DocumentType;
};

export function Type({type}: TypeProps) {
  return (
    <div className={styles.typeWrapper}>
      <BlankDocumentIcon />
      <Text variant="xSmallBold">{type}</Text>
    </div>
  );
}
