import {TranslationRequest, TranslationResponse} from 'types/translation';
import {apiSlice} from './apiSlice';

export const APITranslationSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTranslations: builder.query<TranslationResponse, TranslationRequest>({
      query: ({lang}) => {
        return {
          url: `/translations`,
          method: 'GET',
          headers: {'Accept-Language': lang},
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {useLazyGetTranslationsQuery} = APITranslationSlice;
