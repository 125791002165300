export enum Event {
  CONTACT_ADDED = 'CONTACT_ADDED',
  CONTACT_DELETED = 'CONTACT_DELETED',
  DOCUMENT_SIGNED = 'DOCUMENT_SIGNED',
  DOCUMENT_SHARED = 'DOCUMENT_SHARED',
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  SIGNER_ADDED = 'SIGNER_ADDED',
  SIGNER_DELETED = 'SIGNER_DELETED',
  INITIATE_CERTIFICATE_SWITCH = 'INITIATE_CERTIFICATE_SWITCH',
}
