import {ReactElement} from 'react';
import {useAppSelector} from 'store';
// eslint-disable-next-line import/no-extraneous-dependencies
import {sanitize} from 'dompurify';
import {renderToStaticMarkup} from 'react-dom/server';

type Component = {
  [key: string]: ReactElement;
};

const highlightWords = (text: string, json: Component) => {
  const objectKeys = Object.keys(json);
  let replacedText = text;

  objectKeys.forEach((key: string) => {
    const regex = new RegExp(`{{(?:\\s+)?(${key})(?:\\s+)?}}`);
    const elementName = json[key];
    const element = renderToStaticMarkup(elementName);
    replacedText = replacedText.replace(regex, element);
  });

  return replacedText;
};

export const useTranslation = () => {
  const {translations} = useAppSelector(({translation}) => translation);

  function t(key: string): string;
  function t(key: string, component?: Component): ReactElement;

  function t(key: string, component?: Component): string | ReactElement {
    let translated = key;
    if (translations) {
      translated = translations[`${key}`] || key;
    }
    if (component) {
      const sanitizedHtml = sanitize(highlightWords(translated, component));
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{__html: sanitizedHtml}} />;
    }
    return translated;
  }

  return {t};
};
