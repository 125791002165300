import {message} from 'antd';
import {useDispatch} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {
  clearDocument,
  resetUploadProgress,
  setDocument,
  setDocumentError,
  startConversion,
  startDocumentSigning,
  startUpload,
  updateDocument,
} from 'store/reducers/document';

import {
  useAppendDocumentMutation,
  useAppendShareMutation,
  useAppendSignerMutation,
  useApproveDocumentMutation,
  useChangeDocumentNameMutation,
  useConvertDocumentMutation,
  useDeclineDocumentMutation,
  useDeleteDocumentMutation,
  useDeleteDocumentsMutation,
  useDeleteFileMutation,
  useDeleteSignerMutation,
  useLazyGetAllDocumentsQuery,
  useLazyGetDocumentByIdQuery,
  useNewDocumentMutation,
  useUpdateSignerMutation,
} from 'store/api/document';
import {
  Document,
  DocumentDeleteRequest,
  DocumentsDeleteRequest,
  DocumentType,
  Signer,
  SignerDeleteRequest,
} from 'types/document';
import {ErrorDto} from 'types/shared';
import {Event} from 'types/event';
import {useEvent} from 'hooks/eventHooks';

export const useDocument = () => {
  const [getAllDocumentsByFilter] = useLazyGetAllDocumentsQuery();
  const [createDocumentMutation] = useNewDocumentMutation();
  const [appendDocumentMutation] = useAppendDocumentMutation();
  const [deleteFileMutation] = useDeleteFileMutation();
  const [deleteDocumentsMutation] = useDeleteDocumentsMutation();
  const [deleteDocumentMutation] = useDeleteDocumentMutation();
  const [convertDocumentMutation] = useConvertDocumentMutation();
  const [changeDocumentNameMutation] = useChangeDocumentNameMutation();
  const [appendShare] = useAppendShareMutation();
  const [declineDocumentMutation] = useDeclineDocumentMutation();
  const [approveDocumentMutation] = useApproveDocumentMutation();

  const [appendSignerMutation] = useAppendSignerMutation();
  const [deleteSignerMutation] = useDeleteSignerMutation();
  const [updateSignerMutation] = useUpdateSignerMutation();
  const {settings} = useAppSelector(state => state.settings);
  const {document, isLoading} = useAppSelector(state => state.document);
  const dispatch = useDispatch();
  const {createEvent} = useEvent();

  const setDocumentErrors = useCallback(
    (errors: ErrorDto[]) => {
      if (errors.length) {
        const hasOnlyFieldErrors = errors.every(err => err.field || err.code === 1025);
        if (hasOnlyFieldErrors) {
          dispatch(setDocumentError(errors));
        } else {
          dispatch(resetUploadProgress());
          message.error(errors[0].message);
        }
      }
    },
    [dispatch]
  );

  const documentDownloadLink = useMemo(
    () => `${settings?.backendApiUrl}/signing-portal/api/documents/${document?.id}/download`,
    [document?.id, settings?.backendApiUrl]
  );

  const selectedDocumentDownloadLink = useCallback(
    (documentId: string) =>
      `${settings?.backendApiUrl}/signing-portal/api/documents/${documentId}/download`,
    [settings?.backendApiUrl]
  );

  const downloadDocuments = useCallback(
    (ids: string[]) => {
      const url = `${settings?.backendApiUrl}/signing-portal/api/documents/download-all`;
      return fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(ids),
      })
        .then(response => {
          return response.blob().then(responseBlob => {
            return new Blob([responseBlob], {type: 'application/zip'});
          });
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [setDocumentErrors, settings?.backendApiUrl]
  );

  const getFileDownloadLink = useCallback(
    (fileId: number) => {
      const apiUrl = settings?.backendApiUrl;
      return `${apiUrl}/signing-portal/api/documents/${document?.id}/download/${fileId}`;
    },
    [document?.id, settings?.backendApiUrl]
  );

  const createDocument = useCallback(
    (files: File[]) => {
      dispatch(startUpload());

      return createDocumentMutation({
        files,
      })
        .unwrap()
        .then((response: Document) => {
          dispatch(
            setDocument({
              document: response,
              // saves as blob url to be used in PDF preview
              originalFiles: files.map(file => ({
                name: file.name,
                url: URL.createObjectURL(file),
              })),
            })
          );
          return response;
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
          throw e;
        });
    },
    [dispatch, createDocumentMutation, setDocumentErrors]
  );

  const appendDocument = useCallback(
    (id: string, files: File[]) => {
      dispatch(startUpload());

      return appendDocumentMutation({
        id,
        files,
      })
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
              originalFiles: files.map(file => ({
                name: file.name,
                url: URL.createObjectURL(file),
              })),
            })
          );
          return response;
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
          throw e;
        });
    },
    [dispatch, appendDocumentMutation, setDocumentErrors]
  );

  const deleteFileFromDocument = useCallback(
    (id: string, fileId: number) => {
      return deleteFileMutation({
        id,
        fileId,
      })
        .unwrap()
        .then((response: Document) => {
          if (!response.files.length) {
            dispatch(clearDocument());
          } else {
            dispatch(
              setDocument({
                document: response,
              })
            );
          }
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [deleteFileMutation, dispatch, setDocumentErrors]
  );

  const deleteDocument = useCallback(
    ({id}: DocumentDeleteRequest) => {
      return deleteDocumentMutation({id})
        .unwrap()
        .then(() => {
          dispatch(clearDocument());
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [deleteDocumentMutation, dispatch, setDocumentErrors]
  );

  const deleteDocuments = useCallback(
    ({ids}: DocumentsDeleteRequest) => {
      return deleteDocumentsMutation({ids})
        .unwrap()
        .then()
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [deleteDocumentsMutation, setDocumentErrors]
  );

  const convertDocument = useCallback(
    (id: string, type: DocumentType) => {
      dispatch(startConversion());

      return convertDocumentMutation({
        id,
        type,
      })
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
          return response;
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [dispatch, convertDocumentMutation, setDocumentErrors]
  );

  const changeDocumentName = useCallback(
    (id: string, name: string) => {
      return changeDocumentNameMutation({
        id,
        name,
      })
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [changeDocumentNameMutation, dispatch, setDocumentErrors]
  );

  const appendSigner = useCallback(
    (id: string, signer: Signer) => {
      return appendSignerMutation({id, signer})
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
          createEvent(Event.SIGNER_ADDED);
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
          throw e;
        });
    },
    [appendSignerMutation, dispatch, createEvent, setDocumentErrors]
  );

  const updateSigner = useCallback(
    (id: string, signatureId: number, signer: Signer) => {
      return updateSignerMutation({id, signatureId, signer})
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [dispatch, setDocumentErrors, updateSignerMutation]
  );

  const deleteSigner = useCallback(
    ({id, signatureId}: SignerDeleteRequest) => {
      return deleteSignerMutation({id, signatureId})
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
          createEvent(Event.SIGNER_DELETED);
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [deleteSignerMutation, dispatch, createEvent, setDocumentErrors]
  );

  const appendShareToSigners = useCallback(
    (id: string) => {
      return appendShare({id})
        .unwrap()
        .then(response => response)
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [appendShare, setDocumentErrors]
  );

  const declineDocument = useCallback(
    (id: string, comment: string) => {
      dispatch(startDocumentSigning());

      return declineDocumentMutation({id, comment})
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [dispatch, declineDocumentMutation, setDocumentErrors]
  );

  const approveDocument = useCallback(
    (id: string, comment: string) => {
      dispatch(startDocumentSigning());

      return approveDocumentMutation({id, comment})
        .unwrap()
        .then((response: Document) => {
          dispatch(
            updateDocument({
              document: response,
            })
          );
        })
        .catch(e => {
          setDocumentErrors(e.data.errors);
        });
    },
    [dispatch, approveDocumentMutation, setDocumentErrors]
  );

  return {
    getAllDocumentsByFilter,
    documentDownloadLink,
    getFileDownloadLink,
    createDocument,
    appendDocument,
    isLoading,
    deleteFileFromDocument,
    convertDocument,
    changeDocumentName,
    appendSigner,
    updateSigner,
    deleteSigner,
    selectedDocumentDownloadLink,
    document,
    deleteDocuments,
    downloadDocuments,
    appendShareToSigners,
    deleteDocument,
    declineDocument,
    approveDocument,
  };
};

export const useReloadDocument = () => {
  const [trigger] = useLazyGetDocumentByIdQuery();
  const {document} = useAppSelector(state => state.document);
  const dispatch = useAppDispatch();

  const reloadDocument = useCallback(() => {
    if (!document) {
      return Promise.reject(new Error('NO_EXISTING_DOCUMENT'));
    }

    return trigger(document.id)
      .unwrap()
      .then(response => {
        dispatch(setDocument({document: response}));
      })
      .catch(() => {
        message.error('Failed to reload document');
      });
  }, [document, dispatch, trigger]);

  return {reloadDocument};
};
