import {useAppSelector} from 'store';
import {useCallback} from 'react';
import {userSelectors} from 'store/reducers/user';
import {PersonalType, Signature} from 'types/document';

export const useSignature = () => {
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));

  const isMySignature = useCallback(
    (signature: Signature): boolean => {
      if (signature.personType === PersonalType.PRIVATE) {
        return !activeRole?.organizationCode && signature.code === activeRole?.personalCode;
      }

      if (signature.representativeCode) {
        return (
          signature.code === activeRole?.organizationCode &&
          signature.representativeCode === activeRole?.personalCode
        );
      }

      return signature.code === activeRole?.organizationCode;
    },
    [activeRole?.organizationCode, activeRole?.personalCode]
  );

  const findMySignature = useCallback(
    (signatures?: Signature[]): Signature | undefined => {
      return signatures?.find(s => isMySignature(s));
    },
    [isMySignature]
  );

  return {
    isMySignature,
    findMySignature,
  };
};
