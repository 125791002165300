import './app.scss';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import {useAppSelector} from 'store';
import {AuthGuard} from 'features/components/auth/AuthGuard';
import {MainLayout} from 'features/layout/MainLayout';
import {CookieNotice} from 'features/components/cookie-notice/CookieNotice';
import {AppSettings} from 'features/components/settings/AppSettings';

import {HomePage} from 'pages/home/HomePage';
import {Documents} from 'pages/dashboard/sections';
import {DocumentFilter} from 'types/document';
import {dashboardRoutes, getRoutes, mainLayoutRoutes, mainRoutes, pathTo} from 'App.utils';
import {useEffect} from 'react';
import {defaultSelectedLanguageCode} from 'features/header/components/LanguagePicker.utils';
import {BaseModal} from 'features/components/modal/BaseModal';
import {useNavigation} from 'hooks/navigateHooks';

function Content() {
  const {userInfo} = useAppSelector(state => state.user);
  const {activeLanguage} = useAppSelector(state => state.translation);
  const routesMainLayout = getRoutes(mainLayoutRoutes);
  const routes = getRoutes(mainRoutes);
  const routesDashboard = getRoutes(dashboardRoutes);

  const {navigate} = useNavigation();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (
      activeLanguage !== defaultSelectedLanguageCode &&
      !currentPath.startsWith(`/${activeLanguage}`)
    ) {
      navigate(`/${activeLanguage}${currentPath}`);
    }
  }, [activeLanguage, location.pathname, navigate]);

  return (
    <>
      <CookieNotice />
      <Routes>
        {userInfo ? (
          <Route
            path={pathTo('/')}
            element={
              <Documents
                filter={DocumentFilter.ALL}
                title="settings.page.side.menu.all.documents"
              />
            }
          />
        ) : null}
        <Route element={<MainLayout />}>
          {!userInfo ? <Route path={pathTo('/')} element={<HomePage />} /> : null}
          {routesMainLayout}
        </Route>
        {routesDashboard}
        {routes}
        <Route path="*" element={<div>404: Page not found</div>} />
      </Routes>
      <BaseModal />
    </>
  );
}

function App() {
  useEffect(() => {
    const preventDefaultDrop = (e: DragEvent) => e.preventDefault();
    window.addEventListener('dragover', preventDefaultDrop);
    window.addEventListener('drop', preventDefaultDrop);

    return () => {
      window.removeEventListener('dragover', preventDefaultDrop);
      window.removeEventListener('drop', preventDefaultDrop);
    };
  }, []);

  return (
    <AppSettings>
      <BrowserRouter>
        <AuthGuard>
          <Content />
        </AuthGuard>
      </BrowserRouter>
    </AppSettings>
  );
}

export default App;
