import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

let apiConfig: {backendApiUrl: string} | null = null;

const fetchApiConfig = async () => {
  if (apiConfig === null) {
    const settingsFile = await fetch('/settings.json');
    apiConfig = await settingsFile.json();
  }
  return apiConfig;
};

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const config = await fetchApiConfig();
  const baseQUery = fetchBaseQuery({
    baseUrl: `${config?.backendApiUrl}/signing-portal/api`,
    prepareHeaders(headers, {getState}) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const language = (getState() as any).translation.activeLanguage;
      headers.set('Accept-Language', language || 'az');
      return headers;
    },
    credentials: 'include',
  });
  return baseQUery(args, api, extraOptions);
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: ['UserPreferences', 'Documents'],
});
