import {ReactNode, useEffect, useRef, useState} from 'react';
import {SigningFooter} from 'features/footer/SigningFooter';
import {useAppSelector} from 'store';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setIsModalOpen} from 'store/reducers/modal';
import {useNavigation} from 'hooks/navigateHooks';
import {Header} from './Header';
import {SideMenu} from './SideMenu';

import styles from './Layout.module.scss';
import {MobileMenuModal} from './MobileMenuModal';

type Props = {
  children: ReactNode;
  pageEtag?: string;
};

const smoothScrollToTop = (element: HTMLDivElement) => {
  const maxScroll = element.scrollTop;
  const speed = maxScroll / 10;
  const scroll = () => {
    // eslint-disable-next-line no-param-reassign
    element.scrollTop -= speed;
    if (element.scrollTop > 0) {
      window.requestAnimationFrame(scroll);
    }
  };
  scroll();
};

export function Layout({children, pageEtag}: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const {userInfo} = useAppSelector(state => state.user);
  const {event} = useAppSelector(state => state.event);
  const {navigate} = useNavigation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state?.from) {
      navigate(location.state.from);
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if ((event || pageEtag) && scrollRef.current) {
      smoothScrollToTop(scrollRef.current);
    }
  }, [event, pageEtag]);

  if (!userInfo) {
    navigate('/', {state: {from: location.pathname + location.search}});
    dispatch(setIsModalOpen({isOpen: true, type: 'Login'}));
    return null;
  }

  return (
    <div className={styles.layoutMainContainer}>
      <div className={styles.layoutContainer}>
        <Header
          toggleMobileMenu={() => setMobileMenuOpen(prev => !prev)}
          mobileMenuOpen={mobileMenuOpen}
        />

        <div className={styles.mainContent}>
          <SideMenu className={styles.sideMenu} />
          <div ref={scrollRef} className={styles.scrollableContent}>
            <div className={styles.content}>{children}</div>
            <SigningFooter />
          </div>
        </div>

        <MobileMenuModal
          isOpen={mobileMenuOpen}
          toggleMobileMenu={() => setMobileMenuOpen(prev => !prev)}
        />
      </div>
    </div>
  );
}
