import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {Button} from 'features/components/button/Button';
import {useAppSelector} from 'store';
import {ModalType} from 'store/reducers/modal';

import {useTranslation} from 'hooks/translationHooks';
import styles from './ContactModalControls.module.scss';

type Props = {
  onClose: () => void;
  modalType: ModalType | undefined;
  id?: string;
};

export function ContactModalControls({onClose, modalType, id}: Props) {
  const {isLoading} = useAppSelector(state => state.contact);
  const {t} = useTranslation();

  const {contacts} = useAppSelector(state => state.contact);
  const contactToEdit = contacts?.find(contact => contact.id === id);

  const submitButtonLabel = (() => {
    if (modalType === 'Contact') {
      return contactToEdit
        ? t('settings.page.contacts.update')
        : t('settings.page.contacts.add.to.button');
    }
    return t('settings.page.contacts.add.signer.button');
  })();

  if (modalType === 'Contact' || modalType === 'Signer') {
    return (
      <div className={styles.controls}>
        <UnderlineButton
          leftIcon={<ArrowRightIcon />}
          onClick={onClose}
          className={styles.cancelButton}>
          {t('settings.page.contacts.cancel.button')}
        </UnderlineButton>
        <Button
          rightIcon={<ArrowRightIcon />}
          className={styles.submitButton}
          type="submit"
          form="contactForm"
          disabled={isLoading}>
          {submitButtonLabel}
        </Button>
      </div>
    );
  }

  return null;
}
