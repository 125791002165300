import {Signature} from 'types/document';
import {User} from 'types/user';

const getLatestDateSigned = (signatures: Signature[]): Date => {
  const maxDate = Math.max(
    ...signatures.map(({signedAt}) => {
      if (signedAt) {
        return new Date(signedAt).getTime();
      }
      return 0;
    })
  );
  return new Date(maxDate);
};

const seconds = 60;
const minutes = 60;
const hours = 24;
const milliseconds = 1000;
const dayInMilliSeconds = milliseconds * seconds * minutes * hours;

const getMySignatureCreatedAt = (signatures: Signature[], userInfo: User) => {
  const {roles} = userInfo || {};

  const currentRole = roles.filter(({current}) => current);
  if (currentRole?.length) {
    const {organizationCode, personalCode} = currentRole[0];
    const activeCode = organizationCode || personalCode;
    const mySignature = signatures?.filter(
      ({code, representativeCode}) => code === activeCode || representativeCode === activeCode
    );
    if (mySignature?.length) {
      const signature = mySignature[0];
      const today = new Date();
      const dateFrom = new Date(signature.createdAt || '');
      const timeInMilliSeconds = dateFrom.getTime() - today.getTime();
      return Math.abs(Math.ceil(timeInMilliSeconds / dayInMilliSeconds));
    }
  }
  return null;
};

export {getLatestDateSigned, getMySignatureCreatedAt};
