import {Layout} from 'pages/dashboard/Layout';
import {ReactComponent as InfoIcon} from 'assets/icons/InfoIcon.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {Text} from 'features/components/text/Text';
import {useTranslation} from 'hooks/translationHooks';
import {Button} from 'features/components/button/Button';
import {useEvent} from 'hooks/eventHooks';
import {Event} from 'types/event';
import {useAppSelector} from 'store';
import {userSelectors} from 'store/reducers/user';
import styles from './SwitchCertificateView.module.scss';

type Props = {
  roleId: string;
};

export function SwitchCertificateView({roleId}: Props) {
  const {t} = useTranslation();
  const {createEvent} = useEvent();
  const roles = useAppSelector(state => userSelectors.getRoles(state.user));
  const neededRole = roles.find(r => r.roleId === roleId);

  const handleClick = () => createEvent(Event.INITIATE_CERTIFICATE_SWITCH, 500);

  const renderRoleName = () => {
    if (!neededRole) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <span className={styles.bold}>
        {neededRole.organizationCode
          ? neededRole.organizationName
          : `${neededRole.givenName} ${neededRole.surName}`}
      </span>
    );
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.info}>
          <InfoIcon />
          <Text variant="large" color="spanishBistre">
            {t('certificate.switch.info')}
          </Text>
        </div>
        <Text variant="medium">{t('certificate.switch.content', {name: renderRoleName()})}</Text>
        <Button className={styles.button} rightIcon={<ArrowRightIcon />} onClick={handleClick}>
          {t('certificate.switch.button')}
        </Button>
      </div>
    </Layout>
  );
}
