import {Text} from 'features/components/text/Text';
import {ReactComponent as RectanglesIcon} from 'assets/icons/RectanglesIcon.svg';
import {ReactComponent as MultipleDocumentsIcon} from 'assets/icons/MultipleDocumentsIcon.svg';

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as SearchIcon} from 'assets/icons/SearchIcon.svg';

import {Pagination} from 'features/components/pagination/Pagination';
import {useTranslation} from 'hooks/translationHooks';
import {DocumentFilter, PaginationDocuments} from 'types/document';
import {useDocument} from 'hooks/documentsHooks';
import {Input} from 'features/components/input/Input';
import debounce from 'lodash.debounce';
import {Layout} from 'pages/dashboard/Layout';
import {useAppDispatch, useAppSelector} from 'store';
import {userSelectors} from 'store/reducers/user';
import {useLocation} from 'react-router-dom';
import {resetDocumentsList} from 'store/reducers/documentsList';
import styles from './Documents.module.scss';
import {SelectControls} from './controls/DocumentSelectControls';
import {DocumentsList} from './list/DocumentsList';

export const DOCUMENTS_PER_PAGE = 10;

type Props = {
  filter: DocumentFilter;
  title: string;
};

export function Documents({filter, title}: Props) {
  const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
  const [filesSelectionActive, setFilesSelectionActive] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshList, setRefreshList] = useState(1);
  const [filteredDocuments, setFilteredDocuments] = useState<PaginationDocuments>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const {t} = useTranslation();
  const location = useLocation();
  const {getAllDocumentsByFilter} = useDocument();
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));
  const activeRoleRef = useRef(activeRole?.roleId);
  const {sort} = useAppSelector(state => state.documentsList);
  const dispatch = useAppDispatch();
  const {content} = filteredDocuments || {};
  const isNoDocuments = !content?.length;

  const totalPages = filteredDocuments?.totalPages || 0;

  useEffect(() => {
    setSearchTerm('');
    setFilesSelectionActive(false);
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    if (location.state && location.state.resetSort) {
      dispatch(resetDocumentsList());
    }
  }, [dispatch, location]);

  const handleGetAllDocuments = useCallback(
    (searchWord: string) => {
      const size = DOCUMENTS_PER_PAGE;
      const page = currentPage - 1;
      getAllDocumentsByFilter({filter, searchTerm: searchWord, sort, size, page})
        .then(({data}) => {
          setFilteredDocuments(data);
          const isLastPageDelete = data?.totalPages && currentPage > data.totalPages;
          if (isLastPageDelete) {
            setCurrentPage(data.totalPages);
          }
        })
        .finally(() => setIsInitialLoadingDone(true));
    },
    [currentPage, getAllDocumentsByFilter, filter, sort]
  );

  const handleRefreshDocuments = () => {
    setTimeout(() => setRefreshList(refreshList + 1), 500);
  };

  const handleFileCheckboxSelect = (documentId: string) => {
    if (selectedDocuments.includes(documentId)) {
      setSelectedDocuments(prev => prev.filter(id => id !== documentId));
      return;
    }
    setSelectedDocuments(prev => [...prev, documentId]);
  };

  const debouncedSearchTermChange = useMemo(
    () =>
      debounce(value => {
        handleGetAllDocuments(value);
      }, 500),
    [handleGetAllDocuments]
  );

  useEffect(() => {
    if (activeRole?.roleId !== activeRoleRef.current) {
      activeRoleRef.current = activeRole?.roleId;
      setTimeout(() => handleGetAllDocuments(searchTerm), 1000);
    } else {
      debouncedSearchTermChange(searchTerm);
    }
  }, [debouncedSearchTermChange, searchTerm, refreshList, activeRole, handleGetAllDocuments]);

  const handleSearch = useCallback((word: string) => setSearchTerm(word), []);

  const allFilesYourOwn = useMemo(() => {
    const creatorsArr = selectedDocuments.map(
      documentId => filteredDocuments?.content.find(doc => doc.id === documentId)?.creator
    );

    return creatorsArr.every(value => value === true);
  }, [filteredDocuments?.content, selectedDocuments]);

  if (isNoDocuments) {
    return (
      <Layout>
        <div className={styles.noDocumentsContainer}>
          <Text variant="xLarge">{t(title)}</Text>
          <div className={styles.searchContainer}>
            <Input value={searchTerm} onChange={handleSearch} className={styles.searchInput} />
            <SearchIcon />
          </div>
          <div className={styles.noDocumentsContent}>
            <MultipleDocumentsIcon />
            {isInitialLoadingDone && <Text variant="medium">{t('documents.page.not.found')}</Text>}
          </div>
        </div>
      </Layout>
    );
  }

  const handlePaginationChange = (value: number) => {
    setSelectedDocuments([]);
    setCurrentPage(value);
  };

  return (
    <Layout pageEtag={filter}>
      <div className={styles.container}>
        {!isNoDocuments ? (
          <div className={styles.stickySelection}>
            {filesSelectionActive ? (
              <SelectControls
                selectedDocuments={selectedDocuments}
                content={content}
                setSelectedDocuments={setSelectedDocuments}
                setFilesSelectionActive={() => setFilesSelectionActive(false)}
                handleRefreshDocuments={handleRefreshDocuments}
                deleteButtonDisabled={!allFilesYourOwn}
              />
            ) : null}
          </div>
        ) : null}
        <div className={styles.documentsWrapper}>
          <Text variant="xLarge">{t(title)}</Text>
          {!isNoDocuments && (
            <div>
              <button
                type="button"
                className={styles.selectFilesButton}
                onClick={() => {
                  setFilesSelectionActive(true);
                  setSelectedDocuments([]);
                }}>
                <RectanglesIcon />
                <Text variant="xSmallBold">{t('settings.page.documents.select')}</Text>
              </button>
            </div>
          )}
          <div className={styles.searchContainer}>
            <Input
              value={searchTerm}
              onChange={handleSearch}
              className={styles.searchInput}
              placeholder={t('document.page.search')}
            />
            <SearchIcon />
          </div>
          <div className={styles.documents}>
            <DocumentsList
              paginatedDocuments={content}
              selectedDocuments={selectedDocuments}
              filesSelectionActive={filesSelectionActive}
              handleFileCheckboxSelect={handleFileCheckboxSelect}
            />
            {totalPages > 1 ? (
              <Pagination
                className={styles.pagination}
                pageNumber={currentPage}
                totalPages={totalPages}
                onPageChange={handlePaginationChange}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
}
