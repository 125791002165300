import React, {CSSProperties} from 'react';

interface Props {
  width?: number;
  height?: number;
}

export function Spacer(props: Props): JSX.Element {
  const {width, height} = props;

  const localStyle: CSSProperties = {
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : '100%',
  };

  return <div style={localStyle} />;
}
