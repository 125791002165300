import {RoundButton} from 'features/components/button/RoundButton';
import {ReactComponent as FileIcon} from 'assets/icons/FileIcon.svg';
import {RolePicker} from 'features/header/components/RolePicker';
import {LanguagePicker} from 'features/header/components/LanguagePicker';
import {useTranslation} from 'hooks/translationHooks';
import {useAppDispatch, useAppSelector} from 'store';
import {clearDocument} from 'store/reducers/document';
import {Logos} from 'features/header/components/Logos';
import styles from './Header.module.scss';

export function Header() {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {userInfo} = useAppSelector(state => state.user);
  const isLoggedIn = !!userInfo;

  return (
    <div className={styles.container}>
      <div className={styles.invertedBorderLeft} />
      <div className={styles.headerContent}>
        <Logos homeLink="/" />
        <div className={styles.controls}>
          <LanguagePicker />
          {isLoggedIn && (
            <RoundButton
              leftIcon={<FileIcon />}
              className={styles.createNewButton}
              onClick={() => dispatch(clearDocument())}>
              {t('header.create.new.button')}
            </RoundButton>
          )}
          <RolePicker />
        </div>
      </div>
      <div className={styles.invertedBorderRight} />
    </div>
  );
}
