import {useAppSelector} from 'store';
import {Text} from 'features/components/text/Text';
import {Verification} from 'features/components/modal/verification/Verification';
import styles from 'features/components/modal/signing/SigningModal.module.scss';
import {useTranslation} from 'hooks/translationHooks';
import {useSignDocument} from 'hooks/signingHooks';
import {useCallback, useEffect} from 'react';

type Props = {
  onLoaded: (val: boolean) => void;
};

export function SigningModal({onLoaded}: Props) {
  const {t} = useTranslation();
  const {cancel} = useSignDocument();
  const {isLoading, signingResponse, signingTimeRemaining, errors} = useAppSelector(
    state => state.signature
  );
  const handleClickCancel = useCallback(() => cancel(), [cancel]);

  useEffect(() => {
    if (signingTimeRemaining === 0) {
      handleClickCancel();
    }
  }, [signingTimeRemaining, handleClickCancel]);

  useEffect(() => {
    if (isLoading && signingResponse) {
      onLoaded(true);
    }
  }, [isLoading, onLoaded, signingResponse]);

  if (!isLoading || !signingResponse) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <Text variant="largeBold" textAlign="center">
          {t('signing.modal.title')}
        </Text>
      </div>
      <Verification
        verificationCode={signingResponse.verificationCode}
        timeRemaining={signingTimeRemaining}
        description={t('verification.modal.info')}
        onCancel={handleClickCancel}
        errors={errors}
        verificationCodeLabel={t('verification.modal.code')}
        onCancelLabel={t('confirmation.cancel.text')}
      />
    </div>
  );
}
