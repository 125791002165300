import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {useTranslation} from 'hooks/translationHooks';
import styles from './NeedHelp.module.scss';

export function NeedHelp(): JSX.Element {
  const {t} = useTranslation();

  return (
    <div className={styles.needHelp}>
      <Text variant="xLarge">{t('contact.page.help.title')}</Text>
      <Spacer height={24} />

      <Text variant="large">
        {t('contact.page.help.subtitle', {
          tel: (
            <a className={styles.linkBold} href={`tel:${t('contact.page.help.tel')}`}>
              {t('contact.page.help.tel')}
            </a>
          ),
          email: (
            <a className={styles.linkBlueUnderline} href={`mailto:${t('contact.page.help.email')}`}>
              {t('contact.page.help.email')}
            </a>
          ),
        })}
      </Text>
      {/*

      <Spacer height={24} />

      <Text variant="medium">{t('contact.page.assist.title')}</Text>
      <Text variant="mediumBold">
        <Link underline to="/features" color="pureBlue" className={styles.linkHover}>
          {t('contact.page.assist.link.title')}
        </Link>
      </Text>
        */}
    </div>
  );
}
