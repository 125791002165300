import clsx from 'clsx';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {Button} from 'features/components/button/Button';
import {TextButton} from 'features/components/button/TextButton';

import {ReactComponent as FolderIcon} from 'assets/icons/FolderIcon.svg';
import {ReactComponent as BlankDocumentIcon} from 'assets/icons/BlankDocumentIcon.svg';
import {ReactComponent as FolderPlusIcon} from 'assets/icons/FolderPlusIcon.svg';
import {ReactComponent as LoadingIcon} from 'assets/icons/LoadingIcon.svg';
import {ReactComponent as ContactBookIcon} from 'assets/icons/ContactBookIcon.svg';
import {ReactComponent as GearIcon} from 'assets/icons/GearIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {useAppDispatch, useAppSelector} from 'store';
import {clearDocument} from 'store/reducers/document';
import {resetSigning} from 'store/reducers/signature';
import {useLocation} from 'react-router-dom';

import {useNavigation} from 'hooks/navigateHooks';
import styles from './SideMenu.module.scss';

export enum Section {
  'AllDocuments' = 'All documents',
  'Document' = 'Document',
  'CreatedByMe' = 'Created by me',
  'SharedToMe' = 'Shared to me',
  'WaitingForSigning' = 'Waiting for signing',
  'Contacts' = 'Contacts',
  'SigningProfiles' = 'Signing profiles',
  'Preferences' = 'Preferences',
  'SignDocument' = 'Sign document',
}

export const sectionButtons = [
  {
    id: Section.AllDocuments,
    label: 'settings.page.side.menu.all.documents',
    icon: <FolderIcon />,
    link: '/',
  },
  {
    id: Section.CreatedByMe,
    label: 'settings.page.side.menu.created.by.me',
    icon: <BlankDocumentIcon />,
    link: '/documents/me',
  },
  {
    id: Section.SharedToMe,
    label: 'settings.page.side.menu.shared.to.me',
    icon: <FolderPlusIcon />,
    link: '/documents/shared',
  },
  {
    id: Section.WaitingForSigning,
    label: 'settings.page.side.menu.waiting.for.signing',
    icon: <LoadingIcon />,
    link: '/documents/pending',
  },
  {
    id: Section.Contacts,
    label: 'settings.page.side.menu.contacts',
    icon: <ContactBookIcon />,
    link: '/contacts',
  },
  {
    id: Section.Preferences,
    label: 'settings.page.side.menu.preferences',
    icon: <GearIcon />,
    link: '/preferences',
  },
];

type Props = {
  className?: string;
};

export function SideMenu({className}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {activeLanguage} = useAppSelector(state => state.translation);

  const {navigate} = useNavigation();
  const location = useLocation();

  const handleCreateNew = () => {
    dispatch(clearDocument());
    dispatch(resetSigning());
    navigate('/new');
  };

  const isActive = (link: string): boolean => {
    let {pathname} = location;
    const hasLanguageSelected = activeLanguage && activeLanguage !== 'az';
    if (hasLanguageSelected) {
      pathname = pathname.replace(`/${activeLanguage}`, '');
    }
    return pathname === link;
  };

  return (
    <div className={clsx(styles.container, className)}>
      <div>
        <Button
          variant="secondary"
          rightIcon={<ArrowRightIcon />}
          className={styles.createNewButton}
          onClick={handleCreateNew}>
          {t('settings.page.documents.create')}
        </Button>
      </div>
      <div className={styles.lineBreak} />
      <div>
        {sectionButtons.slice(0, 4).map(button => (
          <TextButton
            key={button.id}
            icon={button.icon}
            onClick={() => navigate(button.link, {state: {resetSort: false}})}
            className={styles.sectionButton}
            isActive={isActive(button.link)}>
            {t(button.label)}
          </TextButton>
        ))}
      </div>
      <div className={styles.lineBreak} />
      <div>
        {sectionButtons.slice(4).map(button => (
          <TextButton
            key={button.id}
            icon={button.icon}
            onClick={() => navigate(button.link)}
            className={styles.sectionButton}
            isActive={isActive(button.link)}>
            {t(button.label)}
          </TextButton>
        ))}
      </div>
    </div>
  );
}
