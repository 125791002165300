import {message} from 'antd';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useLazySignOutQuery} from 'store/api/user';
import {resetAuth} from 'store/reducers/auth';
import {logout} from 'store/reducers/user';
import {useNavigation} from 'hooks/navigateHooks';

export const useLogout = () => {
  const [startSignOut] = useLazySignOutQuery();
  const dispatch = useDispatch();
  const {navigate} = useNavigation();

  const signOut = useCallback(() => {
    return startSignOut()
      .unwrap()
      .then(() => {
        dispatch(resetAuth());
        dispatch(logout());
        navigate('/');
      })
      .catch(() => message.error('Failed to logout'));
  }, [dispatch, startSignOut, navigate]);

  return {
    signOut,
  };
};
