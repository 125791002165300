/* eslint-disable react/button-has-type */
import clsx from 'clsx';

import {HTMLProps, ReactNode} from 'react';
import styles from './Button.module.scss';

type Props = {
  className?: string;
  children?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  type?: 'button' | 'submit' | 'reset';
} & HTMLProps<HTMLButtonElement>;

export function Button({
  className,
  children,
  disabled,
  leftIcon,
  onClick,
  rightIcon,
  variant = 'primary',
  type = 'button',
  ...restProps
}: Props) {
  return (
    <button
      type={type}
      className={clsx(
        styles.button,
        variant === 'primary' ? styles.primaryButton : styles.secondaryButton,
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...restProps}>
      <div>
        {leftIcon}
        <span>{children}</span>
        {rightIcon}
      </div>
    </button>
  );
}
