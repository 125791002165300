import {useLocation} from 'react-router-dom';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {useNavigation} from 'hooks/navigateHooks';
import styles from '../DocumentsList.module.scss';

type NameProps = {
  name: string;
  id: string;
};

export function Name({name, id}: NameProps) {
  const {navigate} = useNavigation();
  const location = useLocation();
  return (
    <div className={styles.nameWrapper}>
      <div>
        <UnderlineButton
          className={styles.nameButton}
          onClick={() => navigate(`/document/${id}`, {state: {fromPath: location.pathname}})}>
          {name}
        </UnderlineButton>
      </div>
    </div>
  );
}
