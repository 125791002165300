import {useCallback, useEffect, useState} from 'react';
import {PdfModal} from 'features/components/pdf-modal/PdfModal';
import {Switch} from 'features/components/switch/Switch';

import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {ReactComponent as NextIcon} from 'assets/icons/NextIcon.svg';
import {Text} from 'features/components/text/Text';
import {DocumentFile, DocumentType} from 'types/document';
import {useAppSelector} from 'store';
import {useDocument} from 'hooks/documentsHooks';
import {Input} from 'features/components/input/Input';
import {useTranslation} from 'hooks/translationHooks';
import {useDocumentName} from 'hooks/useDocumentName';
import {FilesList} from 'features/components/files-list/FilesList';
import clsx from 'clsx';
import {useParams} from 'react-router-dom';
import {useNavigation} from 'hooks/navigateHooks';
import styles from './FilesContainer.module.scss';

type Props = {
  isFilesRemovable?: boolean;
  files: DocumentFile[];
  onFileRemove: (id: number) => void;
  hasPadding?: boolean;
};

export function FilesContainer({
  files,
  isFilesRemovable = true,
  onFileRemove,
  hasPadding = true,
}: Props) {
  const {t} = useTranslation();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const {document, conversionInProgress, originalFiles} = useAppSelector(state => state.document);
  const {settings} = useAppSelector(state => state.settings);
  const {documentName, onDocumentNameChange, nameError} = useDocumentName(document);
  const {convertDocument} = useDocument();
  const [originalFile, setOriginalFile] = useState<File>();
  const params = useParams();
  const {navigate} = useNavigation();

  const isPdfView = document?.type === DocumentType.PDF;

  const convertUrlToFile = useCallback(async (originalFileUrl: string) => {
    const file = await fetch(originalFileUrl)
      .then(r => r.blob())
      .then(blobFile => new File([blobFile], 'fileName', {type: 'application/pdf'}));
    setOriginalFile(file);
  }, []);

  useEffect(() => {
    if (isPdfView && files.length === 1) {
      const url = `${settings?.backendApiUrl}/signing-portal/api/documents/${document.id}/download/${files[0].id}`;
      convertUrlToFile(url);
    }
  }, [convertUrlToFile, document?.id, files, isPdfView, originalFiles, settings?.backendApiUrl]);

  const handleSignatureTypeSwitch = () => {
    if (document) {
      convertDocument(
        document.id,
        document.type === DocumentType.PDF ? DocumentType.ADOC : DocumentType.PDF
      ).then(response => {
        if (params.id && response && params.id !== response.id) {
          navigate(`/document/${response.id}`, {replace: true});
        }
      });
    }
  };

  const switchDisabled =
    conversionInProgress || !document?.creator || !document?.availableConversionTypes.length;

  const tooltipContent = (
    <div className={styles.tooltipContent}>
      <Text variant="mediumBold" className={styles.tooltipTitle}>
        {t('files.section.signature.type')}
      </Text>
      <Text variant="small">
        {isPdfView
          ? t('files.section.signature.type.tooltip.pdf')
          : t('files.section.signature.type.tooltip.adoc')}
      </Text>
    </div>
  );

  return (
    <div className={clsx(hasPadding && styles.containerWithPadding, styles.container)}>
      <FilesList
        isPdfView={isPdfView}
        isRemovable={isFilesRemovable}
        files={files}
        onFileRemove={id => onFileRemove(id)}
      />
      <div>
        <Switch
          leftText={DocumentType.ADOC}
          rightText={DocumentType.PDF}
          checked={isPdfView}
          onChange={handleSignatureTypeSwitch}
          disabled={switchDisabled}
          label={t('files.section.choose.type')}
          tooltipContent={tooltipContent}
          className={styles.switchContainer}
        />
        {isPdfView ? (
          <UnderlineButton
            className={styles.openPDFButton}
            rightIcon={<NextIcon />}
            onClick={() => {
              setIsPreviewModalOpen(true);
            }}>
            {t('files.section.container.pdf.preview')}
          </UnderlineButton>
        ) : (
          <Input
            value={documentName}
            onChange={onDocumentNameChange}
            label={t('files.section.container.name')}
            className={styles.containerInput}
            errorMsg={nameError ? nameError.message : undefined}
          />
        )}

        {isPdfView && !!originalFile ? (
          <PdfModal
            file={originalFile}
            isOpen={isPreviewModalOpen}
            onClose={() => setIsPreviewModalOpen(false)}
          />
        ) : null}
      </div>
    </div>
  );
}
