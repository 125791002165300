import {useAppDispatch, useAppSelector} from 'store';
import clsx from 'clsx';
import {ReactComponent as BlankDocumentIcon} from 'assets/icons/BlankDocumentIcon.svg';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {ReactComponent as ContactBookIcon} from 'assets/icons/ContactBookIcon.svg';
import {ReactComponent as FileDownloadIcon} from 'assets/icons/FileDownloadIcon.svg';
import {ReactComponent as TrashIcon} from 'assets/icons/TrashIcon.svg';
import {ReactComponent as CheckIcon} from 'assets/icons/CheckIcon.svg';
import {ReactComponent as MultipleDocumentsIcon} from 'assets/icons/MultipleDocumentsIcon.svg';

import {Text} from 'features/components/text/Text';
import {useDocument} from 'hooks/documentsHooks';
import {DocumentType, SignatureType, SignerStatusType} from 'types/document';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {Button} from 'features/components/button/Button';

import {SignaturesTable} from 'features/components/signatures-table/SignaturesTable';
import {setIsModalOpen} from 'store/reducers/modal';
import {useLocation, useParams} from 'react-router-dom';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useLazyGetDocumentByIdQuery} from 'store/api/document';
import {useTranslation} from 'hooks/translationHooks';
import {startSigning} from 'store/reducers/signature';
import {useSignDocument} from 'hooks/signingHooks';
import {clearDocument, setDocument, startLoading} from 'store/reducers/document';
import {useScreenSize} from 'hooks/useScreenSize';
import {useSignature} from 'hooks/signatureHooks';
import {Event} from 'types/event';
import {useEvent} from 'hooks/eventHooks';
import {FilesContainer} from 'features/components/files-container/FilesContainer';
import {useSignDocumentContainer} from 'hooks/useSignDocumentContainer';
import {ProgressBar} from 'features/components/progress-bar/ProgressBar';
import {FileInputDrop} from 'features/components/file-input-drop/FileInputDrop';
import {Tooltip} from 'antd';
import {userSelectors} from 'store/reducers/user';
import {useNavigation} from 'hooks/navigateHooks';
import {ErrorDto} from 'types/shared';
import {SwitchCertificateView} from 'pages/dashboard/sections/document/SwitchCertificateView';
import {useChangeRole} from 'hooks/userHooks';
import styles from './Document.module.scss';
import {Layout} from '../../Layout';

type LocationState = {
  isBackButtonDisabled?: boolean;
  fromPath?: string;
};

export function Document() {
  const {document} = useAppSelector(state => state.document);
  const {changeRole} = useChangeRole();
  const {t} = useTranslation();
  const {isSmall} = useScreenSize();
  const {isMySignature, findMySignature} = useSignature();
  const {signDocument, cancel} = useSignDocument();
  const {
    showProgressBar,
    uploadingProgress,
    handleCancel,
    handleFilesChange,
    handleFileRemove,
    isDocumentLoading,
  } = useSignDocumentContainer();
  const {createEvent, getEventMessage} = useEvent();
  const {event} = useAppSelector(state => state.event);

  const dispatch = useAppDispatch();
  const {isLoading, documentDownloadLink, deleteDocument, deleteSigner, appendShareToSigners} =
    useDocument();
  const [trigger] = useLazyGetDocumentByIdQuery();
  const {navigate} = useNavigation();
  const location = useLocation();
  const locationStateRef = useRef<LocationState | null>(null);
  const roles = useAppSelector(state => userSelectors.getRoles(state.user));
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));
  const activeRoleRef = useRef(activeRole?.roleId);
  const [changingCert, setChangingCert] = useState(false);
  const [switchCertTo, setSwitchCertTo] = useState<string | undefined>(undefined);

  const {files, signatures} = document || {};

  const {id} = useParams();

  useEffect(() => {
    if (location.state) {
      locationStateRef.current = location.state;
    }
  }, [location.state]);

  const getDocument = useCallback(() => {
    if (id) {
      dispatch(startLoading());
      trigger(id).then(result => {
        if (result.status === 'rejected') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (result.error && result.error.status === 404) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const errors = result.error.data.errors as ErrorDto[];
            const shouldSwitchCertTo = errors.find(
              e => e.code === 1000 && !!e.extensions?.requiredRoleId
            )?.extensions?.requiredRoleId;
            setSwitchCertTo(shouldSwitchCertTo);
          }
          dispatch(clearDocument());
        } else if (result.data) {
          setSwitchCertTo(undefined);
          dispatch(
            setDocument({
              document: result.data,
            })
          );
        }
        setChangingCert(false);
      });
    }
  }, [dispatch, id, trigger]);

  const handleSign = async () => {
    if (document) {
      dispatch(startSigning());
      signDocument(document.id).then(() =>
        dispatch(setIsModalOpen({isOpen: true, type: 'Signing', onClose: cancel}))
      );
    }
  };

  const handleShare = useCallback(() => {
    if (document) {
      appendShareToSigners(document.id).then(() => {
        getDocument();
        createEvent(Event.DOCUMENT_SHARED);
      });
    }
  }, [document, appendShareToSigners, getDocument, createEvent]);

  useEffect(() => {
    if (activeRole?.roleId !== activeRoleRef.current) {
      activeRoleRef.current = activeRole?.roleId;
      if (!switchCertTo && !changingCert) {
        navigate('/');
      } else {
        getDocument();
      }
    }
  }, [activeRole, changingCert, getDocument, navigate, switchCertTo]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assigneeCode = urlParams.get('assignee');
    const assigneeType = urlParams.get('assigneeType');
    const urlHasRoleInfo = !!assigneeCode && !!assigneeType;

    const roleFromUrl = roles.find(
      r =>
        r.type === assigneeType &&
        (r.type === 'PRIVATE'
          ? r.personalCode === assigneeCode
          : r.organizationCode === assigneeCode)
    );
    const shouldSwitchRole =
      urlHasRoleInfo && !!roleFromUrl && roleFromUrl.roleId !== activeRole?.roleId;

    if (shouldSwitchRole) {
      setChangingCert(true);
      changeRole(roleFromUrl.roleId);
    } else {
      getDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeRole, getDocument]);

  if (switchCertTo) {
    return <SwitchCertificateView roleId={switchCertTo} />;
  }

  if (!document) {
    return (
      <Layout>
        <div className={styles.noDocumentContainer}>
          <div className={styles.noDocumentContent}>
            <MultipleDocumentsIcon />
            {!isLoading && <Text variant="medium">{t('document.page.not.found')}</Text>}
          </div>
        </div>
      </Layout>
    );
  }

  const mySignature = findMySignature(signatures);
  const myApprovalNeeded = mySignature?.type === SignatureType.APPROVAL;
  const declineEnabled = myApprovalNeeded && mySignature?.status === SignerStatusType.PENDING;
  const approveEnabled =
    myApprovalNeeded &&
    (mySignature?.status === SignerStatusType.PENDING ||
      mySignature?.status === SignerStatusType.DECLINED);

  const handleDecline = () => {
    dispatch(
      setIsModalOpen({isOpen: true, type: 'Comment', id: document?.id, documentAction: 'Decline'})
    );
  };

  const handleApprove = () => {
    dispatch(
      setIsModalOpen({isOpen: true, type: 'Comment', id: document?.id, documentAction: 'Approve'})
    );
  };

  const handleRemoveSigner = (signatureId: string) => {
    if (document) {
      deleteSigner({id: document.id, signatureId});
    }
  };

  const isSigned = !!document?.signatures.some(
    signature => signature.status === SignerStatusType.SIGNED
  );
  const mySignatureNeeded = mySignature
    ? mySignature.status === SignerStatusType.PENDING ||
      mySignature.status === SignerStatusType.DECLINED
    : true;
  const otherSignatureNeeded = !!signatures?.some(
    signature => !isMySignature(signature) && signature.status === SignerStatusType.PENDING
  );
  const approvalIsRequested = !!document?.signatures.some(
    signature =>
      signature.type === SignatureType.APPROVAL &&
      (signature.status === SignerStatusType.DECLINED ||
        signature.status === SignerStatusType.PENDING)
  );
  const showControls = mySignatureNeeded || otherSignatureNeeded;
  const isDeniedToAddFiles =
    document?.type === DocumentType.ADOC &&
    !!document?.signatures.some(s => s.status === SignerStatusType.SIGNED);

  const uploadSection = (() => {
    if (showProgressBar) {
      return (
        <div className={styles.progressBarWrapper}>
          <ProgressBar
            progress={uploadingProgress}
            className={styles.progressBarContainer}
            progressBarClassName={styles.progressBar}
            onCancel={handleCancel}
          />
        </div>
      );
    }

    return !isDeniedToAddFiles && document?.creator ? (
      <FileInputDrop
        className={styles.fileInputDrop}
        buttonName={t('signing.page.add.files')}
        onChange={handleFilesChange}
      />
    ) : null;
  })();

  const handleBack = () => {
    dispatch(clearDocument());
    if (locationStateRef.current?.fromPath) {
      navigate(locationStateRef.current?.fromPath);
    } else {
      navigate('/');
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.documentInfoSection}>
          {(event === Event.DOCUMENT_SIGNED ||
            event === Event.DOCUMENT_SHARED ||
            event === Event.SIGNER_ADDED ||
            event === Event.SIGNER_DELETED) && (
            <div className={styles.isSignedContainer}>
              <div className={styles.isSignedTitle}>
                <CheckIcon />
                <Text variant="large">{getEventMessage(event)}</Text>
              </div>
            </div>
          )}
          <div className={styles.titleSection}>
            <Text variant="xLarge" className={styles.documentName}>
              {document?.name}
            </Text>
            {isSmall && (
              <div className={styles.containerType}>
                <BlankDocumentIcon />
                <Text variant="xSmallBold">{document?.type}</Text>
              </div>
            )}
            <div className={styles.buttonsContainer}>
              <Button
                leftIcon={<FileDownloadIcon />}
                variant="secondary"
                className={styles.actionButton}
                onClick={e => {
                  e.preventDefault();
                  window.location.href = documentDownloadLink;
                }}>
                {t('document.page.download.button')}
              </Button>
              {document?.creator ? (
                <Button
                  leftIcon={<TrashIcon />}
                  variant="secondary"
                  className={styles.deleteButton}
                  onClick={() =>
                    dispatch(
                      setIsModalOpen({
                        isOpen: true,
                        type: 'Confirmation',
                        confirmationText: t('settings.page.documents.are.you.sure'),
                        onSubmit: () => {
                          if (document) {
                            deleteDocument({id: document.id}).then(() => {
                              if (locationStateRef.current?.fromPath === '/new') {
                                navigate('/new');
                              } else {
                                navigate('/');
                              }
                            });
                          }
                        },
                      })
                    )
                  }
                />
              ) : null}
            </div>
          </div>
          {!isSmall && (
            <div className={styles.containerType}>
              <BlankDocumentIcon />
              <Text variant="xSmallBold">{document?.type}</Text>
            </div>
          )}
          {uploadSection}
          <div className={styles.filesList}>
            <FilesContainer
              files={files || []}
              onFileRemove={handleFileRemove}
              isFilesRemovable={!isSigned && document?.creator}
              hasPadding={false}
            />
          </div>
        </div>
        <div className={clsx(styles.signaturesSection)}>
          <Text variant="smallBold" className={styles.title} color="contrastGrey">
            {t('document.page.signatures').toUpperCase()}
          </Text>
          <SignaturesTable
            signatures={signatures || []}
            className={styles.signaturesTable}
            onRemove={handleRemoveSigner}
            isCreator={!!document?.creator}
          />
          {document?.creator ? (
            <Button
              leftIcon={<ContactBookIcon />}
              variant="secondary"
              className={styles.addMoreSignersButton}
              onClick={() => dispatch(setIsModalOpen({isOpen: true, type: 'Signer', id}))}>
              {t('document.page.add.more.signers.button')}
            </Button>
          ) : null}
        </div>
        <div className={styles.controls}>
          {!locationStateRef.current?.isBackButtonDisabled ? (
            <UnderlineButton
              leftIcon={<ArrowRightIcon />}
              className={styles.backButton}
              onClick={handleBack}>
              {t('document.page.back.button')}
            </UnderlineButton>
          ) : (
            <div />
          )}
          {showControls && (
            <div className={styles.gap}>
              <Tooltip
                placement="top"
                title={!otherSignatureNeeded ? t('document.page.other.signers.needed') : ''}
                arrow>
                <span />
                <Button
                  variant="secondary"
                  rightIcon={<ArrowRightIcon />}
                  disabled={!document?.creator || !otherSignatureNeeded || isDocumentLoading}
                  onClick={handleShare}
                  className={styles.controlButton}>
                  {t('signing.page.signers.sign.share')}
                </Button>
              </Tooltip>
              {myApprovalNeeded ? (
                <div className={styles.approveControls}>
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    className={styles.declineButton}
                    disabled={!declineEnabled || isDocumentLoading}
                    onClick={handleDecline}>
                    {t('document.page.decline')}
                  </Button>
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    className={styles.controlButton}
                    disabled={!approveEnabled || isDocumentLoading}
                    onClick={handleApprove}>
                    {t('document.page.approve')}
                  </Button>
                </div>
              ) : (
                <Tooltip
                  placement="top"
                  title={!mySignatureNeeded ? t('signing.page.user.already.signed.tooltip') : ''}
                  arrow>
                  <span />
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    className={styles.controlButton}
                    disabled={!mySignatureNeeded || approvalIsRequested || isDocumentLoading}
                    onClick={handleSign}>
                    {t('document.page.add.signature.button')}
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
