import {Hero} from 'pages/home/Hero';
import {HowItWorks} from 'pages/home/HowItWorks';

export function HomePage(): JSX.Element {
  return (
    <>
      {/* <Banner />  */}
      <Hero />
      <HowItWorks />
    </>
  );
}
