import {ReactNode} from 'react';
import clsx from 'clsx';

import styles from './UnderlineButton.module.scss';

type Props = {
  className?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  children: string;
  onClick?: () => void;
};

export function UnderlineButton({className, leftIcon, children, rightIcon, onClick}: Props) {
  return (
    <button type="button" className={clsx(styles.underlineButton, className)} onClick={onClick}>
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </button>
  );
}
