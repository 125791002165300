import {Text} from 'features/components/text/Text';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ContactBookIcon} from 'assets/icons/ContactBookIcon.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';

import {setIsModalOpen} from 'store/reducers/modal';
import {useAppDispatch, useAppSelector} from 'store';
import {useLazyGetContactsQuery} from 'store/api/contact';
import {useEffect} from 'react';
import {setContacts} from 'store/reducers/contact';
import {useContact} from 'hooks/contactHooks';

import {useTranslation} from 'hooks/translationHooks';
import {Event} from 'types/event';
import {Notification} from 'features/components/notification/Notification';
import {Accordion} from 'features/components/accordion/Accordion';
import {useEvent} from 'hooks/eventHooks';
import styles from './Contacts.module.scss';

import {Layout} from '../../Layout';

export function Contacts() {
  const {t} = useTranslation();
  const [fetchContacts, {data: contactsData}] = useLazyGetContactsQuery();
  const {deleteContact, isLoading} = useContact();
  const {createEvent, getEventMessage} = useEvent();

  const dispatch = useAppDispatch();
  const {contacts} = useAppSelector(state => state.contact);
  const {event} = useAppSelector(state => state.event);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  useEffect(() => {
    if (contactsData) {
      dispatch(setContacts({contacts: contactsData}));
    }
  }, [dispatch, contactsData]);

  const handleAddNewContact = () => dispatch(setIsModalOpen({isOpen: true, type: 'Contact'}));

  const handleEditContact = (id: string) => {
    dispatch(setIsModalOpen({isOpen: true, type: 'Contact', id}));
  };

  const handleDeleteContact = (id: string) => {
    deleteContact(id).then(() => createEvent(Event.CONTACT_DELETED));
  };

  if (!contactsData?.length) {
    return (
      <Layout>
        <div className={styles.container}>
          <Text variant="xLarge">{t('settings.page.contacts.title')}</Text>
          <Button
            variant="secondary"
            leftIcon={<ContactBookIcon />}
            className={styles.addNewButton}
            onClick={handleAddNewContact}>
            {t('settings.page.contacts.add.new.button')}
          </Button>
          <div className={styles.noContactsContent}>
            <ContactBookIcon />
            <Text variant="medium">{t('contacts.page.not.found')}</Text>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        {(event === Event.CONTACT_ADDED || event === Event.CONTACT_DELETED) && (
          <div className={styles.successMessage}>
            <Notification variant="success">
              <Text variant="large">{getEventMessage(event)}</Text>
            </Notification>
          </div>
        )}
        <Text variant="xLarge">{t('settings.page.contacts.title')}</Text>
        <Button
          variant="secondary"
          leftIcon={<ContactBookIcon />}
          className={styles.addNewButton}
          onClick={handleAddNewContact}>
          {t('settings.page.contacts.add.new.button')}
        </Button>
        <table className={styles.contactsTable}>
          <thead>
            <tr>
              <th>
                <Text variant="xSmall" color="contrastGrey">
                  {t('settings.page.contacts.signer')}
                </Text>
              </th>
              <th>
                <Text variant="xSmall" color="contrastGrey">
                  {t('contacts.page.fin')}
                </Text>
              </th>
              <th>
                <Text variant="xSmall" color="contrastGrey">
                  {t('settings.page.contacts.email')}
                </Text>
              </th>
              <th>
                <span />
              </th>
            </tr>
          </thead>
          <tbody>
            {(contacts || []).map(contact => (
              <tr key={contact.id}>
                <td>
                  <Text variant="mediumBold">{contact.name}</Text>
                </td>
                <td>
                  <Text variant="medium">{contact.code}</Text>
                </td>
                <td>
                  <Text variant="medium">{contact.email}</Text>
                </td>
                <td className={styles.rowControls}>
                  <button
                    type="button"
                    className={styles.editButton}
                    disabled={isLoading}
                    onClick={() => handleEditContact(contact.id)}>
                    <Text variant="mediumBold" color="pureBlue">
                      {t('settings.page.contacts.add.edit.button')}
                    </Text>
                  </button>

                  <button
                    type="button"
                    className={styles.closeButton}
                    disabled={isLoading}
                    onClick={() => {
                      dispatch(
                        setIsModalOpen({
                          isOpen: true,
                          type: 'Confirmation',
                          confirmationText: `${t('contacts.page.are.you.sure')} ${contact.name}?`,
                          onSubmit: () => handleDeleteContact(contact.id),
                        })
                      );
                    }}>
                    <CloseIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.accordionWrapper}>
          <Text variant="xSmall" className={styles.accordionTitle}>
            {t('contacts.page.signer.pin')}
          </Text>
          <Accordion
            items={(contacts || []).map(contact => ({
              id: contact.id,
              title: (
                <div>
                  <Text variant="mediumBold">{contact.name}</Text>
                  <Text variant="medium">{contact.code}</Text>
                </div>
              ),
              content: (
                <div>
                  <div>
                    <Text variant="xSmall" color="contrastGrey">
                      {t('settings.page.contacts.email')}
                    </Text>
                    <Text variant="medium">{contact.email}</Text>
                  </div>
                  <div className={styles.accordionItemControls}>
                    <button
                      type="button"
                      className={styles.editButton}
                      disabled={isLoading}
                      onClick={() => handleEditContact(contact.id)}>
                      <Text variant="mediumBold" color="pureBlue">
                        {t('settings.page.contacts.add.edit.button')}
                      </Text>
                    </button>

                    <button
                      type="button"
                      className={styles.closeButton}
                      disabled={isLoading}
                      onClick={() => {
                        dispatch(
                          setIsModalOpen({
                            isOpen: true,
                            type: 'Confirmation',
                            confirmationText: `${t('contacts.page.are.you.sure')} ${contact.name}?`,
                            onSubmit: () => handleDeleteContact(contact.id),
                          })
                        );
                      }}>
                      <CloseIcon />
                    </button>
                  </div>
                </div>
              ),
            }))}
          />
        </div>
      </div>
    </Layout>
  );
}
