import clsx from 'clsx';
import {useRef} from 'react';
import {useTranslation} from 'hooks/translationHooks';
import {Text} from '../text/Text';

import styles from './Input.module.scss';

type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  label?: string;
  optional?: boolean;
  required?: boolean;
  onEnterPress?: () => void;
  errorMsg?: string;
  showErrorMsg?: boolean;
  type?: 'textarea' | 'input';
  placeholder?: string;
};

export function Input({
  onChange,
  value,
  label,
  className,
  optional,
  required,
  onEnterPress,
  errorMsg,
  showErrorMsg = true,
  type = 'input',
  placeholder,
}: Props) {
  const {t} = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {label ? (
        <Text variant="smallBold">
          {label}
          {optional ? (
            <span className={styles.optionalLabel}> ({t('general.fields.optional')})</span>
          ) : null}
        </Text>
      ) : null}
      {type === 'textarea' ? (
        <textarea
          ref={textAreaRef}
          className={clsx(styles.textArea, errorMsg && styles.inputError)}
          value={value}
          onChange={e => onChange(e.target.value)}
          required={required}
          onKeyDown={e => {
            if (e.key === 'Enter' && onEnterPress) {
              onEnterPress();
              if (textAreaRef.current) {
                textAreaRef.current.blur();
              }
            }
          }}
          placeholder={placeholder}
        />
      ) : (
        <input
          ref={inputRef}
          className={clsx(styles.input, errorMsg && styles.inputError)}
          value={value}
          onChange={e => onChange(e.target.value)}
          required={required}
          onKeyDown={e => {
            if (e.key === 'Enter' && onEnterPress) {
              onEnterPress();
              if (inputRef.current) {
                inputRef.current.blur();
              }
            }
          }}
          placeholder={placeholder}
        />
      )}
      {showErrorMsg && errorMsg && (
        <div className={styles.errorMessage}>
          <Text variant="small" color="red">
            {errorMsg}
          </Text>
        </div>
      )}
    </div>
  );
}
