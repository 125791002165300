import {Signature, SignatureType, SignerStatusType} from 'types/document';
import {formatDate} from 'utils/formatDate';
import clsx from 'clsx';
import {useTranslation} from 'hooks/translationHooks';
import {useSignature} from 'hooks/signatureHooks';
import styles from './SignatureStatus.module.scss';

type Props = {
  className?: string;
  signature: Signature;
};

export function SignatureStatus({signature, className, ...rest}: Props) {
  const {t} = useTranslation();
  const {isMySignature} = useSignature();

  if (signature.status === SignerStatusType.PENDING) {
    const date = signature.notificationLastSentAt
      ? formatDate(signature.notificationLastSentAt || '')
      : formatDate(signature.createdAt || '');
    if (signature.type === SignatureType.SIGNATURE) {
      return (
        <div className={clsx(styles.waitingSignatureStatus, className)} {...rest}>
          {`${
            isMySignature(signature)
              ? t('settings.page.documents.waiting.my.signature')
              : t('settings.page.documents.waiting.signature')
          } ${date}`.trim()}
        </div>
      );
    }

    if (signature.type === SignatureType.APPROVAL) {
      return (
        <div className={clsx(styles.waitingSignatureStatus, className)} {...rest}>
          {`${
            isMySignature(signature)
              ? t('settings.page.documents.waiting.my.approval')
              : t('settings.page.documents.waiting.approval')
          } ${date}`.trim()}
        </div>
      );
    }
  }

  if (signature.status === SignerStatusType.SIGNED && signature.type === SignatureType.SIGNATURE) {
    return (
      <div className={clsx(styles.signedStatus, className)} {...rest}>
        {`${t('settings.page.documents.signed')} ${formatDate(signature.signedAt || '')}`}
      </div>
    );
  }

  if (signature.status === SignerStatusType.DECLINED) {
    return (
      <div className={clsx(styles.declinedSignatureStatus, className)} {...rest}>
        {`${t('settings.page.documents.declined')} ${
          signature.signedAt ? formatDate(signature.signedAt) : null
        }`}
      </div>
    );
  }

  if (signature.status === SignerStatusType.SIGNED && signature.type === SignatureType.APPROVAL) {
    return (
      <div className={clsx(styles.signedStatus, className)} {...rest}>
        {`${t('settings.page.documents.approved')} ${
          signature.signedAt ? formatDate(signature.signedAt) : null
        }`}
      </div>
    );
  }

  return null;
}
