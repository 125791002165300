import React from 'react';
import clsx from 'clsx';
import styles from './Text.module.scss';

interface Props {
  variant?:
    | 'largeHeading'
    | 'xxLarge'
    | 'xLarge'
    | 'xLargeBold'
    | 'large'
    | 'largeBold'
    | 'medium'
    | 'mediumBold'
    | 'small'
    | 'smallBold'
    | 'smallCaps'
    | 'xSmall'
    | 'xSmallBold';
  children: React.ReactNode;
  className?: string;
  color?:
    | 'white'
    | 'green'
    | 'darkBlue'
    | 'lightBlue'
    | 'contrastGrey'
    | 'pureBlue'
    | 'red'
    | 'spanishBistre';
  textAlign?: 'center' | 'left' | 'right';
  textTransform?: 'transformLowercase' | 'transformNone' | 'transformUpperCase';
}

export function Text({
  children,
  variant = 'medium',
  color = 'darkBlue',
  textAlign = 'left',
  textTransform = 'transformNone',
  className,
}: Props): JSX.Element {
  return (
    <p
      className={clsx(
        styles[variant],
        styles[color],
        styles[textAlign],
        styles[textTransform],
        className
      )}>
      {children}
    </p>
  );
}
