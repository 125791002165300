import {AuthenticationRequest, AuthenticationResponse} from 'types/auth';
import {User} from 'types/user';
import {apiSlice} from './apiSlice';

export const APIUserSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    signIn: builder.mutation<AuthenticationResponse, AuthenticationRequest>({
      query: ({...body}) => ({
        url: `/auth/mobileid`,
        method: 'POST',
        body,
      }),
    }),
    cancelSignIn: builder.mutation<void, void>({
      query: () => ({
        url: `/auth/mobileid`,
        method: 'DELETE',
      }),
    }),
    pollAuthInfo: builder.mutation<AuthenticationResponse, void>({
      query: () => ({
        url: `/auth/mobileid`,
        method: 'PUT',
      }),
    }),
    changeRole: builder.mutation<User, string>({
      query: roleId => {
        return {
          url: `/auth/user/${roleId}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Documents'],
    }),
    getUser: builder.query<User, void>({
      query: () => `/auth/user`,
    }),
    signOut: builder.query<void, void>({
      query: () => `/logout`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  usePollAuthInfoMutation,
  useLazySignOutQuery,
  useLazyGetUserQuery,
  useGetUserQuery,
  useChangeRoleMutation,
  useCancelSignInMutation,
} = APIUserSlice;
