import {createSlice} from '@reduxjs/toolkit';

export type ModalType =
  | 'Login'
  | 'Signing'
  | 'Contact'
  | 'Signer'
  | 'Comment'
  | 'Confirmation'
  | 'OldPortalNotice';

export type DocumentAction = 'Approve' | 'Decline';

export interface ModalStoreProps {
  isOpen: boolean;
  type?: ModalType;
  id?: string;
  documentAction?: DocumentAction;
  onClose?: () => void;
  onSubmit?: () => void;
  confirmationText?: string;
}

const initialState: ModalStoreProps = {
  isOpen: false,
  type: undefined,
  id: undefined,
  onClose: undefined,
};

const modalReducer = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setIsModalOpen(state, action) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
      state.id = action.payload.id;
      state.documentAction = action.payload.documentAction;
      state.onClose = action.payload.onClose;
      state.onSubmit = action.payload.onSubmit;
      state.confirmationText = action.payload.confirmationText;
    },
  },
});

export default modalReducer.reducer;

export const {setIsModalOpen} = modalReducer.actions;
