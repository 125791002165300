import {ReactComponent as CheckIcon} from 'assets/icons/CheckIcon.svg';
import {ReactComponent as ErrorIcon} from 'assets/icons/ErrorIcon.svg';
import clsx from 'clsx';
import {ReactNode} from 'react';
import styles from './Notification.module.scss';

interface Props {
  children: ReactNode;
  variant: 'success' | 'error';
  fullWidth?: boolean;
}

export function Notification(props: Props): JSX.Element {
  const {children, variant, fullWidth} = props;

  const getIcon = () => {
    if (variant === 'success') {
      return <CheckIcon />;
    }

    if (variant === 'error') {
      return <ErrorIcon />;
    }

    return null;
  };

  return (
    <div className={clsx(styles.container, fullWidth && styles.fullWidth, styles[variant])}>
      {getIcon()}
      {children}
    </div>
  );
}
