export enum PreferenceName {
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  NOTIFY_ON_SIGN_REQUEST = 'NOTIFY_ON_SIGN_REQUEST',
  NOTIFY_ON_DOCUMENT_SIGNED = 'NOTIFY_ON_DOCUMENT_SIGNED',
  NOTIFY_ALL_PARTIES_SIGNED = 'NOTIFY_ALL_PARTIES_SIGNED',
  LANGUAGE = 'LANGUAGE',
}

enum PreferenceType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
}

export type PreferenceSettings = {
  name: PreferenceName;
  value: string | boolean;
  type: PreferenceType;
};

export type PreferenceRequest = {
  name: PreferenceName;
  value: string | boolean;
};
