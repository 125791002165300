import clsx from 'clsx';
import {Dropdown, MenuProps} from 'antd';
import {AllowedLanguages} from 'types/translation';
import {useAppSelector} from 'store';
import {useTranslation} from 'hooks/translationHooks';
import {ReactComponent as ChevronDownIcon} from 'assets/icons/ChevronDownIcon.svg';
import styles from './LanguagePicker.module.scss';
import {defaultSelectedLanguageCode, languages, useLanguageChange} from './LanguagePicker.utils';

type Props = {
  minified?: boolean;
  className?: string;
};

type MinifiedProps = {
  handleLanguageChange(code: AllowedLanguages): void;
  selectedLanguage: AllowedLanguages;
};

function Minified({handleLanguageChange, selectedLanguage}: MinifiedProps) {
  const {t} = useTranslation();
  return (
    <div className={styles.languagePickerMinified}>
      {languages.map(({name, code}) => {
        if (code === selectedLanguage) {
          return <div key={code}>{t(name)}</div>;
        }
        return (
          <div key={code} onClick={() => handleLanguageChange(code)}>
            {code.toUpperCase()}
          </div>
        );
      })}
    </div>
  );
}

export function LanguagePicker({minified = false, className}: Props): JSX.Element {
  const {t} = useTranslation();
  const {handleLanguageChange} = useLanguageChange();

  const {activeLanguage} = useAppSelector(state => state.translation);

  const selectedLanguage = activeLanguage || defaultSelectedLanguageCode;

  const items: MenuProps['items'] = languages.map(({code, name}) => {
    const label = <div>{t(name)}</div>;
    return {
      label,
      key: code,
      onClick: () => handleLanguageChange(code),
    };
  });

  const getActiveLangName = () => {
    const active = languages.filter(({code}) => code === activeLanguage);
    if (active.length) {
      return t(active[0].activeName);
    }
    return '';
  };

  if (minified)
    return (
      <Minified handleLanguageChange={handleLanguageChange} selectedLanguage={selectedLanguage} />
    );

  return (
    <Dropdown menu={{items}} trigger={['click']} className={clsx(styles.languagePicker, className)}>
      <a onClick={e => e.preventDefault()}>
        <div className={styles.languagePickerLink}>
          {getActiveLangName()}
          <ChevronDownIcon className={styles.languagePickerIcon} />
        </div>
      </a>
    </Dropdown>
  );
}
