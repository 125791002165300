import * as React from 'react';
import {ChangeEvent} from 'react';
import {FormInstance, Input, InputProps, InputRef} from 'antd';
import {formatPhoneNumber} from 'utils/formatPhoneNumber';

type Props = InputProps &
  React.RefAttributes<InputRef> & {
    formInstance: FormInstance;
    value: string;
    setValue: (val: string) => void;
  };

export function PhoneNumberInput(props: Props) {
  const {formInstance, value, setValue, ...otherProps} = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const formatted = formatPhoneNumber(newValue);

    formInstance.setFieldValue('phoneNumber', formatted);
    setValue(formatted);
  };

  return <Input {...otherProps} value={value} onChange={handleChange} maxLength={15} />;
}
