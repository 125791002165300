import {Progress} from 'antd';
import clsx from 'clsx';
import {useTranslation} from 'hooks/translationHooks';
import styles from './ProgressBar.module.scss';
import {UnderlineButton} from '../button/UnderlineButton';
import {Text} from '../text/Text';

type Props = {
  className?: string;
  progressBarClassName?: string;
  progress: number;
  onCancel?: () => void;
};

export function ProgressBar({className, progressBarClassName, progress, onCancel}: Props) {
  const {t} = useTranslation();

  return (
    <div className={clsx(styles.progressBarContainer, className)}>
      <Text variant="mediumBold" color="green">
        {t('progress.bar.uploading')}
      </Text>
      <Progress
        percent={progress}
        strokeColor="#28AB82"
        className={clsx(styles.progressBar, progressBarClassName)}
      />
      <UnderlineButton onClick={onCancel}>{t('progress.bar.cancel.button')}</UnderlineButton>
    </div>
  );
}
