import clsx from 'clsx';
import {Checkbox} from 'features/components/input/Checkbox';
import {Document} from 'types/document';
import {useEffect, useState} from 'react';
import {Text} from 'features/components/text/Text';
import {ReactComponent as NoDocumentsIcon} from 'assets/icons/NoDocumentsIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {useAppDispatch} from 'store';
import {setSort} from 'store/reducers/documentsList';
import {SortingOrder, SortingType} from 'pages/dashboard/sections/documents/util/documentUtil';
import styles from './DocumentsList.module.scss';
import {Sort} from '../sorting/DocumentsSorting';
import {Name} from './components/Name';
import {Type} from './components/Type';
import {DateAndStatus} from './components/DateAndStatus';
import {Signatures} from './components/Signatures';

type DocumentsListTypes = {
  paginatedDocuments: Document[];
  selectedDocuments: string[];
  filesSelectionActive: boolean;
  handleFileCheckboxSelect(documentId: string): void;
};

const {NAME, CONTAINER_TYPE, DATE_AND_STATUS, SIGNATURES} = SortingType;
const {ASC, DESC} = SortingOrder;

export function DocumentsList({
  paginatedDocuments,
  selectedDocuments,
  filesSelectionActive,
  handleFileCheckboxSelect,
}: DocumentsListTypes) {
  const [selectedSort, setSelectedSort] = useState<SortingType | undefined>(DATE_AND_STATUS);
  const [selectedOrder, setSelectedOrder] = useState<SortingOrder | undefined>(ASC);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedSort && selectedOrder) {
      const sorting = `${selectedSort},${selectedOrder}`;
      dispatch(setSort(sorting));
    }
  }, [dispatch, selectedSort, selectedOrder]);

  function handleSorting(sort: SortingType) {
    if (selectedSort === sort) {
      setSelectedOrder(selectedOrder === ASC ? DESC : ASC);
    } else {
      setSelectedSort(sort);
      setSelectedOrder(ASC);
    }
  }

  if (paginatedDocuments.length === 0) {
    return (
      <div className={styles.emptyView}>
        <NoDocumentsIcon />
        <Text>{t('settings.page.documents.not.found')}</Text>
      </div>
    );
  }

  return (
    <>
      {paginatedDocuments.map(({id, signatures, name, type}, index) => {
        const isDocumentSelected = selectedDocuments.includes(id);

        const isHidden = index === 0 && !filesSelectionActive;

        const isNameActive = selectedSort === NAME;
        const isTypeActive = selectedSort === CONTAINER_TYPE;
        const isDateAndStatusActive = selectedSort === DATE_AND_STATUS;
        const isSignaturesActive = selectedSort === SIGNATURES;

        const sortClassName = (isActive: boolean) =>
          clsx(
            styles.sort,
            isActive ? styles.activeText : styles.notActiveText,
            isActive && selectedOrder === ASC && styles.typeAscActive,
            isActive && selectedOrder === DESC && styles.typeDescActive
          );

        return (
          <div
            key={id}
            className={clsx(
              styles.row,
              isDocumentSelected && styles.rowActive,
              filesSelectionActive && styles.rowSelectionMode
            )}
            onClick={filesSelectionActive ? () => handleFileCheckboxSelect(id) : undefined}>
            <div
              className={clsx(
                styles.nameContainer,
                filesSelectionActive && styles.nameContainerActive
              )}>
              {filesSelectionActive ? (
                <Checkbox className={styles.checkbox} checked={isDocumentSelected} />
              ) : null}
              <Sort
                className={sortClassName(isNameActive)}
                hide={isHidden}
                title="settings.page.documents.sorting.name"
                handleSort={() => handleSorting(NAME)}
              />
              <Name name={name} id={id} />
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.signingStatusContainer}>
                <Sort
                  className={sortClassName(isDateAndStatusActive)}
                  hide={isHidden}
                  handleSort={() => handleSorting(DATE_AND_STATUS)}
                  title="settings.page.documents.sorting.date.and.status"
                />
                {!!signatures.length && <DateAndStatus signatures={signatures} />}
              </div>

              <div className={styles.signersContainer}>
                <Sort
                  className={sortClassName(isSignaturesActive)}
                  hide={isHidden}
                  handleSort={() => handleSorting(SIGNATURES)}
                  title="settings.page.documents.sorting.signatures"
                />
                <Signatures signatures={signatures} />
              </div>

              <div className={styles.typeContainer}>
                <Sort
                  className={sortClassName(isTypeActive)}
                  hide={isHidden}
                  handleSort={() => handleSorting(CONTAINER_TYPE)}
                  title="settings.page.documents.sorting.format"
                />
                <Type type={type} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
