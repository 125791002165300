import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ErrorDto, StoreState} from 'types/shared';
import {Document} from 'types/document';

type OriginalFile = {
  name: string;
  url: string;
};

export interface UserStoreState extends StoreState {
  document: Document | null;
  originalFiles: OriginalFile[];
  conversionInProgress: boolean;
  isUploadingFiles: boolean;
}

const initialState: UserStoreState = {
  document: null,
  originalFiles: [],
  isLoading: false,
  isUploadingFiles: false,
  errors: [],
  conversionInProgress: false,
};

const documentReducer = createSlice({
  name: 'document',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startDocumentSigning(state) {
      state.isLoading = true;
      state.errors = [];
    },
    startUpload(state) {
      state.isLoading = true;
      state.isUploadingFiles = true;
      state.errors = [];
    },
    setDocument(state, action) {
      state.document = action.payload.document;
      state.errors = [];
      state.isLoading = false;
      state.isUploadingFiles = false;
      if (action.payload.originalFiles) {
        state.originalFiles = action.payload.originalFiles;
      }
    },
    setDocumentError(state, action: PayloadAction<ErrorDto[]>) {
      state.isLoading = false;
      state.isUploadingFiles = false;
      state.errors = action.payload;
    },
    resetUploadProgress(state) {
      state.isLoading = false;
      state.isUploadingFiles = false;
    },
    startConversion(state) {
      state.conversionInProgress = true;
      state.errors = [];
    },
    updateDocument(state, action) {
      state.conversionInProgress = false;
      state.isLoading = false;
      state.isUploadingFiles = false;
      state.errors = [];
      state.document = action.payload.document;
      if (action.payload.originalFiles) {
        state.originalFiles = [...state.originalFiles, ...action.payload.originalFiles];
      }
    },
    updateDocumentSignature(state, action) {
      state.conversionInProgress = false;
      state.isLoading = false;
      state.isUploadingFiles = false;
      state.errors = [];
      if (state.document && action.payload.signature) {
        state.document.signatures = state.document.signatures.map(signature =>
          signature.id === action.payload.signature.id ? action.payload.signature : signature
        );
      }
    },
    clearDocument() {
      return initialState;
    },
  },
});

export default documentReducer.reducer;

export const {
  startLoading,
  startDocumentSigning,
  startUpload,
  setDocument,
  setDocumentError,
  clearDocument,
  startConversion,
  updateDocument,
  resetUploadProgress,
  updateDocumentSignature,
} = documentReducer.actions;
