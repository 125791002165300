import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useAppSelector} from 'store';
import {
  addUpContact,
  setContacts,
  setContactsError,
  setLoading,
  updateContact,
} from 'store/reducers/contact';

import {
  useAddContactMutation,
  useDeleteContactMutation,
  useEditContactMutation,
} from 'store/api/contact';
import {Contact, ContactRequest} from 'types/contact';
import {useEvent} from 'hooks/eventHooks';
import {Event} from 'types/event';
import {ErrorDto} from 'types/shared';
import {message} from 'antd';

export const useContact = () => {
  const [addContactMutation] = useAddContactMutation();
  const [editContactMutation] = useEditContactMutation();
  const [deleteContactMutation] = useDeleteContactMutation();

  const {isLoading, contacts} = useAppSelector(state => state.contact);
  const dispatch = useDispatch();
  const {createEvent} = useEvent();

  const setContactErrors = useCallback(
    (errors: ErrorDto[]) => {
      if (errors.length) {
        const hasOnlyFieldErrors = errors.every(
          err => err.field || err.code === 1015 || err.code === 1027
        );
        if (hasOnlyFieldErrors) {
          dispatch(setContactsError(errors));
        } else {
          dispatch(setContactsError([]));
          message.error(errors[0].message);
        }
      }
    },
    [dispatch]
  );

  const addContact = useCallback(
    (body: ContactRequest) => {
      dispatch(setLoading());

      return addContactMutation({
        ...body,
      })
        .unwrap()
        .then((response: Contact) => {
          dispatch(
            addUpContact({
              contact: response,
            })
          );
          createEvent(Event.CONTACT_ADDED);
        })
        .catch(e => {
          setContactErrors(e.data.errors);
          throw e;
        });
    },
    [dispatch, addContactMutation, createEvent, setContactErrors]
  );

  const editContact = useCallback(
    (body: Contact) => {
      dispatch(setLoading());

      return editContactMutation(body)
        .unwrap()
        .then((response: Contact) => {
          dispatch(
            updateContact({
              contact: response,
            })
          );
        })
        .catch(e => {
          setContactErrors(e.data.errors);
          throw e;
        });
    },
    [dispatch, editContactMutation, setContactErrors]
  );

  const deleteContact = useCallback(
    (id: string) => {
      return deleteContactMutation({
        id,
      })
        .unwrap()
        .then(() => {
          dispatch(setContacts({contacts: contacts?.filter(contact => contact.id !== id)}));
        })
        .catch(e => {
          dispatch(setContactsError(e.data.errors));
          throw e;
        });
    },
    [dispatch, deleteContactMutation, contacts]
  );

  return {
    addContact,
    editContact,
    deleteContact,
    isLoading,
  };
};
