import {Document, Page, pdfjs} from 'react-pdf';
import {useState} from 'react';
import Modal from 'react-modal';

import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import styles from './PdfModal.module.scss';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

Modal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  onClose: () => void;
  file: File | undefined;
};

export function PdfModal({isOpen, file, onClose}: Props) {
  const [numPages, setNumPages] = useState<number | null>(null);

  return (
    <Modal
      className={styles.modalContainer}
      overlayClassName={styles.modalOverlay}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <div className={styles.content}>
        <Document
          file={file}
          onLoadSuccess={({numPages: nextNumPages}) => {
            setNumPages(nextNumPages);
          }}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} />
          ))}
        </Document>
        <button type="button" onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </button>
      </div>
    </Modal>
  );
}
