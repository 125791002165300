import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/reset.css';
import './index.scss';
import {Provider as ReduxProvider} from 'react-redux';
import {ConfigProvider} from 'antd';
import App from 'App';
import index from 'store';
import {Translation} from 'features/components/translation/Translation';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxProvider store={index}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Raleway',
          },
        }}>
        <Translation>
          <App />
        </Translation>
      </ConfigProvider>
    </ReduxProvider>
  </React.StrictMode>
);
