import {Button} from 'features/components/button/Button';
import {DocumentAction, ModalType} from 'store/reducers/modal';
import {useAppSelector} from 'store';
import {useTranslation} from 'hooks/translationHooks';
import styles from './CommentModalControls.module.scss';

type Props = {
  onClose: () => void;
  modalType: ModalType | undefined;
  documentAction?: DocumentAction;
};

export function CommentModalControls({modalType, onClose, documentAction}: Props) {
  const {isLoading} = useAppSelector(state => state.document);
  const {userInfo} = useAppSelector(state => state.user);
  const {t} = useTranslation();

  if (modalType !== 'Comment') {
    return null;
  }

  const approveLabel = userInfo ? t('document.page.approve') : t('document.page.sign.to.approve');
  const declineLabel = userInfo ? t('document.page.decline') : t('document.page.sign.to.decline');

  return (
    <div className={styles.container}>
      {documentAction ? (
        <Button
          variant="primary"
          className={styles.addMoreSignersButton}
          type="submit"
          form="commentForm"
          disabled={isLoading}>
          {documentAction === 'Approve' ? approveLabel : declineLabel}
        </Button>
      ) : (
        <Button variant="secondary" className={styles.addMoreSignersButton} onClick={onClose}>
          {t('document.page.back.to.document')}
        </Button>
      )}
    </div>
  );
}
