export enum SortingType {
  CONTAINER_TYPE = 'containerType',
  NAME = 'name',
  DATE_AND_STATUS = 'statusNum,documentDate',
  SIGNATURES = 'signatureCount,nonPendingSignatureCount',
}

export enum SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
