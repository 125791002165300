import {useLayoutEffect, useState} from 'react';

const SCREEN_MEDIA_QUERY = {
  MD: `(max-width: 37.5em)`,
} as const;

export const useScreenSize = (): {isSmall: boolean} => {
  const [isSmall, setIsSmall] = useState(false);

  useLayoutEffect(() => {
    const smallScreen = window.matchMedia(SCREEN_MEDIA_QUERY.MD);

    const updateSize = () => setIsSmall(smallScreen.matches);

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {isSmall};
};
