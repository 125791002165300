import {Contact, ContactRequest, DeleteContactRequest} from 'types/contact';
import {apiSlice} from './apiSlice';

export const APIUserSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getContacts: builder.query<Contact[], void>({
      query: () => `/contacts`,
    }),
    addContact: builder.mutation<Contact, ContactRequest>({
      query: body => {
        return {
          url: '/contacts',
          method: 'POST',
          body,
        };
      },
    }),
    editContact: builder.mutation<Contact, Contact>({
      query: body => {
        return {
          url: `/contacts/${body.id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteContact: builder.mutation<Contact, DeleteContactRequest>({
      query: ({id}) => {
        return {
          url: `/contacts/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetContactsQuery,
  useAddContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
} = APIUserSlice;
