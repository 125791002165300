import {Signature, SignerStatusType} from 'types/document';
import {Tooltip} from 'antd';
import {Text} from 'features/components/text/Text';
import {ReactComponent as UserIcon} from 'assets/icons/UserIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import styles from '../DocumentsList.module.scss';

type SignaturesProps = {
  signatures: Signature[];
};

const NUMBER_OF_SIGNERS = 20;
const {SIGNED} = SignerStatusType;

const getSignersCell = (signatures: Signature[]) => {
  const numberOfSigned = signatures.filter(({status}) => status === SignerStatusType.SIGNED);
  return (
    <div className={styles.signers}>
      <UserIcon />
      <Text variant="xSmallBold">{`${numberOfSigned.length} / ${signatures.length}`}</Text>
    </div>
  );
};

export function Signatures({signatures}: SignaturesProps) {
  const {t} = useTranslation();
  const notSignedSigners = signatures.filter(({status}) => status !== SIGNED);
  const numberOfNotSigned = notSignedSigners?.length;
  const areAllSigned =
    !!signatures.length && signatures.every(s => s.status === SignerStatusType.SIGNED);
  const showTooltip =
    (!!numberOfNotSigned && numberOfNotSigned < NUMBER_OF_SIGNERS) || areAllSigned;

  const getSignersTooltipContent = () => {
    const signaturesToShow = areAllSigned
      ? signatures
      : signatures.filter(signature => signature.status !== SignerStatusType.SIGNED);

    return (
      <div>
        <Text variant="small">
          {areAllSigned
            ? t('settings.page.documents.sorting.signatures.tooltip.all.signed')
            : t('settings.page.documents.sorting.signatures.tooltip.waiting')}
        </Text>
        {signaturesToShow.map(({id, name, representativeName}) => {
          return (
            <Text key={id} variant="smallBold">
              {name || representativeName}
            </Text>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.signersWrapper}>
      {showTooltip ? (
        <Tooltip
          placement="top"
          title={getSignersTooltipContent()}
          arrow
          overlayClassName={styles.tooltipContainer}>
          {getSignersCell(signatures)}
        </Tooltip>
      ) : (
        getSignersCell(signatures)
      )}
    </div>
  );
}
