import {useCallback} from 'react';
import {message} from 'antd';
import {useAppendPreferenceMutation} from 'store/api/userPreferences';
import {PreferenceRequest} from 'types/userPreferences';

const usePreferences = () => {
  const [appendPreferenceMutation] = useAppendPreferenceMutation();
  const appendPreference = useCallback(
    (props: PreferenceRequest) => {
      appendPreferenceMutation(props)
        .unwrap()
        .then(() => {
          message.success('Updated');
        })
        .catch(() => {
          message.error('Failed to update preference');
        });
    },
    [appendPreferenceMutation]
  );

  return {appendPreference};
};

export default usePreferences;
