import {AllowedLanguages} from 'types/translation';
import {getCookie} from 'utils/cookie';
import {defaultSelectedLanguageCode} from '../../header/components/LanguagePicker.utils';

const getPageLanguageOnLanding = (): AllowedLanguages => {
  const {pathname} = window.location;
  const regex = '^(en|ru)$';
  const langSplit = pathname.split('/');
  const pathLanguage = langSplit.filter(r => r)[0];
  const languageRegex = pathLanguage?.match(regex)?.[1] as AllowedLanguages;
  const languageFromCookie = getCookie('language') as AllowedLanguages;
  return languageRegex || languageFromCookie || defaultSelectedLanguageCode;
};

export {getPageLanguageOnLanding};
