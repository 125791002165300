import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {useTranslation} from 'hooks/translationHooks';
import styles from './Privacy.module.scss';

export function Privacy(): JSX.Element {
  const {t} = useTranslation();

  return (
    <div className={styles.privacy}>
      <div className={styles.container}>
        <Spacer height={96} />
        <Text variant="largeHeading" textAlign="center">
          {t('privacy.page.title')}
        </Text>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.principles.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.principles.content.block1')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('privacy.page.principles.content.block2')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.purpose.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.purpose.content')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.dissemination.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.dissemination.content.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('privacy.page.dissemination.content.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.dissemination.content.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.dissemination.content.bullet3')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.dissemination.content.bullet4')}</Text>
            </li>
          </ul>
          <Spacer height={24} />
          <Text variant="medium">{t('privacy.page.dissemination.content.footer')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.storage.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.storage.content')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.rights.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.rights.content.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet3')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet4')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet5')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet6')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet7')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet8')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.rights.content.bullet9')}</Text>
            </li>
          </ul>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('privacy.page.data_collected.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('privacy.page.data_collected.content.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('privacy.page.data_collected.content.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.data_collected.content.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('privacy.page.data_collected.content.bullet3')}</Text>
            </li>
          </ul>
        </div>
        <Spacer height={96} />
      </div>
    </div>
  );
}
