import {createSlice} from '@reduxjs/toolkit';
import {SortingOrder, SortingType} from 'pages/dashboard/sections/documents/util/documentUtil';

export interface DocumentsListState {
  sort: string;
}

const initialState: DocumentsListState = {
  sort: `${SortingType.DATE_AND_STATUS},${SortingOrder.ASC}`,
};

const documentsListReducer = createSlice({
  name: 'documentsList',
  initialState,
  reducers: {
    setSort(state, action) {
      state.sort = action.payload;
    },
    resetDocumentsList() {
      return initialState;
    },
  },
});

export default documentsListReducer.reducer;

export const {setSort, resetDocumentsList} = documentsListReducer.actions;
