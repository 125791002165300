import {Tooltip} from 'antd';
import clsx from 'clsx';
import {ReactComponent as QuestionMarkIcon} from 'assets/icons/QuestionMarkIcon.svg';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Text} from '../text/Text';

import styles from './Switch.module.scss';

type Props = {
  leftText: string;
  rightText: string;
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
  label?: string;
  tooltipContent?: ReactNode;
  className?: string;
};

export function Switch({
  leftText,
  rightText,
  checked,
  onChange,
  disabled,
  label,
  tooltipContent,
  className,
}: Props) {
  const idRef = useRef(`customSwitch_${Math.round(Math.random() * 100)}`);
  const leftLabelRef = useRef<HTMLSpanElement | null>(null);
  const rightLabelRef = useRef<HTMLSpanElement | null>(null);
  const [leftLabelWidth, setLeftLabelWidth] = useState<number>(0);
  const [rightLabelWidth, setRightLabelWidth] = useState<number>(0);
  const [toggleWidth, setToggleWidth] = useState<number | null>(null);

  useEffect(() => {
    if (checked && rightLabelWidth > 0) {
      setToggleWidth(rightLabelWidth);
    } else if (!checked && leftLabelWidth > 0) {
      setToggleWidth(leftLabelWidth);
    }
  }, [checked, leftLabelWidth, rightLabelWidth]);

  useEffect(() => {
    if (rightLabelRef.current) {
      setRightLabelWidth(rightLabelRef.current.offsetWidth);
    }
    if (leftLabelRef.current) {
      setLeftLabelWidth(leftLabelRef.current.offsetWidth);
    }
  }, [leftText, rightText]);

  const handleClick = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className={className}>
      {label ? (
        <div className={styles.textWrapper}>
          <Text variant="smallBold">{label}</Text>
          {tooltipContent ? (
            <Tooltip
              placement="top"
              title={tooltipContent}
              arrow
              overlayClassName={styles.tooltipContainer}>
              <button type="button" className={styles.tooltipButton}>
                <QuestionMarkIcon />
              </button>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      <div className={styles.container}>
        <input
          type="checkbox"
          id={idRef.current}
          className={styles.toggleCheckbox}
          checked={checked}
          onChange={handleClick}
          disabled={disabled}
        />
        <label
          htmlFor={idRef.current}
          className={clsx(
            styles.toggleLabel,
            checked && styles.checked,
            disabled && styles.disabled
          )}>
          <span ref={leftLabelRef} className={clsx(styles.toggleText, styles.left)}>
            {leftText}
          </span>
          <span
            className={styles.toggleButton}
            style={{
              width: toggleWidth ? `calc(${toggleWidth}px + 1px)` : `calc(50% + 1px)`,
              transform: checked ? `translateX(${leftLabelWidth + 1}px)` : 'none',
            }}
          />
          <span ref={rightLabelRef} className={clsx(styles.toggleText, styles.right)}>
            {rightText}
          </span>
        </label>
      </div>
    </div>
  );
}
