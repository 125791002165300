import {Footer} from 'antd/es/layout/layout';
import {Spacer} from 'features/components/Spacer';
import {Text} from 'features/components/text/Text';
import {ReactComponent as FooterLogo} from 'assets/icons/FooterLogoIcon.svg';
import {Link} from 'features/components/link/Link';
import {useTranslation} from 'hooks/translationHooks';
import styles from './MainFooter.module.scss';

export function MainFooter(): JSX.Element {
  const {t} = useTranslation();

  return (
    <Footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <Text variant="small" color="lightBlue">
            {t('footer.brand.supported')}
          </Text>
          <Spacer height={8} />
          <FooterLogo />
          <Spacer height={8} />
          <Text variant="small" color="lightBlue">
            {t('footer.brand.copyright')}
          </Text>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.block}>
            <Text variant="large" color="lightBlue">
              {t('footer.assistance.title')}
            </Text>
            <Spacer height={4} />
            <Text variant="small" color="lightBlue">
              {t('footer.reach.title', {
                tel: (
                  <a className={styles.linkBlue} href={`tel:${t('footer.reach.tel')}`}>
                    {t('footer.reach.tel')}
                  </a>
                ),
                email: (
                  <a
                    className={styles.linkBlueUnderline}
                    href={`mailto:${t('footer.reach.email')}`}>
                    {t('footer.reach.email')}
                  </a>
                ),
              })}
            </Text>
          </div>
          <div className={styles.linksBlock}>
            <div className={styles.block}>
              <Link className={styles.link} to="/contact">
                {t('footer.link.contact.title')}
              </Link>
              <Link className={styles.link} to="/features">
                {t('footer.link.features.title')}
              </Link>
            </div>
            <div className={styles.block}>
              <Link className={styles.link} to="/cookies">
                {t('footer.link.cookies.title')}
              </Link>
              <Link className={styles.link} to="/privacy">
                {t('footer.link.privacy.title')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  );
}
