import {Route} from 'react-router-dom';
import {Privacy} from 'pages/privacy/Privacy';
import {Contact} from 'pages/contact/Contact';
import {Cookies} from 'pages/cookies/Cookies';
import {Signing} from 'pages/signing/Signing';
import {Contacts, Document, Documents, NewDocument, Preferences} from 'pages/dashboard/sections';
import {DocumentFilter} from 'types/document';
import {Features} from 'pages/features/Features';

const {CREATED_BY_ME, SHARED_TO_ME, MY_PENDING} = DocumentFilter;

export type RouteProps = {path: string; element: JSX.Element}[];

const mainLayoutRoutes: RouteProps = [
  {path: '/privacy', element: <Privacy />},
  {path: '/contact', element: <Contact />},
  {path: '/features', element: <Features />},
  {path: '/cookies', element: <Cookies />},
];

const dashboardRoutes: RouteProps = [
  {path: '/new', element: <NewDocument />},
  {path: '/document/:id', element: <Document />},
  {path: '/contacts', element: <Contacts />},
  {
    path: '/documents/me',
    element: <Documents filter={CREATED_BY_ME} title="settings.page.side.menu.created.by.me" />,
  },
  {
    path: '/documents/shared',
    element: <Documents filter={SHARED_TO_ME} title="settings.page.side.menu.shared.to.me" />,
  },
  {
    path: '/documents/pending',
    element: <Documents filter={MY_PENDING} title="settings.page.side.menu.waiting.for.signing" />,
  },
  {path: '/preferences', element: <Preferences />},
];

const mainRoutes: RouteProps = [{path: '/signing', element: <Signing />}];

export const pathTo = (pathUrl: string) => {
  return `/:lang?${pathUrl}`;
};

const getRoutes = (routesTo: RouteProps) => {
  return routesTo.map(({path, element}) => (
    <Route path={pathTo(path)} key={path} element={element} />
  ));
};

export {mainLayoutRoutes, mainRoutes, getRoutes, dashboardRoutes};
