import {PreferenceRequest, PreferenceSettings} from 'types/userPreferences';
import {apiSlice} from './apiSlice';

export const APITranslationSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUserPreferences: builder.query<PreferenceSettings[], void>({
      query: () => {
        return {
          url: `/user/settings`,
          method: 'GET',
        };
      },
      providesTags: ['UserPreferences'],
    }),
    appendPreference: builder.mutation<PreferenceSettings, PreferenceRequest>({
      query: body => {
        return {
          url: `/user/settings`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['UserPreferences'],
    }),
  }),
});

export const {useGetUserPreferencesQuery, useAppendPreferenceMutation} = APITranslationSlice;
