import {ReactComponent as CheckIcon} from 'assets/icons/CheckIcon.svg';
import {ReactComponent as LoadingIcon} from 'assets/icons/LoadingIcon.svg';
import {ReactComponent as ChatIcon} from 'assets/icons/ChatIcon.svg';
import clsx from 'clsx';
import {useScreenSize} from 'hooks/useScreenSize';
import {Text} from 'features/components/text/Text';
import {PersonalType, Signature, SignatureType, Signer, SignerStatusType} from 'types/document';
import {useAppDispatch, useAppSelector} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {LegalType} from 'types/contact';
import {useDocument} from 'hooks/documentsHooks';
import {useSignature} from 'hooks/signatureHooks';
import {UnderlineButton} from '../button/UnderlineButton';

import styles from './SignaturesTable.module.scss';
import {RoleSelect} from '../input/RoleSelect';
import {SignatureStatus} from '../signature-status/SignatureStatus';

export enum SignatureCellType {
  signatureStatus = 'Signer status',
  signer = 'Signer',
  fin = 'FIN / VÖEN',
  role = 'Role',
  role_and_date = 'Date / Role',
}

type Props = {
  signatures: Signature[];
  className?: string;
  cells?: SignatureCellType[];
  onRemove?: (id: string) => void;
  isCreator: boolean;
};

const defaultCells = [
  SignatureCellType.signatureStatus,
  SignatureCellType.signer,
  SignatureCellType.fin,
  SignatureCellType.role_and_date,
];

const useRoleOptions = () => {
  const {t} = useTranslation();

  return {
    roleOptions: [
      {
        value: SignatureType.SIGNATURE,
        label: t('signatures.role.sign'),
      },
      {value: SignatureType.APPROVAL, label: t('signatures.role.approval')},
    ],
  };
};

export function SignaturesTable({
  signatures,
  className,
  cells = defaultCells,
  onRemove,
  isCreator,
}: Props) {
  const {t} = useTranslation();
  const {isSmall} = useScreenSize();
  const dispatch = useAppDispatch();
  const {isMySignature} = useSignature();
  const {document} = useAppSelector(state => state.document);
  const {updateSigner} = useDocument();

  const {roleOptions} = useRoleOptions();

  const hasRoleAndDateCell = cells.includes(SignatureCellType.role_and_date);
  const hasRoleCell = cells.includes(SignatureCellType.role);
  const hasRemoveCell = !!onRemove && signatures.some(s => s.status === SignerStatusType.PENDING);

  const getSignerFromSignature = (
    signature: Signature,
    selectedSignatureType: SignatureType
  ): Signer => {
    const {
      personType,
      code,
      country,
      name,
      representativeCode,
      representativeCountry,
      representativeName,
      email,
    } = signature || {};

    const legalType = personType === PersonalType.PRIVATE ? LegalType.Private : LegalType.Business;

    return {
      personType: legalType,
      code,
      country,
      name,
      representativeCode,
      representativeCountry,
      representativeName,
      type: selectedSignatureType,
      email,
    };
  };

  const handleTypeChange = (id: number, selectedSignatureType: SignatureType) => {
    const currentSignature = document?.signatures.filter(signature => signature.id === id);
    if (currentSignature) {
      if (document?.id) {
        const signature = currentSignature[0];
        const signer = getSignerFromSignature(signature, selectedSignatureType);
        updateSigner(document?.id, id, signer);
      }
    }
  };

  const getRoleSelectCell = (signature: Signature) => {
    const activeRole = roleOptions.find(option => option.value === signature.type);

    return (
      <td className={styles.roleSelectWrapperAlignRight}>
        <RoleSelect
          isAllowedToChange={isCreator && signature.status !== SignerStatusType.SIGNED}
          options={roleOptions}
          value={activeRole ? t(activeRole.label) : ''}
          onChange={(signatureType: SignatureType) => handleTypeChange(signature.id, signatureType)}
          className={styles.roleSelect}
          position="right"
        />
      </td>
    );
  };

  const getStatusCell = (signature: Signature) => {
    if (
      signature.status === SignerStatusType.DECLINED ||
      (signature.status === SignerStatusType.SIGNED && signature.type === SignatureType.APPROVAL)
    ) {
      return (
        <td>
          <div className={styles.approvalStatusCell}>
            {signature.comment ? (
              <UnderlineButton
                leftIcon={<ChatIcon />}
                className={styles.readCommentButton}
                onClick={() =>
                  dispatch(
                    setIsModalOpen({isOpen: true, type: 'Comment', id: signature.id.toString()})
                  )
                }>
                {t('document.page.read.comment')}
              </UnderlineButton>
            ) : null}
            <SignatureStatus signature={signature} />
          </div>
        </td>
      );
    }

    if (signature.status === SignerStatusType.PENDING && !signature.notificationLastSentAt) {
      return getRoleSelectCell(signature);
    }

    return (
      <td>
        <SignatureStatus signature={signature} />
      </td>
    );
  };

  const getSignatureStatus = (signature: Signature) => {
    if (!cells.includes(SignatureCellType.signatureStatus)) {
      return null;
    }

    if (
      !isMySignature(signature) &&
      !signature.notificationLastSentAt &&
      signature.status === SignerStatusType.PENDING
    ) {
      return null;
    }

    return signature.status === SignerStatusType.PENDING ? <LoadingIcon /> : <CheckIcon />;
  };

  const getSignatureName = (signature: Signature) => {
    if (signature.certificateInfo) {
      return (
        <Text variant="mediumBold">{`${signature.certificateInfo.givenName} ${signature.certificateInfo.surName}`}</Text>
      );
    }

    return <Text variant="mediumBold">{signature.name}</Text>;
  };

  const getCompanyInfo = (signature: Signature) => {
    if (
      signature.personType !== PersonalType.PRIVATE &&
      signature.certificateInfo?.organizationName &&
      signature.certificateInfo?.role
    ) {
      return (
        <Text variant="medium">{`${signature.certificateInfo.organizationName}, ${signature.certificateInfo.role}`}</Text>
      );
    }

    return null;
  };

  return (
    <table className={clsx(styles.container, className)}>
      <tr
        className={clsx(
          hasRemoveCell && hasRoleAndDateCell ? styles.withRemoveCell : styles.withoutRemoveCell
        )}>
        <th>
          <Text variant="xSmall">
            {isSmall ? t('contacts.page.signer.pin') : t('settings.page.contacts.signer')}
          </Text>
        </th>
        {!isSmall ? (
          <th>
            <Text variant="xSmall">{t('settings.page.contacts.fin')}</Text>
          </th>
        ) : null}
        {hasRoleCell ? (
          <th>
            <Text variant="xSmall" textAlign={hasRemoveCell ? 'right' : 'left'}>
              {t('signatures.role')}
            </Text>
          </th>
        ) : null}
        {hasRoleAndDateCell ? (
          <th>
            <Text variant="xSmall">{t('document.page.table.date_and_role')}</Text>
          </th>
        ) : null}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {hasRemoveCell ? <th /> : null}
      </tr>
      {signatures.map(signature => {
        return (
          <tr
            key={signature.id}
            className={clsx(
              styles.dataRow,
              hasRemoveCell && hasRoleAndDateCell ? styles.withRemoveCell : styles.withoutRemoveCell
            )}>
            <td>
              <div className={styles.nameField}>
                {getSignatureStatus(signature)}
                <div>
                  {getSignatureName(signature)}
                  {getCompanyInfo(signature)}
                  <Text variant="medium">{isSmall ? signature.code : ''}</Text>
                </div>
              </div>
            </td>
            {!isSmall ? (
              <td>
                <Text variant="medium">{signature.code}</Text>
              </td>
            ) : null}
            {hasRoleCell ? getRoleSelectCell(signature) : null}
            {hasRoleAndDateCell ? getStatusCell(signature) : null}
            {/* eslint-disable-next-line no-nested-ternary */}
            {hasRemoveCell ? (
              signature.status === SignerStatusType.PENDING && document?.creator ? (
                <td className={styles.alignCenter}>
                  <button
                    type="button"
                    className={styles.closeButton}
                    onClick={() => {
                      dispatch(
                        setIsModalOpen({
                          isOpen: true,
                          type: 'Confirmation',
                          confirmationText: t('settings.page.documents.are.you.sure'),
                          onSubmit: () => onRemove(signature.id.toString()),
                        })
                      );
                    }}>
                    <CloseIcon />
                  </button>
                </td>
              ) : (
                <td />
              )
            ) : null}
          </tr>
        );
      })}
    </table>
  );
}
