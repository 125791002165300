import React from 'react';
import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {FileUpload} from 'features/components/upload/FileUpload';
import {useTranslation} from 'hooks/translationHooks';
import styles from './Hero.module.scss';

export function Hero(): JSX.Element {
  const {t} = useTranslation();

  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <h1>{t('home_header.title')}</h1>
        <Spacer height={16} />
        <Text textAlign="center" variant="large">
          {t('home_header.description.one')}
        </Text>
        <Spacer height={18} />
        <Text textAlign="center">{t('home_header.description.two')}</Text>
        <Spacer height={32} />
        <FileUpload />
      </div>
    </div>
  );
}
