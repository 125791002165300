import {Contact} from 'types/contact';
import clsx from 'clsx';
import {useTranslation} from 'hooks/translationHooks';
import styles from './DropdownInput.module.scss';
import {Text} from '../text/Text';

type Props = {
  onInputChange: (value: string) => void;
  onOptionClick: (option: Contact) => void;
  value: string;
  className?: string;
  label?: string;
  optional?: boolean;
  required?: boolean;
  options: Contact[];
  errorMsg?: string;
  showErrorMsg?: boolean;
};

export function DropdownInput({
  className,
  label,
  optional,
  onInputChange,
  onOptionClick,
  value,
  required,
  options,
  errorMsg,
  showErrorMsg = true,
}: Props) {
  const {t} = useTranslation();

  return (
    <div className={className}>
      {label ? (
        <Text variant="smallBold" className={styles.label}>
          {label}
          {optional ? (
            <span className={styles.optionalLabel}> ({t('general.fields.optional')})</span>
          ) : null}
        </Text>
      ) : null}
      <div className={styles.dropdown}>
        <input
          type="text"
          value={value}
          className={clsx(styles.input, errorMsg && styles.inputError)}
          onChange={e => onInputChange(e.target.value)}
          required={required}
        />
        <div className={styles.dropdownContent}>
          {options.map(option => (
            <button
              type="button"
              key={option.id}
              className={styles.option}
              onClick={() => onOptionClick(option)}>
              <Text variant="mediumBold">{option.name}</Text>
              <Text variant="small" className="pl-5">
                Add to signers
              </Text>
            </button>
          ))}
        </div>
      </div>
      {showErrorMsg && errorMsg && (
        <div className={styles.errorMessage}>
          <Text variant="small" color="red">
            {errorMsg}
          </Text>
        </div>
      )}
    </div>
  );
}
