import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ErrorDto, StoreState} from 'types/shared';
import {AuthenticationResponse} from 'types/auth';
import {MAX_SECONDS} from 'features/components/modal/verification/Verification';

export interface AuthStoreState extends StoreState {
  isAuthenticated: boolean;
  authenticationTimeRemaining: number;
  authResponse: AuthenticationResponse | null;
}

const initialState: AuthStoreState = {
  isAuthenticated: false,
  authenticationTimeRemaining: MAX_SECONDS,
  authResponse: null,
  isLoading: false,
  errors: [],
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startAuthentication(state) {
      state.isLoading = true;
      state.errors = [];
      state.authenticationTimeRemaining = MAX_SECONDS;
    },
    decreaseTimeRemaining(state) {
      if (state.authenticationTimeRemaining !== 0) {
        state.authenticationTimeRemaining -= 1;
      }
    },
    authenticationSuccessful(state, action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.authResponse = action.payload.authResponse;
    },
    setAuthResponse(state, action) {
      state.authResponse = action.payload.authResponse;
    },
    setAuthError(state, action: PayloadAction<ErrorDto[]>) {
      state.isLoading = false;
      state.errors = action.payload;
    },
    resetAuth() {
      return initialState;
    },
  },
});

export default authReducer.reducer;

export const {
  startAuthentication,
  authenticationSuccessful,
  decreaseTimeRemaining,
  setAuthResponse,
  setAuthError,
  resetAuth,
} = authReducer.actions;
