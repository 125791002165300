import {CSSProperties, ReactNode} from 'react';
import {Link as ReactLink} from 'react-router-dom';
import {useAppSelector} from 'store';
import clsx from 'clsx';
import {handleLink} from 'features/header/components/LanguagePicker.utils';
import styles from './Link.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  color?: 'pureBlue';
  href?: string;
  underline?: boolean;
  to?: string;
  rel?: string;
  target?: string;
  onClick?: () => void;
}

export function Link(props: Props): JSX.Element {
  const {activeLanguage} = useAppSelector(state => state.translation);
  const {
    children,
    color,
    className = '',
    href,
    underline = false,
    to,
    rel,
    target,
    onClick,
  } = props;

  const localStyle: CSSProperties = {
    textDecoration: underline ? 'underline' : 'none',
  };

  const classNames = clsx(color ? styles[color] : '', className);

  if (to) {
    const linkTo = handleLink(to, activeLanguage);
    return (
      <ReactLink className={classNames} style={localStyle} to={linkTo} rel={rel} onClick={onClick}>
        {children}
      </ReactLink>
    );
  }

  return (
    <a className={classNames} style={localStyle} href={href} target={target} onClick={onClick}>
      {children}
    </a>
  );
}
