import {Text} from 'features/components/text/Text';
import {Switch} from 'features/components/switch/Switch';
import {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {Input} from 'features/components/input/Input';
import {useAppDispatch, useAppSelector} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import {Contact, LegalType} from 'types/contact';

import {DropdownInput} from 'features/components/input/DropdownInput';
import {Notification} from 'features/components/notification/Notification';

import {useLazyGetContactsQuery} from 'store/api/contact';
import {useDocument} from 'hooks/documentsHooks';
import {SignatureType, Signer} from 'types/document';
import {SignerRequest} from 'types/signer';
import {useTranslation} from 'hooks/translationHooks';
import {Checkbox} from 'features/components/input/Checkbox';
import {useContact} from 'hooks/contactHooks';
import styles from './SignerModal.module.scss';

const countryOptions = [
  {value: 'AZ', label: 'Azerbaijan'},
  {value: 'RU', label: 'Russian'},
  {value: 'EN', label: 'English'},
];

const initialData: SignerRequest = {
  personType: LegalType.Private,
  name: '',
  code: '',
  email: '',
  country: countryOptions[0].value,
  type: SignatureType.SIGNATURE,
};

type Props = {
  documentId?: string;
  onLoaded: (val: boolean) => void;
};

export function SignerModal({documentId, onLoaded}: Props) {
  const [fieldsData, setFieldsData] = useState<SignerRequest>(initialData);
  const [fetchContacts, {data: contactsData}] = useLazyGetContactsQuery();
  const {appendSigner} = useDocument();
  const {addContact} = useContact();
  const {errors} = useAppSelector(state => state.document);
  const fieldErrors = errors.filter(e => e.field || e.code === 1025);
  const [contactOptions, setContactOptions] = useState<Contact[]>([]);
  const [saveToContactsValue, setSaveToContactsValue] = useState<boolean>(false);
  const [isExistingContact, setIsExistingContact] = useState<boolean>(false);

  useEffect(() => {
    if (contactsData) {
      setContactOptions(contactsData);
      onLoaded(true);
    }
  }, [contactsData, onLoaded]);

  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const handleLegalTypeSwitch = () => {
    setFieldsData(prev => ({
      ...initialData,
      personType: prev.personType === LegalType.Private ? LegalType.Business : LegalType.Private,
    }));
  };

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const handleFinChange = (field: keyof SignerRequest, value: string) => {
    setFieldsData(prev => ({
      ...prev,
      [field]: value.trim().toUpperCase(),
    }));
    setIsExistingContact(false);
  };

  const updateFieldData = (field: keyof SignerRequest, value: string) => {
    setFieldsData(prev => ({
      ...prev,
      [field]: /^\s*$/.test(value) ? value.trim() : value,
    }));
    setIsExistingContact(false);
  };

  const handleCertificateTypeSwitch = () => {
    setFieldsData(prev => ({
      ...prev,
      personType:
        prev.personType === LegalType.Government ? LegalType.Business : LegalType.Government,
    }));
  };

  const getErrorMsg = useCallback(
    (fieldName: string) => fieldErrors.find(e => e.field === fieldName)?.message,
    [fieldErrors]
  );

  const legalTypeTooltipContent = (
    <div>
      <Text variant="medium">{t('signers.modal.certificate.type.tooltip')}</Text>
    </div>
  );

  const certificateTypeTooltipContent = (
    <div>
      <Text variant="medium">
        {fieldsData.personType === LegalType.Government
          ? t('signers.modal.government.type.tooltip')
          : t('signers.modal.legal.type.tooltip')}
      </Text>
    </div>
  );

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    const nonEmptyValues = Object.fromEntries(
      Object.entries(fieldsData)
        .filter(([, value]) => value !== '')
        .map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
    );

    try {
      if (documentId) {
        const signerData = nonEmptyValues as Signer;
        appendSigner(documentId, signerData).then(() => {
          if (!isExistingContact && saveToContactsValue) {
            addContact({
              name: signerData.name,
              email: signerData.email,
              code: signerData.code,
              country: signerData.country,
              type: signerData.personType,
              representativeCode: signerData.representativeCode,
              representativeName: signerData.representativeName,
              representativeCountry: signerData.representativeCountry,
            });
          }
          dispatch(setIsModalOpen({isOpen: false, type: 'Signer'}));
        });
      }
    } catch (err) {
      /* empty */
    }
  };

  const isBusinessType =
    fieldsData.personType === LegalType.Business || fieldsData.personType === LegalType.Government;

  if (!contactsData) return null;

  const handleContactInputChange = (value: string) => {
    setIsExistingContact(false);
    setContactOptions(
      contactsData.filter(({name}) => name.toLowerCase().includes(value.toLowerCase()))
    );
    updateFieldData('name', value);
  };

  const handleOptionClick = (option: Contact) => {
    updateFieldData('name', option.name);
    const {type, ...restContactFields} = option;

    setFieldsData(prev => ({
      ...prev,
      ...restContactFields,
      personType: type,
    }));
    setIsExistingContact(true);
  };

  const handleSaveToContacts = () => setSaveToContactsValue(prev => !prev);

  const getBusinessTitle = () =>
    fieldsData.personType === LegalType.Business
      ? t('signers.modal.name.of.company')
      : t('signers.modal.name.of.government');

  return (
    <div className={styles.container}>
      <Text variant="largeBold" textAlign="center">
        {t('signers.modal.add.signer')}
      </Text>
      {fieldErrors.length > 0 && (
        <div className={styles.errorMessage}>
          <Notification variant="error" fullWidth>
            <Text variant="large">{fieldErrors[0].message}</Text>
          </Notification>
        </div>
      )}
      <form className={styles.content} id="contactForm" onSubmit={handleSubmit}>
        <Switch
          leftText={t('signers.modal.private')}
          rightText={t('signers.modal.business')}
          checked={isBusinessType}
          onChange={handleLegalTypeSwitch}
          label={t('signers.modal.legal.type')}
          tooltipContent={legalTypeTooltipContent}
        />
        {isBusinessType ? (
          <Switch
            className={styles.certificateTypeSwitchWrapper}
            leftText={t('signers.modal.switch.business')}
            rightText={t('signers.modal.switch.government')}
            checked={fieldsData.personType === LegalType.Government}
            onChange={handleCertificateTypeSwitch}
            label={t('signers.modal.certificate.type')}
            tooltipContent={certificateTypeTooltipContent}
          />
        ) : null}
        <DropdownInput
          label={isBusinessType ? getBusinessTitle() : t('signers.modal.name.of.signer')}
          className={styles.dropdownInput}
          onOptionClick={handleOptionClick}
          value={fieldsData.name}
          onInputChange={handleContactInputChange}
          options={contactOptions}
          errorMsg={getErrorMsg('name')}
          showErrorMsg={false}
        />
        {/*
        <SelectInput
          className={styles.input}
          label={t('signers.modal.country')}
          options={countryOptions}
          defaultValue={countryOptions[0].value}
          onChange={value => updateFieldData('country', value)}
        />
        */}
        {isBusinessType ? (
          <Input
            className={styles.input}
            label="VÖEN"
            value={fieldsData.code || ''}
            onChange={value => handleFinChange('code', value)}
            errorMsg={getErrorMsg('code')}
            showErrorMsg={false}
          />
        ) : (
          <Input
            className={styles.input}
            label="FIN"
            value={fieldsData.code || ''}
            onChange={value => handleFinChange('code', value)}
            errorMsg={getErrorMsg('code')}
            showErrorMsg={false}
          />
        )}
        <Input
          className={styles.input}
          label={t('signers.modal.email')}
          value={fieldsData.email || ''}
          onChange={value => updateFieldData('email', value)}
          errorMsg={getErrorMsg('email')}
          showErrorMsg={false}
        />
        {isBusinessType ? (
          <>
            <Input
              className={styles.input}
              label={t('signers.modal.name.of.representative')}
              value={fieldsData.representativeName || ''}
              onChange={value => updateFieldData('representativeName', value)}
              optional
              errorMsg={getErrorMsg('representativeName')}
              showErrorMsg={false}
            />
            <Input
              className={styles.input}
              label={t('signers.modal.pin.of.representative')}
              value={fieldsData.representativeCode || ''}
              onChange={value => handleFinChange('representativeCode', value)}
              optional
              errorMsg={getErrorMsg('representativeCode')}
              showErrorMsg={false}
            />
          </>
        ) : null}
        {!isExistingContact && (
          <div className={styles.checkboxContainer} onClick={handleSaveToContacts}>
            <Checkbox className={styles.checkbox} checked={saveToContactsValue} variant="light" />
            <Text variant="medium">{t('signers.modal.add.to.contacts')}</Text>
          </div>
        )}
      </form>
    </div>
  );
}
