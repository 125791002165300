import {Signature, SignerStatusType} from 'types/document';
import {Text} from 'features/components/text/Text';
import {Tooltip} from 'antd';
import {useTranslation} from 'hooks/translationHooks';
import {useAppSelector} from 'store';
import {SignatureStatus} from 'features/components/signature-status/SignatureStatus';
import {useSignature} from 'hooks/signatureHooks';
import styles from '../DocumentsList.module.scss';
import {getMySignatureCreatedAt} from './DateAndStatus.utils';

type DateAndStatusProps = {
  signatures: Signature[];
};

const getStatusTooltipContent = (title: JSX.Element) => (
  <div>
    <Text variant="small">{title}</Text>
  </div>
);

export function DateAndStatus({signatures}: DateAndStatusProps) {
  const {t} = useTranslation();
  const {userInfo} = useAppSelector(state => state.user);
  const {findMySignature} = useSignature();
  const mySignature = findMySignature(signatures);

  const days = userInfo ? getMySignatureCreatedAt(signatures, userInfo) : null;

  if (!mySignature) {
    return null;
  }

  if (mySignature.status === SignerStatusType.PENDING) {
    return (
      <Tooltip
        placement="top"
        title={() =>
          getStatusTooltipContent(
            t('settings.page.documents.sent.for.signing', {
              days: <span>{days}</span>,
            })
          )
        }
        arrow
        overlayClassName={styles.tooltipContainer}>
        <SignatureStatus signature={mySignature} />
      </Tooltip>
    );
  }

  return <SignatureStatus signature={mySignature} />;
}
