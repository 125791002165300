import {Text} from 'features/components/text/Text';

import {Input} from 'features/components/input/Input';
import {SyntheticEvent, useState} from 'react';
import {DocumentAction, setIsModalOpen} from 'store/reducers/modal';
import {useDocument} from 'hooks/documentsHooks';
import {useAppDispatch, useAppSelector} from 'store';
import {useTranslation} from 'hooks/translationHooks';
import {SignerStatusType} from 'types/document';
import styles from './CommentModal.module.scss';

type Props = {
  signatureId?: string;
  documentAction?: DocumentAction;
};

export function CommentModal({signatureId, documentAction}: Props) {
  const {approveDocument, declineDocument} = useDocument();
  const {document} = useAppSelector(state => state.document);
  const {t} = useTranslation();

  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const action = documentAction === 'Approve' ? approveDocument : declineDocument;

    if (document && documentAction) {
      action(document.id, inputValue).then(() =>
        dispatch(setIsModalOpen({isOpen: false, type: 'Comment'}))
      );
    }
  };

  if (documentAction) {
    return (
      <div className={styles.container}>
        <Text variant="largeBold" className={styles.text}>
          {documentAction === 'Approve'
            ? t('document.page.approve.document')
            : t('document.page.decline.document')}
        </Text>
        <form id="commentForm" onSubmit={handleSubmit}>
          <Text variant="smallBold" className={styles.formLabel}>
            {t('document.page.your.comments')}{' '}
            <span className={styles.regularText}>({t('document.page.optional')})</span>
          </Text>
          <Input value={inputValue} onChange={value => setInputValue(value)} type="textarea" />
        </form>
      </div>
    );
  }

  const signature = document?.signatures.find(x => x.id.toString() === signatureId);

  if (signature) {
    const commentLabel =
      signature.status === SignerStatusType.DECLINED
        ? 'document.page.comments.for.declining'
        : 'document.page.comments.for.approving';
    return (
      <div className={styles.container}>
        <Text variant="largeBold" className={styles.text}>
          {signature.name} {t(commentLabel)}
        </Text>
        <Text variant="medium" className={styles.text}>
          {signature.comment}
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Text variant="largeBold" className={styles.text}>
        {t('document.page.not.found')}
      </Text>
    </div>
  );
}
