import clsx from 'clsx';

import styles from './Checkbox.module.scss';

type Props = {
  checked: boolean;
  onClick?: () => void;
  className?: string;
  variant?: 'light' | 'dark';
};

export function Checkbox({checked, onClick, className, variant = 'dark'}: Props) {
  return (
    <div className={clsx(styles.container, styles[variant], className)} onClick={onClick}>
      <input type="checkbox" checked={checked} readOnly />
      <span className={styles.checkmark} />
    </div>
  );
}
