import {useCallback, useEffect, useMemo, useState} from 'react';
import {Form, Image, Input, message} from 'antd';
import {useAppDispatch, useAppSelector} from 'store';
import {useLogin} from 'hooks/loginHooks';
import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {Verification} from 'features/components/modal/verification/Verification';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import AsanImzaLogo from 'assets/images/AsanImza.png';
import clsx from 'clsx';
import {useTranslation} from 'hooks/translationHooks';
import {Notification} from 'features/components/notification/Notification';
import {resetAuth} from 'store/reducers/auth';
import {PhoneNumberInput} from 'features/components/modal/login/PhoneNumberInput';
import styles from './LoginForm.module.scss';

export function LoginForm(): JSX.Element {
  const {t} = useTranslation();
  const {login, cancel} = useLogin();
  const {
    isLoading: isAuthenticating,
    authenticationTimeRemaining,
    authResponse,
    errors,
  } = useAppSelector(state => state.auth);
  const [form] = Form.useForm();
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const userIdWatch = Form.useWatch(['userId'], form) as string | undefined;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const onLogin = useCallback(() => {
    try {
      const userId = form.getFieldValue('userId');
      login(userId, phoneNumberValue);
    } catch (e) {
      message.error('Failed to start log in');
    }
  }, [form, login, phoneNumberValue]);

  const handleClickCancel = useCallback(() => {
    cancel();
    form.resetFields();
  }, [cancel, form]);

  useEffect(() => {
    if (authenticationTimeRemaining === 0) {
      handleClickCancel();
    }
  }, [authenticationTimeRemaining, handleClickCancel]);

  const getError = useCallback(
    (fieldName: string) => errors.find(e => e.field === fieldName),
    [errors]
  );

  const phoneNumberError = useMemo(() => getError('phoneNumber'), [getError]);
  const userIdError = useMemo(() => getError('userId'), [getError]);

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <div>
          <Text variant="largeBold" textAlign="center">
            {t('log.in.modal.title')}
          </Text>
        </div>
        {(!isAuthenticating || !authResponse) && (
          <>
            <Spacer height={16} />
            <Image width={100} height={31} preview={false} src={AsanImzaLogo} />
          </>
        )}
      </div>
      {errors.length > 0 && (
        <div className={styles.errorMessage}>
          <Notification variant="error" fullWidth>
            <Text variant="large">{errors[0].message}</Text>
          </Notification>
        </div>
      )}
      {isAuthenticating && !!authResponse ? (
        <Verification
          verificationCode={authResponse.verificationCode}
          timeRemaining={authenticationTimeRemaining}
          description={t('verification.modal.info')}
          onCancel={handleClickCancel}
          errors={errors}
          verificationCodeLabel={t('verification.modal.code')}
          onCancelLabel={t('confirmation.cancel.text')}
        />
      ) : (
        <Form form={form}>
          <Form.Item
            name="phoneNumber"
            className={styles.formItem}
            validateStatus={phoneNumberError ? 'error' : ''}
            trigger="onChange">
            <div className={clsx(!!phoneNumberValue && styles.active, styles.inputContainer)}>
              <PhoneNumberInput
                formInstance={form}
                value={phoneNumberValue}
                setValue={setPhoneNumberValue}
                className={clsx(styles.input, phoneNumberError && styles.hasError)}
              />
              <span className={styles.label}>{t('log.in.modal.mobile.number')}</span>
              <span className={styles.prefix}>+994</span>
            </div>
          </Form.Item>
          <Spacer height={16} />
          <Form.Item
            name="userId"
            className={styles.formItem}
            validateStatus={userIdError ? 'error' : ''}
            rules={[{max: 6, message: t('log.in.modal.user.id.error')}]}>
            <div className={clsx(!!userIdWatch && styles.active, styles.inputContainer)}>
              <Input
                className={clsx(styles.numberInput, userIdError && styles.hasError)}
                type="number"
              />
              <span className={styles.label}>{t('log.in.modal.user.id')}</span>
            </div>
          </Form.Item>
          <Spacer height={16} />
          <Form.Item wrapperCol={{offset: 8, span: 16}} noStyle>
            <Button onClick={onLogin} rightIcon={<ArrowRightIcon />}>
              {t('log.in.modal.button')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
