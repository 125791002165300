import {AnyAction, combineReducers} from '@reduxjs/toolkit';
import modal from 'store/reducers/modal';
import user, {initialUserState} from 'store/reducers/user';
import auth from 'store/reducers/auth';
import document from 'store/reducers/document';
import contact from 'store/reducers/contact';
import translation from 'store/reducers/translation';
import {apiSlice} from 'store/api/apiSlice';
import signature from 'store/reducers/signature';
import settings from 'store/reducers/settings';
import event from 'store/reducers/event';
import documentsList from 'store/reducers/documentsList';

const reducers = combineReducers({
  settings,
  modal,
  user,
  auth,
  document,
  documentsList,
  translation,
  signature,
  contact,
  event,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export type RootState = ReturnType<typeof reducers>;

export const resetStore = () => ({
  type: 'RESET_STORE',
});

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'user/logout') {
    return reducers(
      {
        translation: state.translation,
        user: {...initialUserState, isInitialLoadingDone: true},
      } as RootState,
      action
    );
  }

  if (action.type === 'RESET_STORE') {
    if (state.modal.isOpen && state.modal.type === 'Login') {
      return reducers(
        {
          translation: state.translation,
          modal: state.modal,
          user: {...initialUserState, isInitialLoadingDone: true},
        } as RootState,
        action
      );
    }
    return reducers(
      {
        translation: state.translation,
        user: {...initialUserState, isInitialLoadingDone: true},
      } as RootState,
      action
    );
  }

  return reducers(state, action);
};

export default rootReducer;
