import {useParams} from 'react-router-dom';
import {useTranslation} from 'hooks/translationHooks';
import {useDocument} from 'hooks/documentsHooks';
import {AllowedLanguages} from 'types/translation';
import {handleLink} from 'features/header/components/LanguagePicker.utils';
import {useNavigation} from 'hooks/navigateHooks';
import styles from './FileUpload.module.scss';
import {FileInputDrop} from '../file-input-drop/FileInputDrop';

export function FileUpload(): JSX.Element {
  const {navigate} = useNavigation();
  const {lang} = useParams();
  const {t} = useTranslation();
  const {createDocument} = useDocument();

  const handleFilesChange = (files: File[]) => {
    createDocument(files);
    const language = lang as AllowedLanguages;
    const navigateTo = handleLink('/signing', language);
    navigate(navigateTo);
  };

  return (
    <FileInputDrop
      className={styles.fileInputDrop}
      title={t('home_header.add_files.title')}
      onChange={handleFilesChange}
    />
  );
}
