import {ReactNode} from 'react';
import clsx from 'clsx';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';

import {useTranslation} from 'hooks/translationHooks';
import {useAppDispatch} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import styles from './InfoBox.module.scss';
import {Text} from '../text/Text';

type Props = {
  className?: string;
  icon: ReactNode;
  text: string;
  downloadLink: string;
  onCloseIconClick?: () => void;
  isRemovable?: boolean;
};

export function InfoBox({
  className,
  icon,
  text,
  downloadLink,
  onCloseIconClick,
  isRemovable = true,
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const handleRemoveClick = () => {
    if (onCloseIconClick) {
      onCloseIconClick();
    }
  };

  return (
    <div className={clsx(styles.container, className)}>
      <a href={downloadLink} download className={styles.content}>
        {icon}
        <Text variant="mediumBold">{text}</Text>
      </a>
      {isRemovable ? (
        <button
          type="button"
          className={styles.closeIconButton}
          onClick={() => {
            dispatch(
              setIsModalOpen({
                isOpen: true,
                type: 'Confirmation',
                confirmationText: t('document.delete.are.you.sure'),
                onSubmit: handleRemoveClick,
              })
            );
          }}>
          <CloseIcon />
        </button>
      ) : null}
    </div>
  );
}
