import {Text} from 'features/components/text/Text';
import {Link} from 'features/components/link/Link';

import {useTranslation} from 'hooks/translationHooks';
import styles from './SigningFooter.module.scss';

export function SigningFooter() {
  const {t} = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.footerTop}>
        <div className={styles.assistanceContainer}>
          <Text variant="large">{t('signing.page.footer.title')}</Text>
          <Text variant="mediumBold" className={styles.assistanceText}>
            {t('signing.page.footer.need.assistance')}
          </Text>
          <Text variant="small">
            {t('signing.page.footer.reach.us', {
              tel: (
                <a href={`tel:${t('signing.page.footer.reach.us.number')}`}>
                  {t('signing.page.footer.reach.us.number')}
                </a>
              ),
            })}
          </Text>
          <Text variant="small">
            {t('signing.page.footer.sending', {
              email: (
                <a href={`mailto:${t('signing.page.footer.sending.email')}`}>
                  {t('signing.page.footer.sending.email')}
                </a>
              ),
            })}
          </Text>
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.links}>
            <Link to="/features">
              <Text variant="small">{t('signing.page.footer.link.features')}</Text>
            </Link>
            <Link to="/contact">
              <Text variant="small">{t('signing.page.footer.link.contact')}</Text>
            </Link>
          </div>
          <div className={styles.links}>
            <Link to="/cookies">
              <Text variant="small">{t('signing.page.footer.link.cookies')}</Text>
            </Link>
            <Link to="/privacy">
              <Text variant="small">{t('signing.page.footer.link.privacy')}</Text>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.lineBreak} />
      <Text variant="small" className={styles.copyright}>
        {t('signing.page.footer.copyright')}
      </Text>
    </div>
  );
}
