import {Button} from 'features/components/button/Button';
import {ModalType} from 'store/reducers/modal';
import {useTranslation} from 'hooks/translationHooks';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {ReactComponent as TrashIcon} from 'assets/icons/TrashIcon.svg';

import styles from './ConfirmationModalControls.module.scss';

type Props = {
  onClose: () => void;
  onSubmit?: () => void;
  modalType: ModalType | undefined;
};

export function ConfirmationModalControls({modalType, onClose, onSubmit}: Props) {
  const {t} = useTranslation();

  if (modalType !== 'Confirmation') {
    return null;
  }

  return (
    <div className={styles.controls}>
      <UnderlineButton
        leftIcon={<ArrowRightIcon />}
        onClick={onClose}
        className={styles.cancelButton}>
        {t('confirmation.cancel.text')}
      </UnderlineButton>
      <Button
        leftIcon={<TrashIcon />}
        className={styles.submitButton}
        type="submit"
        form="contactForm"
        onClick={() => {
          if (onSubmit) {
            onSubmit();
          }
          onClose();
        }}>
        {t('confirmation.delete.text')}
      </Button>
    </div>
  );
}
