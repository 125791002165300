import {ChangeEvent, DragEvent, useRef, useState} from 'react';
import clsx from 'clsx';
import {ReactComponent as FileIcon} from 'assets/icons/FileIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {Button} from '../button/Button';
import {Text} from '../text/Text';

import styles from './FileInputDrop.module.scss';

type Props = {
  className?: string;
  title?: string;
  onChange: (files: File[]) => void;
  buttonName?: string;
};

export function FileInputDrop(props: Props) {
  const {t} = useTranslation();
  const {className, title, onChange, buttonName = t('home_header.add_files.button')} = props;
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(Array.from(e.dataTransfer.files));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      onChange(Array.from(e.target.files));
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className={clsx(styles.container, className)}>
      <form
        className={clsx(styles.form, dragActive && styles.dragActive)}
        onDragEnter={handleDrag}
        onSubmit={e => e.preventDefault()}>
        <input
          ref={inputRef}
          type="file"
          multiple
          onChange={handleChange}
          className={styles.fileInput}
        />
        <div className={styles.content}>
          {title ? (
            <Text variant="large" textAlign="center" className={styles.title}>
              {title}
            </Text>
          ) : null}
          <Button
            variant="secondary"
            leftIcon={<FileIcon />}
            className={styles.addFileButton}
            onClick={handleButtonClick}>
            {buttonName}
          </Button>
          <Text variant="medium" className={styles.caption}>
            {t('home_header.add_files.drop')}
          </Text>
        </div>
        {dragActive ? (
          <div
            className={styles.dragFileElement}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        ) : null}
      </form>
    </div>
  );
}
