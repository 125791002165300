import {ReactNode} from 'react';
import clsx from 'clsx';

import styles from './TextButton.module.scss';

type Props = {
  className?: string;
  icon?: ReactNode;
  children: string;
  onClick?: () => void;
  isActive?: boolean;
};

export function TextButton({className, icon, children, onClick, isActive}: Props) {
  return (
    <button
      type="button"
      className={clsx(styles.textButton, isActive && styles.textButtonActive, className)}
      onClick={onClick}>
      <div className={styles.textButtonIconWrapper}>{icon}</div>
      {children}
    </button>
  );
}
