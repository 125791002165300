import {useEffect, useState} from 'react';
import {Dropdown} from 'antd';
import {useAppDispatch, useAppSelector} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import {UserMenu} from 'pages/signing/components/UserMenu';
import {UserButton} from 'features/components/button/UserButton';
import {ReactComponent as ChevronDownIcon} from 'assets/icons/ChevronDownIcon.svg';
import {ReactComponent as UserIcon} from 'assets/icons/UserIcon.svg';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import styles from 'features/header/components/RolePicker.module.scss';
import {userSelectors} from 'store/reducers/user';
import {useTranslation} from 'hooks/translationHooks';
import {useLogin} from 'hooks/loginHooks';
import {Event} from 'types/event';

export function RolePicker(): JSX.Element {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const {cancel} = useLogin();
  const {event} = useAppSelector(state => state.event);
  const {userInfo} = useAppSelector(state => state.user);
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));

  useEffect(() => {
    if (event === Event.INITIATE_CERTIFICATE_SWITCH) {
      setIsOpen(true);
    }
  }, [event]);

  const handleClickLogin = () =>
    dispatch(setIsModalOpen({isOpen: true, type: 'Login', onClose: cancel}));
  const renderContent = () => <UserMenu onClose={() => setIsOpen(false)} />;

  const getActiveRoleName = () => {
    if (!activeRole) {
      return '';
    }

    return activeRole.organizationCode
      ? activeRole.organizationName
      : `${activeRole.givenName} ${activeRole.surName}`;
  };

  return userInfo && userInfo.roles ? (
    <Dropdown
      dropdownRender={renderContent}
      trigger={['click']}
      open={isOpen}
      placement="bottomRight"
      overlayClassName={styles.dropdown}>
      <UserButton
        onClick={() => setIsOpen(state => !state)}
        rightIcon={<ChevronDownIcon />}
        leftIcon={<UserIcon />}
        className={styles.userButton}>
        {getActiveRoleName()}
      </UserButton>
    </Dropdown>
  ) : (
    <Button
      onClick={handleClickLogin}
      rightIcon={<ArrowRightIcon />}
      className={styles.loginButton}>
      {t('header.log.in.button')}
    </Button>
  );
}
