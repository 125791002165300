import clsx from 'clsx';
import {Button} from 'features/components/button/Button';
import {useAppDispatch, useAppSelector} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import {Text} from 'features/components/text/Text';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {FileInputDrop} from 'features/components/file-input-drop/FileInputDrop';
import {useDocument} from 'hooks/documentsHooks';
import {clearDocument} from 'store/reducers/document';
import {resetSigning, startSigning} from 'store/reducers/signature';
import {useSignDocument} from 'hooks/signingHooks';
import {ProgressBar} from 'features/components/progress-bar/ProgressBar';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {ReactComponent as DownloadIcon} from 'assets/icons/DownloadIcon.svg';
import {ReactComponent as FileIcon} from 'assets/icons/FileIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {Notification} from 'features/components/notification/Notification';
import {useSignDocumentContainer} from 'hooks/useSignDocumentContainer';
import {useLogin} from 'hooks/loginHooks';
import {SignerStatusType} from 'types/document';
import {userSelectors} from 'store/reducers/user';
import styles from './SignDocumentContainer.module.scss';
import {FilesSection} from './FilesSection';

export function SignDocumentContainer() {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {documentDownloadLink} = useDocument();
  const {signDocument, cancel: cancelSigning} = useSignDocument();
  const {cancel: cancelLogin} = useLogin();

  const {
    showProgressBar,
    document,
    filesUploaded,
    handleCancel,
    uploadingProgress,
    handleFilesChange,
    handleBack,
    isDocumentLoading,
    isUploadingFiles,
    handleFileRemove,
  } = useSignDocumentContainer();

  const {userInfo} = useAppSelector(state => state.user);
  const isLoggedIn = !!userInfo;
  const {isSigned} = useAppSelector(state => state.signature);
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));
  const isSignedByUser = !!document?.signatures.some(
    s =>
      s.status === SignerStatusType.SIGNED &&
      (s.code === activeRole?.personalCode || s.code === activeRole?.organizationCode)
  );

  const handleAddSignature = async () => {
    if (document) {
      dispatch(startSigning());
      if (isLoggedIn) {
        signDocument(document.id).then(() =>
          dispatch(setIsModalOpen({isOpen: true, type: 'Signing', onClose: cancelSigning}))
        );
        return;
      }

      dispatch(setIsModalOpen({isOpen: true, type: 'Login', onClose: cancelLogin}));
    }
  };

  const handleCreateNew = () => {
    dispatch(clearDocument());
    dispatch(resetSigning());
  };

  const uploadSection = (() => {
    if (showProgressBar) {
      return (
        <div className={styles.progressBarWrapper}>
          <ProgressBar
            progress={uploadingProgress}
            className={styles.progressBarContainer}
            progressBarClassName={styles.progressBar}
            onCancel={handleCancel}
          />
        </div>
      );
    }

    return (
      <FileInputDrop
        className={styles.fileInputDrop}
        buttonName={filesUploaded ? t('signing.page.add.more.files') : t('signing.page.add.files')}
        onChange={handleFilesChange}
      />
    );
  })();

  return (
    <div className={styles.container}>
      <div className={styles.uploadContainer}>
        {isSigned ? (
          <>
            <Notification variant="success">
              <Text variant="large">{t('signing.page.document.signed')}</Text>
            </Notification>
            <Text variant="xLarge" className={styles.containerName}>
              {document?.name}
            </Text>
            <a href={documentDownloadLink} download className={styles.downloadButton}>
              <DownloadIcon />
              {t('signing.page.document.signed.download')}
            </a>
          </>
        ) : (
          <Text variant="xLarge">{t('signing.page.title')}</Text>
        )}
        {uploadSection}
      </div>
      {filesUploaded ? (
        <FilesSection
          files={document?.files || []}
          onFileRemove={handleFileRemove}
          signatures={document?.signatures || []}
          isCreator={!!document?.creator}
        />
      ) : null}
      {isSigned ? (
        <div className={styles.createNew}>
          <Button
            variant="secondary"
            leftIcon={<FileIcon />}
            className={styles.createNewButton}
            onClick={handleCreateNew}>
            {t('signing.page.create.new.button')}
          </Button>
        </div>
      ) : (
        <div className={clsx(styles.controls, filesUploaded && styles.controlsHighlighted)}>
          <UnderlineButton
            leftIcon={<ArrowRightIcon />}
            className={clsx(!filesUploaded && styles.buttonHidden, styles.cancelButton)}
            onClick={handleBack}>
            {isLoggedIn ? t('signing.page.cancel') : t('signing.page.back')}
          </UnderlineButton>
          <Button
            rightIcon={<ArrowRightIcon />}
            disabled={isDocumentLoading || isUploadingFiles || !filesUploaded || isSignedByUser}
            className={styles.addSignatureButton}
            onClick={handleAddSignature}>
            {isLoggedIn ? t('signing.page.add.signature') : t('signing.page.log.in.add.signature')}
          </Button>
        </div>
      )}
    </div>
  );
}
