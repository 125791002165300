import {Text} from 'features/components/text/Text';
import {Switch} from 'features/components/switch/Switch';
import {SyntheticEvent, useCallback, useEffect, useState} from 'react';
import {Input} from 'features/components/input/Input';
import {useContact} from 'hooks/contactHooks';
import {Contact, ContactRequest, LegalType} from 'types/contact';
import {useAppDispatch, useAppSelector} from 'store';

import {useTranslation} from 'hooks/translationHooks';
import {setIsModalOpen} from 'store/reducers/modal';
import {Notification} from 'features/components/notification/Notification';
import styles from './ContactModal.module.scss';

const countryOptions = [
  {value: 'AZ', label: 'Azerbaijan'},
  {value: 'RU', label: 'Russian'},
  {value: 'EN', label: 'English'},
];

const initialData: ContactRequest = {
  type: LegalType.Private,
  name: '',
  code: '',
  email: '',
  country: countryOptions[0].value,
};

type Props = {
  contactId?: string;
};

export function ContactModal({contactId}: Props) {
  const {t} = useTranslation();
  const [fieldsData, setFieldsData] = useState<ContactRequest>(initialData);

  const {addContact, editContact} = useContact();
  const dispatch = useAppDispatch();
  const {contacts, errors} = useAppSelector(state => state.contact);
  const fieldErrors = errors.filter(e => e.field || [1015, 1027].includes(e.code));

  useEffect(() => {
    if (contactId) {
      const contactToEdit = contacts?.find(contact => contact.id === contactId);
      if (contactToEdit) {
        setFieldsData({
          ...contactToEdit,
        });
      }
    }
  }, [contactId, contacts]);

  const handleLegalTypeSwitch = () => {
    setFieldsData(prev => ({
      ...initialData,
      type: prev.type === LegalType.Private ? LegalType.Business : LegalType.Private,
    }));
  };

  const handleCertificateTypeSwitch = () => {
    setFieldsData(prev => ({
      ...prev,
      type: prev.type === LegalType.Government ? LegalType.Business : LegalType.Government,
    }));
  };

  const handleFinChange = (field: keyof ContactRequest, value: string) => {
    setFieldsData(prev => ({
      ...prev,
      [field]: value.trim().toUpperCase(),
    }));
  };

  const updateFieldData = (field: keyof ContactRequest, value: string) => {
    setFieldsData(prev => ({
      ...prev,
      [field]: /^\s*$/.test(value) ? value.trim() : value,
    }));
  };

  const legalTypeTooltipContent = (
    <div>
      <Text variant="medium">{t('signers.modal.certificate.type.tooltip')}</Text>
    </div>
  );

  const certificateTypeTooltipContent = (
    <div>
      <Text variant="medium">
        {fieldsData.type === LegalType.Government
          ? t('signers.modal.government.type.tooltip')
          : t('signers.modal.legal.type.tooltip')}
      </Text>
    </div>
  );

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    const nonEmptyValues = Object.fromEntries(
      Object.entries(fieldsData)
        .filter(([, value]) => value !== '')
        .map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
    );

    try {
      if (contactId) {
        editContact({id: contactId, ...nonEmptyValues} as Contact).then(() =>
          dispatch(setIsModalOpen({isOpen: false, type: 'Contact'}))
        );
      } else {
        addContact(nonEmptyValues as ContactRequest).then(() =>
          dispatch(setIsModalOpen({isOpen: false, type: 'Contact'}))
        );
      }
    } catch (err) {
      /* empty */
    }
  };

  const isBusinessType =
    fieldsData.type === LegalType.Business || fieldsData.type === LegalType.Government;

  const getBusinessTitle = () =>
    fieldsData.type === LegalType.Business
      ? t('settings.page.contacts.modal.name.of.company')
      : t('settings.page.contacts.modal.name.of.government');

  const getErrorMsg = useCallback(
    (fieldName: string) => {
      const error = fieldErrors.find(
        e =>
          e.field === fieldName ||
          (['representativeCode', 'code'].includes(fieldName) && [1015, 1027].includes(e.code))
      );
      const shouldNotShowInvalidFinErrorForRepresentative =
        fieldName === 'representativeCode' && !fieldsData.representativeCode;

      if (error?.code === 1027 && shouldNotShowInvalidFinErrorForRepresentative) {
        return undefined;
      }

      return error?.message;
    },
    [fieldErrors, fieldsData.representativeCode]
  );

  return (
    <div className={styles.container}>
      <Text variant="largeBold" textAlign="center">
        {contactId
          ? t('settings.page.contacts.modal.edit.title')
          : t('settings.page.contacts.modal.add.title')}
      </Text>
      {fieldErrors.length > 0 && (
        <div className={styles.errorMessage}>
          <Notification variant="error" fullWidth>
            <Text variant="large">
              {fieldErrors[0].code === 1027 && !!fieldsData.code && !!fieldsData.representativeCode
                ? t('contacts.page.errors.fin_voen')
                : fieldErrors[0].message}
            </Text>
          </Notification>
        </div>
      )}
      <form className={styles.content} id="contactForm" onSubmit={handleSubmit}>
        <Switch
          leftText={t('settings.page.contacts.modal.legal.type.private')}
          rightText={t('settings.page.contacts.modal.legal.type.business')}
          checked={isBusinessType}
          onChange={handleLegalTypeSwitch}
          label={t('settings.page.contacts.modal.legal.type')}
          tooltipContent={legalTypeTooltipContent}
        />
        {isBusinessType ? (
          <Switch
            className={styles.certificateTypeSwitchWrapper}
            leftText={t('signers.modal.switch.business')}
            rightText={t('signers.modal.switch.government')}
            checked={fieldsData.type === LegalType.Government}
            onChange={handleCertificateTypeSwitch}
            label={t('signers.modal.certificate.type')}
            tooltipContent={certificateTypeTooltipContent}
          />
        ) : null}
        <Input
          className={styles.input}
          label={
            isBusinessType ? getBusinessTitle() : t('settings.page.contacts.modal.name.of.contact')
          }
          value={fieldsData.name}
          onChange={value => updateFieldData('name', value)}
          errorMsg={getErrorMsg('name')}
          showErrorMsg={false}
        />
        {/*
         <SelectInput
                  className={styles.input}
                  label={t('settings.page.contacts.modal.country')}
                  options={countryOptions}
                  defaultValue={countryOptions[0].value}
                  onChange={value => updateFieldData('country', value)}
                />
                */}
        {isBusinessType ? (
          <Input
            className={styles.input}
            label="VÖEN"
            value={fieldsData.code || ''}
            onChange={value => handleFinChange('code', value)}
            errorMsg={getErrorMsg('code')}
            showErrorMsg={false}
          />
        ) : (
          <Input
            className={styles.input}
            label="FIN"
            value={fieldsData.code || ''}
            onChange={value => handleFinChange('code', value)}
            errorMsg={getErrorMsg('code')}
            showErrorMsg={false}
          />
        )}
        <Input
          className={styles.input}
          label={t('settings.page.contacts.modal.email')}
          value={fieldsData.email || ''}
          onChange={value => updateFieldData('email', value)}
          errorMsg={getErrorMsg('email')}
          showErrorMsg={false}
        />
        {isBusinessType ? (
          <>
            <Input
              className={styles.input}
              label={t('settings.page.contacts.modal.name.representative')}
              value={fieldsData.representativeName || ''}
              onChange={value => updateFieldData('representativeName', value)}
              optional
              errorMsg={getErrorMsg('representativeName')}
              showErrorMsg={false}
            />
            <Input
              className={styles.input}
              label={t('settings.page.contacts.modal.pin.representative')}
              value={fieldsData.representativeCode || ''}
              onChange={value => handleFinChange('representativeCode', value)}
              optional
              errorMsg={getErrorMsg('representativeCode')}
              showErrorMsg={false}
            />
          </>
        ) : null}
      </form>
    </div>
  );
}
