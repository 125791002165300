import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import {ReactComponent as BusinessIcon} from 'assets/icons/BusinessIcon.svg';
import {ReactComponent as UserIcon} from 'assets/icons/UserIcon.svg';

import {useAppSelector} from 'store';
import {useLogout} from 'hooks/logoutHooks';
import {TextButton} from 'features/components/button/TextButton';
import {Role} from 'types/user';
import {useChangeRole} from 'hooks/userHooks';
import {userSelectors} from 'store/reducers/user';
import {useTranslation} from 'hooks/translationHooks';
import styles from './UserMenu.module.scss';

type DropdownMenuProps = {
  onClose: () => void;
};

export function UserMenu({onClose}: DropdownMenuProps) {
  const {signOut} = useLogout();
  const {t} = useTranslation();
  const {changeRole} = useChangeRole();
  const roles = useAppSelector(state => userSelectors.getRoles(state.user));

  const changeActiveCertificate = (role: Role) => {
    changeRole(role.roleId).finally(onClose);
  };

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <UnderlineButton onClick={signOut}>{t('user.menu.log.out')}</UnderlineButton>
        <button type="button" onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </button>
      </div>
      <h2>{t('user.menu.choose.certificate')}</h2>
      {roles.map(role => (
        <TextButton
          key={role.roleId}
          icon={role.organizationCode ? <BusinessIcon /> : <UserIcon />}
          isActive={role.current}
          onClick={() => changeActiveCertificate(role)}
          className={styles.certificateButton}>
          {role.organizationCode ? role.organizationName : `${role.givenName} ${role.surName}`}
        </TextButton>
      ))}
    </div>
  );
}
