import Modal from 'react-modal';
import clsx from 'clsx';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';

import {clearDocument} from 'store/reducers/document';
import {resetSigning} from 'store/reducers/signature';
import {useAppDispatch, useAppSelector} from 'store';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'hooks/translationHooks';
import {TextButton} from 'features/components/button/TextButton';
import {languages, useLanguageChange} from 'features/header/components/LanguagePicker.utils';
import {useNavigation} from 'hooks/navigateHooks';
import {Header} from './Header';
import {sectionButtons} from './SideMenu';

import styles from './MobileMenuModal.module.scss';
import sideMenuStyles from './SideMenu.module.scss';

Modal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  toggleMobileMenu: () => void;
};

export function MobileMenuModal({isOpen, toggleMobileMenu}: Props) {
  const dispatch = useAppDispatch();
  const {navigate} = useNavigation();
  const location = useLocation();
  const {handleLanguageChange} = useLanguageChange();
  const {activeLanguage} = useAppSelector(state => state.translation);

  const {t} = useTranslation();

  const handleCreateNew = () => {
    dispatch(clearDocument());
    dispatch(resetSigning());
    toggleMobileMenu();
    navigate('/new');
  };

  return (
    <Modal
      className={styles.modalContainer}
      isOpen={isOpen}
      onRequestClose={toggleMobileMenu}
      overlayClassName={styles.modalOverlay}>
      <div className={styles.contentLayout}>
        <Header toggleMobileMenu={toggleMobileMenu} mobileMenuOpen={isOpen} />
        <div className={styles.content}>
          <Button
            variant="secondary"
            rightIcon={<ArrowRightIcon />}
            className={styles.createNewButton}
            onClick={handleCreateNew}>
            {t('settings.page.documents.create')}
          </Button>
          <div className={sideMenuStyles.lineBreak} />
          <div>
            {sectionButtons.slice(0, 4).map(button => (
              <TextButton
                key={button.id}
                icon={button.icon}
                onClick={() => navigate(button.link)}
                className={sideMenuStyles.sectionButton}
                isActive={location.pathname === button.link}>
                {t(button.label)}
              </TextButton>
            ))}
          </div>
          <div className={sideMenuStyles.lineBreak} />
          <div>
            {sectionButtons.slice(4).map(button => (
              <TextButton
                key={button.id}
                icon={button.icon}
                onClick={() => navigate(button.link)}
                className={sideMenuStyles.sectionButton}
                isActive={location.pathname === button.link}>
                {t(button.label)}
              </TextButton>
            ))}
          </div>
          <div className={sideMenuStyles.lineBreak} />
          <div className={styles.languages}>
            {languages.map(language => (
              <button
                className={clsx(activeLanguage === language.code && styles.languageActive)}
                key={language.code}
                type="button"
                onClick={() => handleLanguageChange(language.code)}>
                {language.code.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
