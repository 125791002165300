import {message} from 'antd';
import {useDispatch} from 'react-redux';
import {useCallback, useEffect, useRef} from 'react';
import {
  useCancelSignInMutation,
  useLazyGetUserQuery,
  usePollAuthInfoMutation,
  useSignInMutation,
} from 'store/api/user';
import {ResponseStatus} from 'types/shared';
import {useAppDispatch, useAppSelector} from 'store';
import {cancelLogin, setUserData, startLogIn} from 'store/reducers/user';
import {
  authenticationSuccessful,
  decreaseTimeRemaining,
  resetAuth,
  setAuthError,
  setAuthResponse,
  startAuthentication,
} from 'store/reducers/auth';
import {AuthenticationResponse} from 'types/auth';
import {setIsModalOpen} from 'store/reducers/modal';
import {useLocation} from 'react-router-dom';
import {useNavigation} from 'hooks/navigateHooks';

export const useGetUserInfo = () => {
  const [trigger] = useLazyGetUserQuery();
  const dispatch = useAppDispatch();
  const {isOpen, type} = useAppSelector(state => state.modal);

  const fetchUser = useCallback(async () => {
    return trigger()
      .unwrap()
      .then(response => {
        dispatch(setUserData({userInfo: response}));
        if (isOpen && type === 'Login') {
          dispatch(setIsModalOpen({isOpen: false, type: 'Login'}));
        }
      })
      .catch(e => {
        throw e;
      });
  }, [dispatch, isOpen, trigger, type]);

  return {
    fetchUser,
  };
};

export const useLogin = () => {
  const [signIn] = useSignInMutation();
  const [cancelSignIn] = useCancelSignInMutation();
  const [pollAuthInformation] = usePollAuthInfoMutation();
  const {isLoading} = useAppSelector(state => state.user);
  const {authResponse, isLoading: isAuthenticating} = useAppSelector(state => state.auth);
  const {fetchUser} = useGetUserInfo();
  const dispatch = useDispatch();
  const pollTimeout = useRef<NodeJS.Timeout | number | null>(null);
  const isAuthenticatingRef = useRef(isAuthenticating);
  const {document} = useAppSelector(state => state.document);
  const {navigate} = useNavigation();
  const location = useLocation();

  useEffect(() => {
    isAuthenticatingRef.current = isAuthenticating;
  }, [isAuthenticating]);

  const poll = useCallback(() => {
    pollAuthInformation()
      .unwrap()
      .then(response => {
        if (response.status === ResponseStatus.USER_AUTHENTICATED) {
          dispatch(authenticationSuccessful({authResponse: response}));
          fetchUser().then(() => {
            dispatch(setIsModalOpen({isOpen: false}));
            if (document) {
              navigate(`/document/${document.id}`, {state: {fromPath: location.pathname}});
            } else if (!location.state?.from.includes('/document')) {
              navigate(`/new`, {state: {fromPath: location.pathname}});
            }
          });
        } else if (response.status === ResponseStatus.OUTSTANDING_TRANSACTION) {
          if (isAuthenticatingRef.current) {
            pollTimeout.current = setTimeout(() => {
              poll();
              dispatch(decreaseTimeRemaining());
            }, 1000);
          }
        } else {
          message.error('Authentication failed');
          dispatch(setAuthError([{code: 500, message: 'Authentication failed'}]));
        }
      })
      .catch(error => {
        if (isAuthenticatingRef.current) {
          dispatch(setAuthError(error.data.errors));
        }
      });
  }, [
    pollAuthInformation,
    dispatch,
    fetchUser,
    document,
    location.state?.from,
    location.pathname,
    navigate,
  ]);

  const login = useCallback(
    (userId: string, phoneNumber: string) => {
      dispatch(startLogIn());
      return signIn({
        userId,
        phoneNumber,
      })
        .unwrap()
        .then((response: AuthenticationResponse) => {
          if (response.status === ResponseStatus.OUTSTANDING_TRANSACTION) {
            dispatch(setAuthResponse({authResponse: response}));
            dispatch(startAuthentication());
            poll();
          } else {
            message.error('Failed to initialize authentication');
            dispatch(setAuthError([{code: 500, message: 'Failed to initialize authentication'}]));
          }
        })
        .catch(error => {
          dispatch(setAuthError(error.data.errors));
        });
    },
    [dispatch, poll, signIn]
  );

  const cancel = useCallback(() => {
    if (pollTimeout.current !== null) {
      clearTimeout(pollTimeout.current);
    }
    cancelSignIn();
    dispatch(cancelLogin());
    dispatch(resetAuth());
  }, [cancelSignIn, dispatch, pollTimeout]);

  return {
    login,
    cancel,
    authResponse,
    isLoading,
    isAuthenticating,
  };
};
