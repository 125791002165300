import 'features/components/modal/verification/verification.scss';
import {Progress} from 'antd';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {COLORS} from 'css/colors';
import {Text} from 'features/components/text/Text';
import styles from 'features/components/modal/verification/Verification.module.scss';
import {Notification} from 'features/components/notification/Notification';
import {ErrorDto} from 'types/shared';

export const MAX_SECONDS = 120;

type Props = {
  verificationCode: string;
  verificationCodeLabel: string;
  timeRemaining: number;
  description: string;
  errors: ErrorDto[];
  onCancel: () => void;
  onCancelLabel: string;
};

export function Verification({
  verificationCode,
  verificationCodeLabel,
  timeRemaining,
  description,
  errors,
  onCancel,
  onCancelLabel,
}: Props): JSX.Element {
  return (
    <div className="verification">
      {errors.length > 0 && (
        <div className={styles.errorMessage}>
          <Notification variant="error" fullWidth>
            <Text variant="large">{errors[0].message}</Text>
          </Notification>
        </div>
      )}
      <Text variant="small" textAlign="center">
        {description}
      </Text>
      {errors.length === 0 && (
        <div className="verification__code">
          <Text
            variant="smallBold"
            textAlign="center"
            color="green"
            textTransform="transformUpperCase">
            {verificationCodeLabel}
          </Text>
          <Text variant="xxLarge" textAlign="center" color="green">
            {verificationCode}
          </Text>
        </div>
      )}
      <div className="verification__progress">
        <Progress
          percent={(100 / MAX_SECONDS) * timeRemaining}
          strokeColor={COLORS.ivory}
          trailColor={timeRemaining === 0 ? COLORS.error : COLORS.warmWhite}
          showInfo={false}
          className="verification__progress-bar"
        />
        <div className="verification__time">
          <Text variant="largeBold" textAlign="right">
            {timeRemaining} sec
          </Text>
        </div>
      </div>
      <div className={styles.cancelButtonWrapper}>
        <UnderlineButton onClick={onCancel}>{onCancelLabel}</UnderlineButton>
      </div>
    </div>
  );
}
