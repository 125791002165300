import {ReactElement, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {setActiveLanguage, setTranslations} from 'store/reducers/translation';
import {useLazyGetTranslationsQuery} from 'store/api/translation';
import {AllowedLanguages} from 'types/translation';
import {useAppendPreferenceMutation} from 'store/api/userPreferences';
import {PreferenceName} from 'types/userPreferences';
import {getPageLanguageOnLanding} from './Translation.utils';

type Props = {
  children: ReactElement;
};

export function Translation({children}: Props) {
  const dispatch = useAppDispatch();
  const {activeLanguage} = useAppSelector(state => state.translation);
  const {userInfo} = useAppSelector(state => state.user);

  const [appendPreferenceMutation] = useAppendPreferenceMutation();
  const [trigger] = useLazyGetTranslationsQuery();
  const [loadingTranslations, setLoadingTranslations] = useState(true);

  const handleChangeLanguage = (lang: AllowedLanguages) => {
    trigger({lang}).then(response => {
      const {data} = response || {};
      dispatch(setTranslations({translations: data}));
      setLoadingTranslations(false);
    });
  };

  const setLanguageOnLanding = () => {
    const landingLanguage = getPageLanguageOnLanding();
    dispatch(setActiveLanguage({activeLanguage: landingLanguage}));
    if (userInfo) {
      appendPreferenceMutation({name: PreferenceName.LANGUAGE, value: landingLanguage});
    }
    handleChangeLanguage(landingLanguage);
  };

  useEffect(() => {
    if (activeLanguage) {
      handleChangeLanguage(activeLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage]);

  useEffect(() => {
    if (loadingTranslations) {
      setLanguageOnLanding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTranslations]);

  if (loadingTranslations) return null;

  return children;
}
