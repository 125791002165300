import './howItWorks.scss';
import {ReactComponent as DocumentIcon} from 'assets/icons/DocumentIcon.svg';
import {ReactComponent as SignatureIcon} from 'assets/icons/SignatureIcon.svg';
import {Spacer} from 'features/components/Spacer';
import {ReactComponent as ShieldIcon} from 'assets/icons/ShieldIcon.svg';
import {Text} from 'features/components/text/Text';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {useNavigation} from 'hooks/navigateHooks';
import styles from './HowItWorks.module.scss';

export function HowItWorks(): JSX.Element {
  const {navigate} = useNavigation();
  const {t} = useTranslation();

  const onStartSigning = () => navigate('/signing');

  return (
    <div className="how-it-works content">
      <div className="how-it-works__container">
        <h1 className="how-it-works__h1">{t('home_content.add_files.title')}</h1>
        <div className="how-it-works__steps">
          <div className="how-it-works__step">
            <div className="how-it-works__icon">
              <SignatureIcon />
            </div>
            <Text
              className="how-it-works__title"
              variant="smallCaps"
              color="green"
              textTransform="transformUpperCase">
              {t('home_content.description.one.title')}
            </Text>
            <Text variant="large">{t('home_content.description.one.subtitle')}</Text>
          </div>
          <div className="how-it-works__step">
            <div className="how-it-works__icon">
              <ShieldIcon />
            </div>
            <Text
              variant="smallCaps"
              className="how-it-works__title"
              color="green"
              textTransform="transformUpperCase">
              {t('home_content.description.two.title')}
            </Text>
            <Text variant="large">{t('home_content.description.two.subtitle')}</Text>
          </div>
          <div className="how-it-works__step">
            <div className="how-it-works__icon">
              <DocumentIcon />
            </div>
            <Text
              className="how-it-works__title"
              variant="smallCaps"
              color="green"
              textTransform="transformUpperCase">
              {t('home_content.description.three.title')}
            </Text>
            <Text variant="large">{t('home_content.description.three.subtitle')}</Text>
          </div>
        </div>
        <div className="how-it-works__start">
          <Text variant="largeBold" textAlign="center" color="green">
            {t('home_footer.title')}
          </Text>
          <Spacer height={48} />
          <Button
            rightIcon={<ArrowRightIcon />}
            onClick={onStartSigning}
            className={styles.startSigningButton}>
            {t('home_footer.button.title')}
          </Button>
        </div>
      </div>
    </div>
  );
}
