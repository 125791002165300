import {
  AppendDocumentRequest,
  ChangeDocumentNameRequest,
  DeleteFileRequest,
  Document,
  DocumentConvertRequest,
  DocumentDeclineApproveRequest,
  DocumentDeleteRequest,
  DocumentRequest,
  DocumentsDeleteRequest,
  PaginationDocumentRequest,
  PaginationDocuments,
  SignerDeleteRequest,
  SignerRequest,
  SignerUpdateRequest,
} from 'types/document';
import {apiSlice} from './apiSlice';

function addURLSearchParams(
  params: URLSearchParams,
  param: string,
  value?: string | number | undefined
): void {
  if (value) {
    params.append(param, value.toString());
  }
}

export const APIUserSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDocumentById: builder.query<Document, string>({
      query: id => `/documents/${id}`,
      keepUnusedDataFor: 0,
    }),

    getAllDocuments: builder.query<PaginationDocuments, PaginationDocumentRequest>({
      query: ({filter, searchTerm, page, size, sort}) => {
        const params = new URLSearchParams();
        addURLSearchParams(params, 'filter', filter);
        addURLSearchParams(params, 'page', page);
        addURLSearchParams(params, 'size', size);
        if (sort) {
          const sortParts = sort.split(',');
          const direction = sortParts[sortParts.length - 1];
          sortParts.pop();
          sortParts.forEach(sortField => {
            const dir = sortField === 'documentDate' ? 'DESC' : direction;
            addURLSearchParams(params, 'sort', `${sortField},${dir}`);
          });
        }
        const searchQueryParam = searchTerm ? `&searchTerm=${searchTerm}` : '';
        return `/documents?${params.toString()}${searchQueryParam}`;
      },
      providesTags: ['Documents'],
    }),

    newDocument: builder.mutation<Document, DocumentRequest>({
      query: ({files}) => {
        const body = new FormData();
        files.forEach(file => {
          body.append('Content-Type', file.type);
          body.append('files', file);
        });

        const forceAdoc = files.length === 1 && files[0].type === 'application/pdf';

        return {
          url: `/documents?forceAdoc=${forceAdoc}`,
          method: 'POST',
          body,
          formData: true,
        };
      },
    }),

    appendDocument: builder.mutation<Document, AppendDocumentRequest>({
      query: ({id, files}) => {
        const body = new FormData();
        files.forEach(file => {
          body.append('Content-Type', file.type);
          body.append('files', file);
        });

        return {
          url: `/documents/${id}`,
          method: 'POST',
          body,
          formData: true,
        };
      },
    }),

    appendShare: builder.mutation<void, {id: string}>({
      query: ({id}) => {
        return {
          url: `/documents/${id}/notify`,
          method: 'POST',
        };
      },
    }),

    deleteFile: builder.mutation<Document, DeleteFileRequest>({
      query: ({id, fileId}) => {
        return {
          url: `/documents/${id}/delete/${fileId}`,
          method: 'DELETE',
        };
      },
    }),

    deleteDocument: builder.mutation<void, DocumentDeleteRequest>({
      query: ({id}) => {
        return {
          url: `/documents/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Documents'],
    }),

    deleteDocuments: builder.mutation<void, DocumentsDeleteRequest>({
      query: ({ids}) => {
        return {
          url: `/documents`,
          method: 'DELETE',
          body: ids,
        };
      },
      invalidatesTags: ['Documents'],
    }),

    convertDocument: builder.mutation<Document, DocumentConvertRequest>({
      query: ({id, type}) => {
        return {
          url: `/documents/${id}/convert`,
          body: {type},
          method: 'PUT',
        };
      },
    }),

    changeDocumentName: builder.mutation<Document, ChangeDocumentNameRequest>({
      query: ({id, name}) => {
        return {
          url: `/documents/${id}`,
          method: 'PUT',
          body: {name},
        };
      },
      invalidatesTags: ['Documents'],
    }),

    appendSigner: builder.mutation<Document, SignerRequest>({
      query: ({id, signer}) => {
        return {
          url: `/documents/${id}/signatures`,
          method: 'POST',
          body: {...signer},
        };
      },
    }),

    updateSigner: builder.mutation<Document, SignerUpdateRequest>({
      query: ({id, signatureId, signer}) => {
        return {
          url: `/documents/${id}/signatures/${signatureId}`,
          method: 'PUT',
          body: {...signer},
        };
      },
    }),

    deleteSigner: builder.mutation<Document, SignerDeleteRequest>({
      query: ({id, signatureId}) => {
        return {
          url: `/documents/${id}/signatures/${signatureId}`,
          method: 'DELETE',
        };
      },
    }),

    declineDocument: builder.mutation<Document, DocumentDeclineApproveRequest>({
      query: ({id, comment}) => {
        return {
          url: `/documents/${id}/signatures/decline`,
          method: 'PUT',
          body: {comment},
        };
      },
    }),

    approveDocument: builder.mutation<Document, DocumentDeclineApproveRequest>({
      query: ({id, comment}) => {
        return {
          url: `/documents/${id}/signatures/approve`,
          method: 'PUT',
          body: {comment},
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetDocumentByIdQuery,
  useNewDocumentMutation,
  useAppendDocumentMutation,
  useDeleteFileMutation,
  useConvertDocumentMutation,
  useChangeDocumentNameMutation,
  useAppendSignerMutation,
  useDeleteSignerMutation,
  useUpdateSignerMutation,
  useLazyGetAllDocumentsQuery,
  useDeleteDocumentsMutation,
  useAppendShareMutation,
  useDeleteDocumentMutation,
  useDeclineDocumentMutation,
  useApproveDocumentMutation,
} = APIUserSlice;
