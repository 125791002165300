import clsx from 'clsx';
import {ReactComponent as ChevronDownIcon} from 'assets/icons/ChevronDownIcon.svg';
import {useEffect, useRef, useState} from 'react';
import {SignatureType} from 'types/document';
import {useTranslation} from 'hooks/translationHooks';
import {useOnClickOutside} from 'utils/useOnClickOutside';
import styles from './RoleSelect.module.scss';
import {Text} from '../text/Text';

type Option = {
  value: SignatureType;
  label: string;
};

type Props = {
  isAllowedToChange: boolean;
  options: Option[];
  onChange: (value: SignatureType) => void;
  value: string;
  position?: 'right' | 'left';
  className?: string;
  required?: boolean;
};

export function RoleSelect({
  className,
  onChange,
  value,
  required,
  options,
  isAllowedToChange,
  position = 'left',
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [roleValue, setRoleValue] = useState(value);
  const {t} = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => setIsFocused(true);
  const handleClickOutside = () => setIsFocused(false);

  useOnClickOutside(inputRef, handleClickOutside);

  useEffect(() => {
    setRoleValue(value);
  }, [value]);

  return (
    <div className={className}>
      <div className={styles.dropdown}>
        <div className={styles.inputWrapper}>
          <input
            ref={inputRef}
            type="button"
            value={roleValue}
            className={clsx(
              styles.input,
              roleValue === options[0].label && styles.highlightedInput,
              !isAllowedToChange && styles.labelInput,
              isFocused && styles.focus
            )}
            onClick={handleClick}
            required={required}
          />
          {isAllowedToChange && (
            <>
              <div
                className={clsx(
                  styles.dropdownContent,
                  position === 'right' && styles.openAlignRight
                )}>
                {options.map(option => (
                  <button
                    type="button"
                    key={option.value}
                    className={styles.option}
                    onClick={() => {
                      onChange(option.value);
                      setRoleValue(option.label);
                    }}>
                    <Text variant="medium">{t(option.label)}</Text>
                  </button>
                ))}
              </div>
              <ChevronDownIcon />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
