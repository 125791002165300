import {Text} from 'features/components/text/Text';

import styles from './ConfirmationModal.module.scss';

type Props = {
  confirmationText?: string;
};

export function ConfirmationModal({confirmationText}: Props) {
  return (
    <div className={styles.container}>
      <Text variant="largeBold">{confirmationText}</Text>
    </div>
  );
}
