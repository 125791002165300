import {useChangeRoleMutation} from 'store/api/user';
import {useAppSelector} from 'store';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {setAuthError} from 'store/reducers/auth';
import {message} from 'antd';
import {inititateLoading, setUserData} from 'store/reducers/user';
import {User} from 'types/user';

export const useChangeRole = () => {
  const [mutation] = useChangeRoleMutation();
  const {isLoading} = useAppSelector(state => state.user);
  const dispatch = useDispatch();

  const changeRole = useCallback(
    (roleId: string) => {
      dispatch(inititateLoading());
      return mutation(roleId)
        .unwrap()
        .then((response: User) => {
          dispatch(setUserData({userInfo: response}));
        })
        .catch(e => {
          message.error('Failed to change role');
          dispatch(setAuthError(e.data.errors));
        });
    },
    [dispatch, mutation]
  );

  return {
    changeRole,
    isLoading,
  };
};
