import {SigningFooter} from 'features/footer/SigningFooter';
import {Header} from './components/Header';
import {Layout} from './components/Layout';
import {SignDocumentContainer} from './components/SignDocumentContainer';

export function Signing() {
  return (
    <Layout>
      <Header />
      <SignDocumentContainer />
      <SigningFooter />
    </Layout>
  );
}
