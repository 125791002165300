const formatMonthOrDayOrTime = (value: number) => (value < 10 ? `0${value}` : value);

export const formatDate = (date: string) => {
  const dateObj = new Date(date);

  return `${formatMonthOrDayOrTime(dateObj.getDate())}.${formatMonthOrDayOrTime(
    dateObj.getMonth() + 1
  )}.${dateObj.getFullYear()} ${formatMonthOrDayOrTime(
    dateObj.getHours()
  )}:${formatMonthOrDayOrTime(dateObj.getMinutes())}`;
};
