import {createSlice} from '@reduxjs/toolkit';
import {StoreState} from 'types/shared';
import {Role, User} from 'types/user';

export interface UserStoreState extends StoreState {
  userInfo: User | null;
  isInitialLoadingDone: boolean;
}

export const initialUserState: UserStoreState = {
  userInfo: null,
  isLoading: false,
  isInitialLoadingDone: false,
  errors: [],
};

const userReducer = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    startLogIn(state) {
      state.isLoading = true;
      state.errors = [];
    },
    inititateLoading(state) {
      state.isLoading = true;
    },
    setUserData(state, action) {
      state.userInfo = action.payload.userInfo;
      state.isLoading = false;
      state.isInitialLoadingDone = true;
    },
    setInitialUserLoadingDone(state) {
      state.isLoading = false;
      state.isInitialLoadingDone = true;
    },
    cancelLogin() {
      return {...initialUserState, isInitialLoadingDone: true};
    },
    logout(state) {
      state.userInfo = null;
    },
  },
});

const getActiveRole = (state: UserStoreState): Role | undefined => {
  return state.userInfo?.roles.find(role => role.current);
};

const roleComparator = (a: Role, b: Role) => {
  if (a.organizationCode === null && b.organizationCode === null) {
    return 0;
  }
  if (a.organizationCode === null) {
    return -1;
  }
  if (b.organizationCode === null) {
    return 1;
  }
  return a.organizationCode.localeCompare(b.organizationCode);
};

const getRoles = (state: UserStoreState): Role[] => {
  const roles = state.userInfo?.roles || null;
  return roles ? [...roles].sort(roleComparator) : [];
};

export const userSelectors = {
  getActiveRole,
  getRoles,
};

export default userReducer.reducer;

export const {
  startLogIn,
  inititateLoading,
  cancelLogin,
  setUserData,
  logout,
  setInitialUserLoadingDone,
} = userReducer.actions;
