import {useSignDocumentContainer} from 'hooks/useSignDocumentContainer';
import {Text} from 'features/components/text/Text';
import clsx from 'clsx';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {ReactComponent as ContactBookIcon} from 'assets/icons/ContactBookIcon.svg';
import {FileInputDrop} from 'features/components/file-input-drop/FileInputDrop';
import {ProgressBar} from 'features/components/progress-bar/ProgressBar';
import {UnderlineButton} from 'features/components/button/UnderlineButton';
import {useTranslation} from 'hooks/translationHooks';
import {FilesContainer} from 'features/components/files-container/FilesContainer';
import {
  SignatureCellType,
  SignaturesTable,
} from 'features/components/signatures-table/SignaturesTable';
import {useAppDispatch, useAppSelector} from 'store';

import {setIsModalOpen} from 'store/reducers/modal';
import {startSigning} from 'store/reducers/signature';
import {useSignDocument} from 'hooks/signingHooks';
import {useDocument} from 'hooks/documentsHooks';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Tooltip} from 'antd';
import {DocumentType, SignatureType, SignerStatusType} from 'types/document';
import {useEvent} from 'hooks/eventHooks';
import {Event} from 'types/event';
import {Notification} from 'features/components/notification/Notification';
import {useSignature} from 'hooks/signatureHooks';
import {userSelectors} from 'store/reducers/user';
import {useNavigation} from 'hooks/navigateHooks';
import styles from './NewDocument.module.scss';
import {Layout} from '../../Layout';

export function NewDocument() {
  const {t} = useTranslation();
  const {createEvent, getEventMessage} = useEvent();
  const {
    showProgressBar,
    document,
    filesUploaded,
    handleCancel,
    uploadingProgress,
    handleFilesChange,
    handleBack,
    handleFileRemove,
    signAndShareButtonsState,
  } = useSignDocumentContainer();
  const dispatch = useAppDispatch();

  const {event} = useAppSelector(state => state.event);
  const {signDocument, cancel} = useSignDocument();
  const {navigate} = useNavigation();
  const [notifyInProgress, setNotifyInProgress] = useState(false);
  const {findMySignature} = useSignature();
  const activeRole = useAppSelector(state => userSelectors.getActiveRole(state.user));
  const activeRoleRef = useRef(activeRole?.roleId);

  const {deleteSigner, appendShareToSigners} = useDocument();
  const isDeniedToAddFiles =
    document?.type === DocumentType.ADOC &&
    !!document?.signatures.some(s => s.status === SignerStatusType.SIGNED);

  useEffect(() => {
    if (activeRole?.roleId !== activeRoleRef.current) {
      handleCancel();
    }
  }, [activeRole, handleCancel]);

  const navigateToDocumentView = useCallback(
    ({shared = false, signed = false}) => {
      if (document?.id) {
        if (signed) {
          createEvent(Event.DOCUMENT_SIGNED);
        }
        if (shared) {
          createEvent(Event.DOCUMENT_SHARED);
        }
        navigate(`/document/${document.id}`, {
          state: {isBackButtonDisabled: true, fromPath: '/new'},
        });
      }
    },
    [createEvent, document, navigate]
  );

  const handleShare = useCallback(() => {
    if (document?.id) {
      setNotifyInProgress(true);
      appendShareToSigners(document.id).then(() => {
        setNotifyInProgress(false);
        navigateToDocumentView({shared: true});
      });
    } else {
      navigateToDocumentView({shared: false});
    }
  }, [document, appendShareToSigners, navigateToDocumentView]);

  const handleSign = async () => {
    if (document) {
      dispatch(startSigning());
      signDocument(document.id).then(() => {
        dispatch(setIsModalOpen({isOpen: true, type: 'Signing', onClose: cancel}));
      });
    }
  };

  const handleRemoveSigner = (signatureId: string) => {
    if (document) {
      deleteSigner({id: document.id, signatureId});
    }
  };

  const handleOpenSignerModal = () => {
    if (document?.id) {
      dispatch(setIsModalOpen({isOpen: true, type: 'Signer', id: document.id}));
    }
  };

  const uploadSection = (() => {
    if (showProgressBar) {
      return (
        <div className={styles.progressBarWrapper}>
          <ProgressBar
            progress={uploadingProgress}
            className={styles.progressBarContainer}
            progressBarClassName={styles.progressBar}
            onCancel={handleCancel}
          />
        </div>
      );
    }

    return (
      !isDeniedToAddFiles && (
        <FileInputDrop
          className={styles.fileInputDrop}
          buttonName={t('signing.page.add.files')}
          onChange={handleFilesChange}
        />
      )
    );
  })();

  const filesSection = (() => {
    if (!filesUploaded) {
      return null;
    }

    if (document?.signatures) {
      return (
        <div>
          <FilesContainer files={document?.files || []} onFileRemove={handleFileRemove} />
          <div className={styles.signersContainer}>
            <Text variant="xLarge">{t('signing.page.signers.title')}</Text>
            <SignaturesTable
              className={styles.signaturesTable}
              signatures={document.signatures}
              cells={[
                SignatureCellType.signer,
                SignatureCellType.fin,
                SignatureCellType.role_and_date,
              ]}
              onRemove={handleRemoveSigner}
              isCreator={!!document.creator}
            />
            {document ? (
              <Button
                leftIcon={<ContactBookIcon />}
                variant="secondary"
                className={styles.addMoreSignersButton}
                onClick={handleOpenSignerModal}>
                {t('document.page.add.more.signers.button')}
              </Button>
            ) : null}
          </div>
        </div>
      );
    }
    return <FilesContainer files={document?.files || []} onFileRemove={handleFileRemove} />;
  })();

  const mySignature = findMySignature(document?.signatures);
  const myApprovalNeeded = mySignature?.type === SignatureType.APPROVAL;
  const declineEnabled = myApprovalNeeded && mySignature?.status === SignerStatusType.PENDING;
  const approveEnabled =
    myApprovalNeeded &&
    (mySignature?.status === SignerStatusType.PENDING ||
      mySignature?.status === SignerStatusType.DECLINED);

  const handleDecline = () => {
    dispatch(
      setIsModalOpen({isOpen: true, type: 'Comment', id: document?.id, documentAction: 'Decline'})
    );
  };

  const handleApprove = () => {
    dispatch(
      setIsModalOpen({isOpen: true, type: 'Comment', id: document?.id, documentAction: 'Approve'})
    );
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.uploadContainer}>
          {(event === Event.DOCUMENT_SIGNED ||
            event === Event.DOCUMENT_SHARED ||
            event === Event.SIGNER_ADDED ||
            event === Event.SIGNER_DELETED) && (
            <div className={styles.successMessage}>
              <Notification variant="success">
                <Text variant="large">{getEventMessage(event)}</Text>
              </Notification>
            </div>
          )}
          <Text variant="xLarge">{t('signing.page.title')}</Text>
          {uploadSection}
        </div>
        {filesSection}
        <div className={clsx(styles.controls, filesUploaded && styles.controlsHighlighted)}>
          <UnderlineButton
            leftIcon={<ArrowRightIcon />}
            className={clsx(!filesUploaded && styles.buttonHidden, styles.cancelButton)}
            onClick={handleBack}>
            {t('signing.page.back')}
          </UnderlineButton>
          <div className={styles.actionButtons}>
            <Tooltip placement="top" title={signAndShareButtonsState.shareButtonTooltip} arrow>
              <span />
              <Button
                rightIcon={<ArrowRightIcon />}
                disabled={
                  !document?.creator ||
                  signAndShareButtonsState.shareButtonDisabled ||
                  notifyInProgress
                }
                className={styles.shareButton}
                onClick={handleShare}>
                {t('signing.page.signers.sign.share')}
              </Button>
            </Tooltip>

            {myApprovalNeeded ? (
              <div className={styles.approveControls}>
                <Button
                  rightIcon={<ArrowRightIcon />}
                  className={styles.declineButton}
                  disabled={!declineEnabled}
                  onClick={handleDecline}>
                  {t('document.page.decline')}
                </Button>
                <Button
                  rightIcon={<ArrowRightIcon />}
                  className={styles.approveButton}
                  disabled={!approveEnabled}
                  onClick={handleApprove}>
                  {t('document.page.approve')}
                </Button>
              </div>
            ) : (
              <Tooltip placement="top" title={signAndShareButtonsState.signButtonTooltip} arrow>
                {/* Fixes bug where tooltip doesn't show on disabled buttons */}
                <span />
                <Button
                  rightIcon={<ArrowRightIcon />}
                  disabled={signAndShareButtonsState.signButtonDisabled || notifyInProgress}
                  className={styles.signButton}
                  onClick={handleSign}>
                  {t('document.page.add.signature.button')}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
