export const COLORS = {
  white: '#ffffff',
  darkBlue: '#1a2741',
  green: '#07875E',
  ivory: '#28AB82',
  lightBlue: '#D9EDF2',
  warmWhite: '#D5EDE7',
  error: '#FCE1DF',
  transparent: 'transparent',
};
