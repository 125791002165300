import {ReactElement, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {setAppSettings} from 'store/reducers/settings';

type Props = {
  children: ReactElement;
};

export function AppSettings({children}: Props) {
  const {settings} = useAppSelector(state => state.settings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!settings) {
      fetch('/settings.json').then(async data => {
        dispatch(setAppSettings(await data.json()));
      });
    }
  }, [dispatch, settings]);

  return children;
}
