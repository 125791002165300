import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from 'store';
import {setActiveLanguage} from 'store/reducers/translation';
import {AllowedLanguages} from 'types/translation';
import {useAppendPreferenceMutation} from 'store/api/userPreferences';
import {PreferenceName} from 'types/userPreferences';
import {setCookie} from 'utils/cookie';

export const defaultSelectedLanguageCode: AllowedLanguages = 'az';

export const languages: {code: AllowedLanguages; name: string; activeName: string}[] = [
  {code: 'az', name: 'AZ', activeName: 'header.language.in.azerbaijan'},
  // {code: 'ru', name: 'header.language.russian', activeName: 'header.language.in.russian'},
  {code: 'en', name: 'EN', activeName: 'header.language.in.english'},
];

export const allowedLanguageCodes: AllowedLanguages[] = languages.map(({code}) => code);

export const handleLink = (to: string, lang: AllowedLanguages | null) => {
  const isDefaultLanguage = lang === defaultSelectedLanguageCode;
  const hasAllowedLanguage = lang && allowedLanguageCodes.includes(lang);
  let langPath = '';
  if (hasAllowedLanguage) {
    langPath = isDefaultLanguage ? '' : `/${lang}`;
  }
  return `${langPath}${to}`;
};

export const useLanguageChange = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pathname, hash, search} = useLocation();
  const params = useParams();
  const [appendPreferenceMutation] = useAppendPreferenceMutation();

  const getPath = (fullPath: string) => {
    return Object.entries(params).reduce(
      (path, param) => (param[0] !== 'lang' ? path : path?.replace(`/${param[1]}`, '')),
      fullPath
    );
  };

  const generateLink = (lang: AllowedLanguages) => {
    const currentPath = `${pathname}${hash}${search}`;
    const toPath = getPath(currentPath);
    return handleLink(toPath || '', lang);
  };

  const handleLanguageChange = (code: AllowedLanguages) => {
    dispatch(setActiveLanguage({activeLanguage: code}));
    setCookie('language', code, 365);
    appendPreferenceMutation({name: PreferenceName.LANGUAGE, value: code});
    const to = generateLink(code);
    navigate(to);
  };

  return {handleLanguageChange};
};
