import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ErrorDto, StoreState} from 'types/shared';
import {SigningResponse} from 'types/signature';

export interface SignatureStoreState extends StoreState {
  isSigned: boolean;
  signingTimeRemaining: number;
  signingResponse: SigningResponse | null;
}

const initialState: SignatureStoreState = {
  isSigned: false,
  signingTimeRemaining: 120,
  signingResponse: null,
  isLoading: false,
  errors: [],
};

const signatureReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startSigning(state) {
      state.isLoading = true;
      state.isSigned = false;
      state.signingResponse = null;
    },
    decreaseSigningTimeRemaining(state) {
      if (state.signingTimeRemaining !== 0) {
        state.signingTimeRemaining -= 1;
      }
    },
    signingSuccessful(state, action) {
      state.isSigned = true;
      state.isLoading = false;
      state.signingResponse = action.payload.signingResponse;
      state.signingTimeRemaining = 120;
    },
    setSigningResponse(state, action) {
      state.signingResponse = action.payload.signingResponse;
    },
    setSigningError(state, action: PayloadAction<ErrorDto[]>) {
      state.isLoading = false;
      state.errors = action.payload;
      state.isSigned = false;
      state.signingResponse = null;
      state.signingTimeRemaining = 120;
    },
    resetSigning() {
      return initialState;
    },
  },
});

export default signatureReducer.reducer;

export const {
  startSigning,
  decreaseSigningTimeRemaining,
  signingSuccessful,
  setSigningResponse,
  setSigningError,
  resetSigning,
} = signatureReducer.actions;
