import {useEffect, useMemo, useState} from 'react';
import debounce from 'lodash.debounce';
import {Document} from 'types/document';
import {useAppSelector} from 'store';
import {useDocument} from './documentsHooks';

const containerNameRegex = /[\\/.:*?"<>#{}%~&()]|[$]$|^[$]/;

export const useDocumentName = (document?: Document | null) => {
  const [documentName, setDocumentName] = useState('');
  const {errors} = useAppSelector(state => state.document);
  const {changeDocumentName} = useDocument();

  const removeFileExtension = (filename: string): string => filename.replace(/(\.pdf|\.adoc)$/, '');

  useEffect(() => {
    if (document) {
      setDocumentName(removeFileExtension(document.name));
    }
  }, [document]);

  const debouncedContainerNameChange = useMemo(
    () =>
      debounce(containerNameValue => {
        if (document) {
          changeDocumentName(document.id, containerNameValue);
        }
      }, 1000),
    [changeDocumentName, document]
  );

  const onDocumentNameChange = (value: string) => {
    if (!containerNameRegex.test(value)) {
      debouncedContainerNameChange(value);
      setDocumentName(value);
    }
  };

  const nameError = errors.find(err => err.field === 'name');

  return {documentName, onDocumentNameChange, nameError};
};
