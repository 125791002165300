import {useTranslation} from 'hooks/translationHooks';
import {Text} from 'features/components/text/Text';
import {ReactComponent as SortingArrowDownIcon} from 'assets/icons/SortingArrowDown.svg';
import {ReactComponent as SortingArrowUpIcon} from 'assets/icons/SortingArrowUp.svg';
import clsx from 'clsx';
import styles from './DocumentsSorting.module.scss';

type SortingProps = {
  handleSort(): void;
  title: string;
  hide: boolean;
  className?: string;
};

function SortingIcon() {
  return (
    <div className={styles.sortingIconContainer}>
      <SortingArrowUpIcon />
      <SortingArrowDownIcon />
    </div>
  );
}

export function Sort({title, handleSort, hide, className}: SortingProps) {
  const {t} = useTranslation();

  if (!hide) return null;

  return (
    <div className={clsx(styles.documentsSorting, className)}>
      <div className={styles.itemContainer} onClick={handleSort}>
        <Text variant="xSmall">{t(title)}</Text>
        <SortingIcon />
      </div>
    </div>
  );
}
