import clsx from 'clsx';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {useEffect, useState} from 'react';
import {useTranslation} from 'hooks/translationHooks';
import {useScreenSize} from 'hooks/useScreenSize';
import {Button} from '../button/Button';

import {Input} from '../input/Input';
import {Text} from '../text/Text';

import styles from './Pagination.module.scss';

type Props = {
  className?: string;
  pageNumber: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export function Pagination({className, pageNumber, totalPages, onPageChange}: Props) {
  const [inputValue, setInputValue] = useState(pageNumber);
  const {t} = useTranslation();
  const {isSmall} = useScreenSize();

  useEffect(() => {
    setInputValue(pageNumber);
  }, [setInputValue, pageNumber]);

  const handleNextClick = () => {
    if (pageNumber + 1 > totalPages) {
      return;
    }
    onPageChange(pageNumber + 1);
    setInputValue(pageNumber + 1);
  };

  const handlePreviousClick = () => {
    if (pageNumber - 1 < 1) {
      return;
    }
    onPageChange(pageNumber - 1);
    setInputValue(pageNumber - 1);
  };

  const handlePageChange = (value: string) => {
    const newPage = +value;
    if (newPage > totalPages || Number.isNaN(newPage)) {
      return;
    }

    setInputValue(newPage);
  };

  const handleEnterPress = () => {
    if (inputValue === 0) {
      setInputValue(1);
      onPageChange(1);
      return;
    }
    onPageChange(inputValue);
  };

  if (isSmall) {
    return (
      <div className={clsx(styles.mobileContainer, className)}>
        <div className={styles.mobileButtons}>
          <Button
            onClick={handlePreviousClick}
            leftIcon={<ArrowRightIcon />}
            className={styles.previousButton}
            disabled={pageNumber === 1}>
            {t('pagination.previous')}
          </Button>
          <Button
            onClick={handleNextClick}
            rightIcon={<ArrowRightIcon />}
            className={styles.nextButton}
            disabled={pageNumber === totalPages}>
            {t('pagination.next')}
          </Button>
        </div>
        <div className={styles.pages}>
          <Input
            value={inputValue.toString()}
            onChange={handlePageChange}
            className={styles.input}
            onEnterPress={handleEnterPress}
          />
          <Text variant="small">
            {t('pagination.of')} {totalPages}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.container, className)}>
      <Button
        onClick={handlePreviousClick}
        leftIcon={<ArrowRightIcon />}
        className={styles.previousButton}
        disabled={pageNumber === 1}>
        {t('pagination.previous')}
      </Button>
      <div className={styles.pages}>
        <Input
          value={inputValue.toString()}
          onChange={handlePageChange}
          className={styles.input}
          onEnterPress={handleEnterPress}
        />
        <Text variant="small">
          {t('pagination.of')} {totalPages}
        </Text>
      </div>
      <Button
        onClick={handleNextClick}
        rightIcon={<ArrowRightIcon />}
        className={styles.nextButton}
        disabled={pageNumber === totalPages}>
        {t('pagination.next')}
      </Button>
    </div>
  );
}
