import {createSlice} from '@reduxjs/toolkit';

export type AppSettings = {
  backendApiUrl: string;
};

export interface SettingsStoreProps {
  settings: AppSettings | null;
}

const initialState: SettingsStoreProps = {
  settings: null,
};

const settingsReducer = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAppSettings(state, action) {
      state.settings = action.payload;
    },
  },
});

export default settingsReducer.reducer;

export const {setAppSettings} = settingsReducer.actions;
