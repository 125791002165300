import {RolePicker} from 'features/header/components/RolePicker';
import {LanguagePicker} from 'features/header/components/LanguagePicker';
import {ReactComponent as MenuIcon} from 'assets/icons/MenuIcon.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import {useAppDispatch} from 'store';
import {clearDocument} from 'store/reducers/document';
import {Logos} from 'features/header/components/Logos';
import styles from './Header.module.scss';

type Props = {
  mobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
};

export function Header({mobileMenuOpen, toggleMobileMenu}: Props) {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.container}>
      <Logos
        homeLink="/new"
        onClickHome={() => {
          dispatch(clearDocument());
        }}
      />
      <div className={styles.controls}>
        <LanguagePicker className={styles.languagePicker} />
        <RolePicker />
        <button type="button" className={styles.menuIcon} onClick={toggleMobileMenu}>
          {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>
    </div>
  );
}
