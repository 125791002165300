import {SigningRequest, SigningResponse} from 'types/signature';
import {apiSlice} from './apiSlice';

export const APISignatureSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    signDocument: builder.mutation<SigningResponse, SigningRequest>({
      query: ({documentId}) => ({
        url: `/sign/mobileid`,
        method: 'POST',
        body: {
          containerId: documentId,
        },
      }),
    }),
    cancelSigning: builder.mutation<void, void>({
      query: () => ({
        url: `/sign/mobileid`,
        method: 'DELETE',
      }),
    }),
    pollSigningInfo: builder.mutation<SigningResponse, void>({
      query: () => ({
        url: `/sign/mobileid`,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {usePollSigningInfoMutation, useSignDocumentMutation, useCancelSigningMutation} =
  APISignatureSlice;
