import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {useTranslation} from 'hooks/translationHooks';
import {SendRequest} from './components/send-request/SendRequest';
import {NeedHelp} from './components/need-help/NeedHelp';
import styles from './Contact.module.scss';

/* const data = {
  mobile: '35235',
  email: 'some@gmail.com',
  offices: [
    {
      city: 'Baku',
      address: 'Baku',
      email: 'baku0@gmail.com',
    },
    {
      city: 'Tallinn',
      address: 'Baku',
      email: 'baku1@gmail.com',
    },
    {
      city: 'Milano',
      address: 'Baku',
      email: 'baku2@gmail.com',
    },
  ],
}; */

export function Contact(): JSX.Element {
  // const {offices} = data || {};

  const {t} = useTranslation();

  return (
    <div className={styles.contact}>
      <div className={styles.headerContainer}>
        <Text variant="largeHeading" textAlign="center">
          {t('contact.page.title')}
        </Text>
      </div>
      <Spacer height={96} />

      <div className={styles.bodyContainer}>
        <div className={styles.blocks}>
          <div className={styles.block}>
            <NeedHelp />
            <Spacer height={48} />
            <SendRequest />
          </div>
          <div className={styles.block}>
            {/* <ForCommercial /> */}
            {/*
               <Spacer height={48} />
               <Offices offices={offices} />
               */}
          </div>
        </div>
      </div>
      <Spacer height={96} />
    </div>
  );
}
