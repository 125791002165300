import {useAppDispatch} from 'store';
import {Event} from 'types/event';
import {addEvent, clearEvent} from 'store/reducers/event';
import {useTranslation} from 'hooks/translationHooks';

export const useEvent = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const createEvent = (event: Event, timeout?: number) => {
    dispatch(addEvent(event));

    setTimeout(() => {
      dispatch(clearEvent(event));
    }, timeout || 5000);
  };

  const getEventMessage = (event: Event) => {
    switch (event) {
      case Event.DOCUMENT_ADDED:
      case Event.DOCUMENT_SHARED:
      case Event.DOCUMENT_SIGNED:
      case Event.DOCUMENT_DELETED:
        return t(`document.page.message.${event.toLowerCase()}`);
      case Event.CONTACT_ADDED:
      case Event.CONTACT_DELETED:
        return t(`settings.page.contacts.${event.toLowerCase()}`);
      case Event.SIGNER_ADDED:
      case Event.SIGNER_DELETED:
        return t(`contacts.page.signer.${event.toLowerCase()}`);
      default:
        return null;
    }
  };

  return {createEvent, getEventMessage};
};
