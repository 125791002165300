import {LanguagePicker} from 'features/header/components/LanguagePicker';
import {RolePicker} from 'features/header/components/RolePicker';
import clsx from 'clsx';
import {Text} from 'features/components/text/Text';
import {useState} from 'react';
import {useTranslation} from 'hooks/translationHooks';
import {ReactComponent as BurgerMenu} from 'assets/icons/BurgerMenu.svg';
import {ReactComponent as BurgerMenuOpen} from 'assets/icons/BurgerMenuClose.svg';
import {Logos} from 'features/header/components/Logos';
import styles from './MainHeader.module.scss';
import {Link} from '../components/link/Link';

export function MainHeader(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();
  const handleClickBurger = () => setIsOpen(prev => !prev);
  const handleCloseBurger = () => setIsOpen(false);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.container}>
          <Logos homeLink="/" />
          <div className={styles.menu}>
            <p className={clsx(styles.menuItem, styles.menuItemDisabled)}>
              {t('header.link.pricing')}
            </p>
            <Link className={styles.menuItem} to="/features">
              {t('header.link.features')}
            </Link>
            <Link className={styles.menuItem} to="/contact">
              {t('header.link.contact')}
            </Link>
          </div>
          <div className={styles.actions}>
            <LanguagePicker />
            <RolePicker />
            <div className={styles.burgerMenu}>
              <div className={styles.burger} onClick={handleClickBurger}>
                <div className={clsx(styles.burgerMenuIcon, isOpen && styles.burgerIconHide)}>
                  <BurgerMenu />
                </div>
                <div className={clsx(styles.burgerMenuIcon, !isOpen && styles.burgerIconHide)}>
                  <BurgerMenuOpen />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(isOpen && styles.open, styles.burgerMenuContainer)}>
        <div className={styles.burgerMenuItems}>
          <Text variant="large">{t('header.link.pricing')}</Text>
          <Link to="/features" onClick={handleCloseBurger}>
            <Text variant="large">{t('header.link.features')}</Text>
          </Link>
          <Link to="/contact" onClick={handleCloseBurger}>
            <Text variant="large">{t('header.link.contact')}</Text>
          </Link>
          <div onClick={handleCloseBurger}>
            <LanguagePicker minified />
          </div>
        </div>
      </div>
    </>
  );
}
