import {LegalType} from './contact';

export enum SignerStatusType {
  SIGNED = 'SIGNED',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

export enum DocumentType {
  ADOC = 'ADOC',
  PDF = 'PDF',
}

export enum PersonalType {
  PRIVATE = 'PRIVATE',
  LEGAL = 'LEGAL',
  LEGAL_REP = 'LEGAL_REP',
}

export enum SignatureType {
  SIGNATURE = 'SIGNATURE',
  APPROVAL = 'APPROVAL',
}

export type DocumentFile = {
  id: number;
  fileName: string;
  fileSize: number;
  mediaType: string;
};

export type Signature = {
  id: number;
  personType: PersonalType;
  code: string;
  country: string;
  name: string;
  representativeCode: string;
  representativeCountry: string;
  representativeName: string;
  type: SignatureType;
  status: SignerStatusType;
  notificationLastSentAt: string | null;
  email: string;
  signedAt: string | null;
  createdAt: string | null;
  comment: string;
  valid: boolean;
  verificationErrors: {code: number; message: string}[];
  certificateInfo: CertificateInfo;
};

export type CertificateInfo = {
  givenName: string;
  surName: string;
  fin: string;
  organizationName?: string;
  organizationCode?: string;
  organizatonCountryCode?: string;
  role?: string;
};

export type Sort = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export type Pageable = {
  offset: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
};

export enum DocumentFilter {
  ALL = 'ALL',
  SHARED_TO_ME = 'SHARED_TO_ME',
  MY_PENDING = 'MY_PENDING',
  CREATED_BY_ME = 'CREATED_BY_ME',
}

export type PaginationDocumentRequest = {
  filter: DocumentFilter;
  searchTerm?: string;
  page?: number;
  size?: number;
  sort?: string;
};

export type PaginationDocuments = {
  totalPages: number;
  totalElements: number;
  size: number;
  content: Document[];
  number: number;
  sort: Sort;
  numberOfElements: number;
  pageable: Pageable;
  first: boolean;
  last: boolean;
  empty: boolean;
};

export type Document = {
  id: string;
  type: DocumentType;
  creator: boolean;
  name: string;
  availableConversionTypes: string[];
  files: DocumentFile[];
  signatures: Signature[];
};

export type Signer = {
  personType: LegalType;
  code: string;
  country: string;
  name: string;
  representativeCode?: string;
  representativeCountry?: string;
  representativeName?: string;
  type: SignatureType;
  email: string;
};

export type SignerRequest = {
  id: string;
  signer: Signer;
};

export type SignerUpdateRequest = {
  id: string;
  signatureId: number;
  signer: Signer;
};

export type SignerDeleteRequest = {
  id: string;
  signatureId: string;
};

export type DocumentRequest = {
  files: File[];
};

export type AppendDocumentRequest = {
  id: string;
  files: File[];
};

export type DeleteFileRequest = {
  id: string;
  fileId: number;
};

export type DocumentConvertRequest = {
  id: string;
  type: DocumentType;
};

export type ChangeDocumentNameRequest = {
  id: string;
  name: string;
};

export type DocumentDeleteRequest = {
  id: string;
};

export type DocumentsDeleteRequest = {
  ids: string[];
};

export type DocumentDeclineApproveRequest = {
  id: string;
  comment: string;
};
