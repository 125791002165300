import {Form, Input, message} from 'antd';
import {Text} from 'features/components/text/Text';
import {Spacer} from 'features/components/Spacer';
import {Button} from 'features/components/button/Button';
import {ReactComponent as ArrowRightIcon} from 'assets/icons/ArrowRightIcon.svg';
import {useTranslation} from 'hooks/translationHooks';
import {useContactUs} from 'hooks/contactUsHooks';
import {ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState} from 'react';
import {ErrorDto} from 'types/shared';
import clsx from 'clsx';
import {formatPhoneNumber} from 'utils/formatPhoneNumber';
import styles from './SendRequest.module.scss';

type FormElementProps = {
  title: string;
  fieldName: string;
  isTextArea?: boolean;
  error?: ErrorDto;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  maxLength?: number;
  value?: string;
};

function FormElement({
  title,
  value,
  fieldName,
  isTextArea,
  error,
  maxLength,
  onChange,
}: FormElementProps) {
  const {Item} = Form || {};
  const {TextArea} = Input || {};

  const renderInput = () => {
    if (isTextArea) {
      return <TextArea className={clsx(styles.textArea, error && styles.hasError)} />;
    }

    if (fieldName === 'phone') {
      return (
        <>
          <Input
            value={value}
            className={clsx(styles.input, styles.phoneInput, error && styles.hasError)}
            onChange={onChange}
            maxLength={maxLength}
          />
          <span className={styles.phoneNumberPrefix}>+994</span>
        </>
      );
    }

    return (
      <Input
        className={clsx(styles.input, error && styles.hasError)}
        onChange={onChange}
        maxLength={maxLength}
      />
    );
  };

  return (
    <>
      <Text variant="smallBold">{title}</Text>
      <Spacer height={8} />
      <Item
        className={styles.formItem}
        name={fieldName}
        validateStatus={error ? 'error' : ''}
        help={error ? error.message : ''}>
        {renderInput()}
      </Item>
    </>
  );
}

export function SendRequest(): JSX.Element {
  const [form] = Form.useForm();
  const {send} = useContactUs();
  const [errors, setErrors] = useState<ErrorDto[]>([]);
  const [phoneNumberValue, setPhoneNumberValue] = useState('');

  const {t} = useTranslation();

  const handleSend = () => {
    setErrors([]);
    send(form.getFieldsValue())
      .unwrap()
      .then(() => {
        message.success(t('contact.page.form.success'));
        form.resetFields();
        setPhoneNumberValue('');
      })
      .catch(e => {
        message.error(t('contact.page.form.fail'));
        setErrors(e.data.errors);
      });
  };

  const handlePhoneNumberFormat = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const formattedValue = formatPhoneNumber(e.target.value);
      form.setFieldValue('phone', formattedValue);
      setPhoneNumberValue(formattedValue);
      return formattedValue;
    },
    [form]
  );

  const formValues = useMemo(
    () => [
      {title: 'contact.page.form.name', fieldName: 'name'},
      {title: 'contact.page.form.email', fieldName: 'email'},
      {
        title: 'contact.page.form.phone',
        fieldName: 'phone',
        maxLength: 15,
        value: phoneNumberValue,
        onChange: handlePhoneNumberFormat,
      },
      {title: 'contact.page.form.message', fieldName: 'message', isTextArea: true},
    ],
    [handlePhoneNumberFormat, phoneNumberValue]
  );

  return (
    <div className={styles.sendRequest}>
      <Text variant="xLarge">{t('contact.page.form.title')}</Text>
      <Spacer height={16} />
      <Form form={form}>
        {formValues.map(({title, value, fieldName, isTextArea, maxLength, onChange}) => (
          <>
            <FormElement
              title={t(title)}
              fieldName={fieldName}
              value={value}
              onChange={onChange}
              maxLength={maxLength}
              isTextArea={isTextArea}
              error={errors.find(e => e.field === fieldName)}
            />
            <Spacer height={16} />
          </>
        ))}
        <Form.Item className={styles.sendButtonContainer}>
          <Button onClick={handleSend} rightIcon={<ArrowRightIcon />}>
            {t('contact.page.form.send.button')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
