import {useTranslation} from 'hooks/translationHooks';
import {DocumentFile} from 'types/document';
import {ReactComponent as DownloadIcon} from 'assets/icons/DownloadIcon.svg';
import {useDocument} from 'hooks/documentsHooks';
import {Button} from 'features/components/button/Button';
import {Text} from '../text/Text';
import {InfoBox} from '../info-box/InfoBox';

import styles from './FilesList.module.scss';

type Props = {
  className?: string;
  isPdfView: boolean;
  files: DocumentFile[];
  onFileRemove: (id: number) => void;
  isRemovable?: boolean;
};

export function FilesList({className, files, isPdfView, onFileRemove, isRemovable = true}: Props) {
  const {t} = useTranslation();
  const {getFileDownloadLink} = useDocument();

  return (
    <div className={className}>
      <Text variant="smallBold" className={styles.filesLabel}>
        {isPdfView ? t('files.section.file') : t('files.section.files.in.documents')}
      </Text>
      {files.map(file => (
        <InfoBox
          className={styles.infoBox}
          key={file.id}
          icon={<DownloadIcon />}
          text={file.fileName}
          downloadLink={getFileDownloadLink(file.id)}
          onCloseIconClick={() => onFileRemove(file.id)}
          isRemovable={isRemovable}
        />
      ))}
      {!isPdfView && files.length > 1 ? (
        <Button
          leftIcon={<DownloadIcon />}
          variant="secondary"
          className={styles.actionButton}
          onClick={e => {
            e.preventDefault();
            files?.forEach(file => {
              window.open(getFileDownloadLink(file.id));
            });
          }}>
          {`${t('document.page.download.all')} (${files?.length || ''})`}
        </Button>
      ) : null}
    </div>
  );
}
