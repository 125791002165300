export type StoreState = {
  isLoading: boolean;
  errors: ErrorDto[];
};

export type ErrorDto = {
  code: number;
  message: string;
  field?: string;
  extensions?: {
    [key: string]: string;
  };
};

export enum ResponseStatus {
  OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION',
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  SIGNATURE_CREATED = 'SIGNATURE_CREATED',
  NOT_VALID = 'NOT_VALID',
  EXPIRED_TRANSACTION = 'EXPIRED_TRANSACTION',
  USER_CANCEL = 'USER_CANCEL',
  MID_NOT_READY = 'MID_NOT_READY',
  SENDING_ERROR = 'SENDING_ERROR',
  SIM_ERROR = 'SIM_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
}
