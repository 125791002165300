import {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {setIsModalOpen} from 'store/reducers/modal';
import {ReactComponent as CloseIcon} from 'assets/icons/CloseIcon.svg';
import {LoginForm} from 'features/components/modal/login/LoginForm';
import {SigningModal} from 'features/components/modal/signing/SigningModal';
import Modal from 'react-modal';
import {setContactsError} from 'store/reducers/contact';
import clsx from 'clsx';
import {ContactModal} from './contact/ContactModal';
import styles from './BaseModal.module.scss';
import {ContactModalControls} from './contact/ContactModalControls';
import {SignerModal} from './signer/SignerModal';
import {CommentModal} from './comment/CommentModal';
import {CommentModalControls} from './comment/CommentModalControls';
import {ConfirmationModal} from './confirmation/ConfirmationModal';
import {ConfirmationModalControls} from './confirmation/ConfirmationModalControls';

Modal.setAppElement('#root');

export function BaseModal(): JSX.Element {
  const {isOpen, type, id, onClose, documentAction, onSubmit, confirmationText} = useAppSelector(
    state => state.modal
  );
  const [contentLoaded, setContentLoaded] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      if (
        type &&
        ['Login', 'Contact', 'Comment', 'Confirmation', 'OldPortalNotice'].includes(type)
      ) {
        setContentLoaded(true);
      }
    } else {
      setContentLoaded(false);
    }
  }, [isOpen, type]);

  const handleContentLoaded = (val: boolean) => setContentLoaded(val);
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(setIsModalOpen({isOpen: false}));

    if (type === 'Contact') {
      dispatch(setContactsError([]));
    }
  };

  const ModalComponent = useMemo((): JSX.Element | null => {
    switch (type) {
      case 'Login':
        return <LoginForm />;
      case 'Signing':
        return <SigningModal onLoaded={handleContentLoaded} />;
      case 'Contact':
        return <ContactModal contactId={id} />;
      case 'Signer':
        return <SignerModal documentId={id} onLoaded={handleContentLoaded} />;
      case 'Comment':
        return <CommentModal signatureId={id} documentAction={documentAction} />;
      case 'Confirmation':
        return <ConfirmationModal confirmationText={confirmationText} />;
      default:
        return null;
    }
  }, [type, id, documentAction, confirmationText]);

  return (
    <Modal
      className={clsx(styles.modalContainer, !contentLoaded && styles.hidden)}
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName={styles.modalOverlay}>
      <div className={styles.content}>
        {ModalComponent}
        <button type="button" onClick={handleClose} className={styles.closeButton}>
          <CloseIcon />
        </button>
      </div>
      <ContactModalControls onClose={handleClose} modalType={type} id={id} />
      <CommentModalControls
        onClose={handleClose}
        modalType={type}
        documentAction={documentAction}
      />
      <ConfirmationModalControls onClose={handleClose} modalType={type} onSubmit={onSubmit} />
    </Modal>
  );
}
