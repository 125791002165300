import {DocumentFile, DocumentType, Signature} from 'types/document';
import {useAppSelector} from 'store';
import {FilesList} from 'features/components/files-list/FilesList';
import {FilesContainer} from 'features/components/files-container/FilesContainer';
import {SignaturesSection} from './SignaturesSection';

import styles from './FilesSection.module.scss';

type Props = {
  files: DocumentFile[];
  signatures: Signature[];
  onFileRemove: (id: number) => void;
  isCreator: boolean;
};

export function FilesSection({files, onFileRemove, signatures, isCreator}: Props) {
  const {document} = useAppSelector(state => state.document);

  const isPdfView = document?.type === DocumentType.PDF;

  const hasSignatures = !!signatures.length;

  if (hasSignatures && !isPdfView) {
    return (
      <div>
        <FilesList
          className={styles.filesList}
          isPdfView={isPdfView}
          isRemovable={!hasSignatures}
          files={files}
          onFileRemove={id => onFileRemove(id)}
        />
        <SignaturesSection signatures={signatures} isCreator={isCreator} />
      </div>
    );
  }

  return (
    <div>
      <FilesContainer
        files={files}
        onFileRemove={id => onFileRemove(id)}
        isFilesRemovable={!hasSignatures}
      />
      {hasSignatures ? <SignaturesSection signatures={signatures} isCreator={isCreator} /> : null}
    </div>
  );
}
