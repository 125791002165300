import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {setLoading} from 'store/reducers/contact';
import {useContactUsMutation} from 'store/api/contactUs';
import {Feedback} from 'types/contactUs';

export const useContactUs = () => {
  const [sendContactUs] = useContactUsMutation();
  const dispatch = useDispatch();

  const send = useCallback(
    (body: Feedback) => {
      dispatch(setLoading());

      return sendContactUs({
        ...body,
      });
    },
    [dispatch, sendContactUs]
  );

  return {
    send,
  };
};
