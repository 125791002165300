import {Spacer} from 'features/components/Spacer';
import {Text} from 'features/components/text/Text';
import {useTranslation} from 'hooks/translationHooks';
import styles from './Cookies.module.scss';

export function Cookies(): JSX.Element {
  const {t} = useTranslation();

  return (
    <div className={styles.cookies}>
      <div className={styles.container}>
        <Spacer height={96} />
        <Text variant="largeHeading" textAlign="center">
          {t('cookies.page.title')}
        </Text>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.information.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.information.content.block1')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.information.content.block2')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.purpose.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.purpose.content')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.purpose.content.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet3')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet4')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet5')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.purpose.content.bullet6')}</Text>
            </li>
          </ul>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.types.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.types.content1.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('cookies.page.types.content1.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.types.content1.bullet2')}</Text>
            </li>
          </ul>
          <Spacer height={24} />
          <Text variant="medium">{t('cookies.page.types.content.block1')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.types.content.block2')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.types.content2.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('cookies.page.types.content2.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.types.content2.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.types.content2.bullet3')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.types.content2.bullet4')}</Text>
            </li>
          </ul>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.third_party.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.third_party.content.block1')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.third_party.content.block2')}</Text>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.used.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.used.content.header')}</Text>
          <Spacer height={24} />
          <ul>
            <li>
              <Text variant="medium">{t('cookies.page.used.content.bullet1')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.used.content.bullet2')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.used.content.bullet3')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.used.content.bullet4')}</Text>
            </li>
            <li>
              <Text variant="medium">{t('cookies.page.used.content.bullet5')}</Text>
            </li>
          </ul>
        </div>
        <div>
          <Spacer height={96} />
          <Text variant="xLarge">{t('cookies.page.management.title')}</Text>
          <Spacer height={48} />
          <Text variant="medium">{t('cookies.page.management.content.block1')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.management.content.block2')}</Text>
          <Spacer height={5} />
          <Text variant="medium">{t('cookies.page.management.content.block3')}</Text>
        </div>
        <Spacer height={96} />
      </div>
    </div>
  );
}
