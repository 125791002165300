import {useGetUserInfo} from 'hooks/loginHooks';
import {ReactElement, useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from 'store';
import {resetStore} from 'store/reducers';
import {setInitialUserLoadingDone} from 'store/reducers/user';

type Props = {
  children: ReactElement;
};

const SESSION_LENGTH_IN_MINUTES = 30;

export function AuthGuard({children}: Props) {
  const {userInfo, isInitialLoadingDone} = useAppSelector(state => state.user);
  const {fetchUser} = useGetUserInfo();
  const dispatch = useAppDispatch();
  const userInfoRef = useRef(userInfo);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    intervalId.current = null;
  };

  const startTimer = () => {
    resetTimer();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    intervalId.current = setInterval(navigateToHome, SESSION_LENGTH_IN_MINUTES * 60 * 1000);
  };

  const navigateToHome = () => {
    if (userInfoRef.current) {
      fetchUser()
        .then(() => startTimer())
        .catch(() => dispatch(resetStore()));
    }
  };

  useEffect(() => {
    startTimer();
    window.addEventListener('focus', navigateToHome);

    return () => {
      window.removeEventListener('focus', navigateToHome);
      resetTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUser().finally(() => dispatch(setInitialUserLoadingDone()));
  }, [dispatch, fetchUser]);

  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  return isInitialLoadingDone ? children : null;
}
