import {NavigateOptions, useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store';
import {defaultSelectedLanguageCode} from 'features/header/components/LanguagePicker.utils';
import {useCallback} from 'react';

export const useNavigation = () => {
  const {activeLanguage} = useAppSelector(state => state.translation);
  const nav = useNavigate();

  const navigate = useCallback(
    (path: string | number, options?: NavigateOptions) => {
      if (typeof path === 'number') {
        nav(path);
      } else if (
        activeLanguage !== defaultSelectedLanguageCode &&
        !path.startsWith(`/${activeLanguage}`)
      ) {
        nav(`/${activeLanguage}${path}`, options);
      } else {
        nav(path, options);
      }
    },
    [activeLanguage, nav]
  );

  return {navigate};
};
