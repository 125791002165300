import {ReactNode, useRef, useState} from 'react';
import {ReactComponent as ChevronDownIcon} from 'assets/icons/ChevronDownIcon.svg';

import clsx from 'clsx';
import styles from './Accordion.module.scss';

export type Item = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  title: ReactNode;
  content: ReactNode;
};

function AccordionItem({content, title}: Item) {
  const [isOpen, setIsOpen] = useState(false);
  const contentEl = useRef<HTMLDivElement | null>(null);

  return (
    <div className={clsx(styles.accordionItem, isOpen && styles.accordionItemOpen)}>
      <button type="button" className={styles.button} onClick={() => setIsOpen(prev => !prev)}>
        {title}
        <ChevronDownIcon className={clsx(styles.control, isOpen && styles.controlOpen)} />
      </button>

      <div
        ref={contentEl}
        className={styles.answerWrapper}
        style={{height: isOpen ? contentEl.current?.scrollHeight : 0}}>
        <div className={styles.answer}>{content}</div>
      </div>
    </div>
  );
}

type Props = {
  className?: string;
  items: Item[];
};

export function Accordion({className, items}: Props) {
  return (
    <div className={clsx(styles.container, className)}>
      {items.map(item => (
        <AccordionItem key={item.id} {...item} />
      ))}
    </div>
  );
}
