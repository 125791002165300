import {ReactNode} from 'react';
import clsx from 'clsx';

import styles from './RoundButton.module.scss';

type Props = {
  className?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  children?: string;
  onClick?: () => void;
};

export function RoundButton({className, leftIcon, children, rightIcon, onClick}: Props) {
  return (
    <button type="button" className={clsx(styles.roundButton, className)} onClick={onClick}>
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </button>
  );
}
