import {Text} from 'features/components/text/Text';
import styles from 'pages/features/Features.module.scss';
import {useTranslation} from 'hooks/translationHooks';
import {ReactComponent as FileIcon} from 'assets/icons/FileIcon.svg';
import {ReactComponent as SecuredIcon} from 'assets/icons/SecuredIcon.svg';
import {ReactComponent as SignatureVerificationIcon} from 'assets/icons/SignatureVerificationIcon.svg';
import {ReactComponent as ListIcon} from 'assets/icons/ListIcon.svg';
import {ReactComponent as ContactBookIcon} from 'assets/icons/ContactBookIcon.svg';
import {ReactComponent as PaperAirplaneIcon} from 'assets/icons/PaperAirplaneIcon.svg';
import {ReactComponent as ApproveIcon} from 'assets/icons/ApproveIcon.svg';
import {ReactComponent as BlankDocumentIcon} from 'assets/icons/BlankDocumentIcon.svg';
import {ReactComponent as BellIcon} from 'assets/icons/BellIcon.svg';
import {ReactComponent as AuditIcon} from 'assets/icons/AuditIcon.svg';
import {ReactComponent as GearIcon} from 'assets/icons/GearIcon.svg';
import {ReactComponent as LockIcon} from 'assets/icons/LockIcon.svg';
import FeaturesImg from 'assets/images/features-bg-large.png';
import {Image} from 'antd';

export function Features(): JSX.Element {
  const {t} = useTranslation();

  return (
    <div className={styles.features}>
      <Text variant="largeHeading" textAlign="center">
        {t('features.page.title')}
      </Text>
      <div className={styles.imageContainer}>
        <Image className={styles.image} preview={false} src={FeaturesImg} />
      </div>
      <div className={styles.blocks}>
        <div className={styles.block}>
          <FileIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block1.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block1.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <SecuredIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block2.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block2.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <SignatureVerificationIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block3.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block3.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <ListIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block4.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block4.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <ContactBookIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block5.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block5.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <PaperAirplaneIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block6.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block6.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <ApproveIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block7.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block7.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <BlankDocumentIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block8.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block8.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <BellIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block9.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block9.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <AuditIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block10.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block10.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <GearIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block11.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block11.content')}
          </Text>
        </div>
        <div className={styles.block}>
          <LockIcon />
          <Text className={styles.blockTitle} variant="largeBold">
            {t('features.page.block12.title')}
          </Text>
          <Text className={styles.blockContent} variant="medium">
            {t('features.page.block12.content')}
          </Text>
        </div>
      </div>
    </div>
  );
}
