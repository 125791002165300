import {ReactNode} from 'react';
import clsx from 'clsx';

import styles from './UserButton.module.scss';

type Props = {
  className?: string;
  leftIcon: ReactNode;
  rightIcon?: ReactNode;
  children: string;
  onClick?: () => void;
};

export function UserButton({
  className,
  leftIcon,
  children,
  rightIcon,
  onClick,
  ...restProps
}: Props) {
  return (
    <button
      type="button"
      className={clsx(styles.userButton, className)}
      onClick={onClick}
      {...restProps}>
      <div className={styles.leftIconWrapper}>{leftIcon}</div>
      <span>{children}</span>
      {rightIcon}
    </button>
  );
}
