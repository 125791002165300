import {useAppSelector} from 'store';
import {Link as RouterLink} from 'react-router-dom';
import {Link} from 'features/components/link/Link';
import {ReactComponent as ESimIcon} from 'assets/icons/ESimIcon.svg';
import {ReactComponent as AsanDocPortalIcon} from 'assets/icons/AsanDocPortalIcon.svg';
import styles from './Logos.module.scss';

interface Props {
  homeLink: string;
  onClickHome?: () => void;
}

export function Logos({homeLink, onClickHome}: Props): JSX.Element {
  const {activeLanguage} = useAppSelector(state => state.translation);

  return (
    <div className={styles.logos}>
      <RouterLink to={homeLink} rel="noreferrer" onClick={onClickHome}>
        <AsanDocPortalIcon />
      </RouterLink>
      <Link href={`https://www.asanimza.az/${activeLanguage === 'en' ? 'en' : ''}`} target="_blank">
        <ESimIcon />
      </Link>
    </div>
  );
}
