import {useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store';
import {useDocument} from 'hooks/documentsHooks';
import {clearDocument} from 'store/reducers/document';
import {Document, Signature, SignatureType, SignerStatusType} from 'types/document';
import {useEvent} from 'hooks/eventHooks';
import {Event} from 'types/event';
import {useSignature} from 'hooks/signatureHooks';
import {useNavigation} from 'hooks/navigateHooks';
import {useTranslation} from './translationHooks';

const getSignAndShareButtonsState = (
  document: Document | null,
  isDocumentLoading: boolean,
  isFilesUploading: boolean,
  filesUploaded: boolean,
  isMySignature: (signature: Signature) => boolean,
  findMySignature: (signatures?: Signature[]) => Signature | undefined,
  t: (key: string) => string
) => {
  const isUserACreator = document?.creator;
  const isSignaturesEmpty = !document?.signatures?.length;
  const signaturesEmpty = (document?.signatures || []).length === 0;

  const actionInProgress = isDocumentLoading || isFilesUploading || !filesUploaded;
  const userIsInSignersList = document?.signatures.some(signature => isMySignature(signature));
  const isOnlyUserAsSigner = userIsInSignersList && document?.signatures.length === 1;
  const mySignature = findMySignature(document?.signatures);

  const userAlreadySigned = !!mySignature && mySignature.status !== SignerStatusType.PENDING;
  const canUserSign = (() => {
    const approvalIsRequested = document?.signatures.some(
      signature =>
        signature.type === SignatureType.APPROVAL &&
        (signature.status === SignerStatusType.DECLINED ||
          signature.status === SignerStatusType.PENDING)
    );
    return !(userAlreadySigned || approvalIsRequested || !userIsInSignersList);
  })();

  const shareButtonTooltip = (() => {
    if (!isUserACreator) {
      return t('signing.page.document.owner.tooltip');
    }

    if (signaturesEmpty || isOnlyUserAsSigner) {
      return t('signing.page.signatures.empty.tooltip');
    }
    return '';
  })();

  const signButtonTooltip = (() => {
    if (!userIsInSignersList) {
      return t('signing.page.user.not.in.signers.array.tooltip');
    }
    if (userAlreadySigned) {
      return t('signing.page.user.already.signed.tooltip');
    }
    return '';
  })();

  return {
    signButtonDisabled: actionInProgress || isSignaturesEmpty || !canUserSign,
    signButtonTooltip,
    shareButtonDisabled:
      actionInProgress || isSignaturesEmpty || isOnlyUserAsSigner || !isUserACreator,
    shareButtonTooltip,
  };
};

export const useSignDocumentContainer = () => {
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const dispatch = useAppDispatch();
  const {
    createDocument,
    appendDocument,
    deleteFileFromDocument,
    documentDownloadLink,
    deleteDocument,
  } = useDocument();
  const {t} = useTranslation();
  const {createEvent} = useEvent();
  const {isMySignature, findMySignature} = useSignature();
  const params = useParams();

  const {userInfo} = useAppSelector(state => state.user);
  const isLoggedIn = !!userInfo;
  const {
    isLoading: isDocumentLoading,
    isUploadingFiles,
    document,
  } = useAppSelector(state => state.document);

  const {navigate} = useNavigation();
  const location = useLocation();

  const invokeUploadingProgress = useCallback(() => {
    const interval = setInterval(() => {
      setUploadingProgress(prevProgress => {
        if (prevProgress === 100) {
          clearInterval(interval);
          return 100;
        }
        const randomIncrement = Math.floor(Math.random() * 10) + 1;
        return Math.min(prevProgress + randomIncrement, 100);
      });
    }, 50);
  }, []);

  useEffect(() => {
    if (!document) {
      setUploadingProgress(0);
    }
  }, [document]);

  useEffect(() => {
    if (isUploadingFiles) {
      invokeUploadingProgress();
    }
  }, [invokeUploadingProgress, isUploadingFiles]);

  const filesUploaded = !isUploadingFiles && !!document?.files.length && uploadingProgress === 100;

  const handleFileRemove = (fileId: number) => {
    if (document) {
      if (document.files.length === 1) {
        deleteDocument({id: document.id});
        return;
      }
      deleteFileFromDocument(document.id, fileId).then(() => createEvent(Event.DOCUMENT_DELETED));
    }
  };

  const handleCancel = () => {
    dispatch(clearDocument());
  };

  const handleBack = () => {
    if (isLoggedIn) {
      handleCancel();
      return;
    }

    navigate(-1);
  };

  const handleFilesChange = (files: File[]) => {
    if (document) {
      setUploadingProgress(0);
      appendDocument(document.id, files).then(response => {
        if (params.id && response && params.id !== response.id) {
          navigate(`/document/${response.id}`, {replace: true});
        }
        createEvent(Event.DOCUMENT_ADDED);
      });
      return;
    }

    createDocument(files).then(response => {
      const doc = response as Document;
      if (isLoggedIn && doc.signatures.every(s => s.status === SignerStatusType.SIGNED)) {
        navigate(`/document/${doc.id}`, {
          state: {isBackButtonDisabled: true, fromPath: location.pathname},
        });
      }
    });
  };

  const showProgressBar = (() => {
    if (isUploadingFiles) {
      return true;
    }
    return uploadingProgress > 0 && uploadingProgress < 100;
  })();

  return {
    showProgressBar,
    documentDownloadLink,
    document,
    uploadingProgress,
    filesUploaded,
    handleCancel,
    handleFilesChange,
    handleBack,
    isDocumentLoading,
    isUploadingFiles,
    handleFileRemove,
    signAndShareButtonsState: getSignAndShareButtonsState(
      document,
      isDocumentLoading,
      isUploadingFiles,
      filesUploaded,
      isMySignature,
      findMySignature,
      t
    ),
  };
};
