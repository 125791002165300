const sixMonthMaxAge = 60 * 60 * 24 * 180;
const privacyCookieName = 'COOKIE_CONSENT';
const messageKey = 'agreementNotification';

function getCookieConsent() {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(`${privacyCookieName}=`))
    ?.split('=')[1];
}

function setCookieConsent() {
  const date = new Date();
  date.setTime(date.getTime() + sixMonthMaxAge);
  const expires = `expires=${date.toUTCString()}`;
  const cookieValue = '{all:true}';
  document.cookie = `${privacyCookieName}=${cookieValue};${expires};path=/`;
}

export {setCookieConsent, getCookieConsent, messageKey};
