import {Feedback} from 'types/contactUs';
import {apiSlice} from './apiSlice';

export const APIUserSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    contactUs: builder.mutation<void, Feedback>({
      query: body => {
        return {
          url: '/feedback',
          method: 'POST',
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {useContactUsMutation} = APIUserSlice;
